import { useEffect, useState } from 'react'
import './PriorArtsReferenceDocumentElement.css'

export default function PriorArtsReferenceDocumentElement({element, document}) {
    const [elementPresent, setElementPresent] = useState(false)

    const checkPresentElement = () => {
        setElementPresent(false)
        document.features.forEach((feature) => {
            if (feature.feature === element) {
                setElementPresent(true)
                return
            }
        })
    }

    useEffect(() => {
        checkPresentElement()
        // eslint-disable-next-line
    },[document])

  return (
    <td className='PriorArtsReferenceDocumentElement'>
        { elementPresent ?
        <div className='priorArtsReferenceDocumentElementPresent'>
            <span></span>
        </div>
        :
        <div className='priorArtsReferenceDocumentElementAbsent'>
            <span></span>
        </div>
        }
        
    </td>
    )
}
