import './pdf.css'

export default function Pdf({thumbnails, fullImages, hidePdf, pdf}) {

  return (
    <div className='Pdf'>
        <div className='pdf'>        
          <iframe src={pdf} alt="File not found" title='patentPDF' />
        </div>
        <div className='close-button'>
            <div onClick={hidePdf}>
              <svg width="20" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="14.2656" cy="13.5" r="13" fill="#EDEFF2" stroke="#EEEEEE"/>
                <path d="M18.2846 9.95898L10.7246 17.519M10.7246 9.95898L18.2846 17.519" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
        </div>
    </div>
  )
}
