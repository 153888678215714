import './PhotoGalleryThumb.css'

export default function PhotoGalleryThumb({thumbnail, updateImage, index, currentImage}) {

    const handleClick = (evt) => {
        updateImage(index)
    }

  return (
    <div onClick={handleClick} className={`PhotoGalleryThumb ${currentImage===index ? 'active' : ''}`}>
        <img src={thumbnail} alt=" not found" />
        {currentImage===index && <div className='activeOverlay'></div>}
    </div>
  )
}
