import './PhotoGallery.css'
import PhotoGalleryThumb from '../PhotoGalleryThumb/PhotoGalleryThumb'
import { useEffect, useState } from 'react'
import { createImageAnnotation } from '../../utilities/api/projects'
import { formNotificationStyle1 } from '../../utilities/services/formNotification'

export default function PhotoGallery({user, hideGallery, thumbnails, fullImages, currentProject, updateCurrentProject, documentData, currentImage, updateCurrentImage}) {
  const [formData, setFormData] = useState({feature: ''})
  const [annotationMatch, setAnnotationMatch] = useState(false)
  const [elementList, setElementList] = useState([])

  // console.log(currentProject)
  // console.log(annotationMatch)
  // console.log(elementList)
  
  const checkImageAnnotations = () => {
    let elements = []
    setElementList([])
    setAnnotationMatch(false)
    if (fullImages[currentImage] && currentProject) {
      currentProject.features.forEach((feature) => {
        feature.imageAnnotations.forEach((imageAnnotation) => {
          if ((imageAnnotation.imageURL === fullImages[currentImage]) && (imageAnnotation.document === documentData.publicationNumber)) {
            setAnnotationMatch(true)
            elements.push(` Element${imageAnnotation.feature}`)
            // console.log('match')
          }
        })
      })
    }
    const elementsSet = new Set(elements)
    elements = [Array.from(elementsSet)]
    setElementList(elements)
  }

  const updateImage = (index) => {
      updateCurrentImage(index)
  }

  // const handleGalleryScroll = (evt) => {
  //   console.log(evt.target.id)
  //   const container = document.querySelector('.thumbs')
  //   if (evt.target.id === 'slideDocumentPhotoGalleryLeft' || evt.target.id === 'slideDocumentPhotoGalleryLeftSVG') {
  //     if (currentImage > 0) {
  //       updateCurrentImage(currentImage - 1)
  //       container.scrollLeft -= 100
  //     }
  //   } else if (evt.target.id === 'slideDocumentPhotoGalleryRight' || evt.target.id === 'slideDocumentPhotoGalleryRightSVG') {
  //     if (currentImage < fullImages.length - 1) {
  //       updateCurrentImage(currentImage + 1)
  //       container.scrollLeft += 100
  //     }
  //   }
  // }

  function handleChange(evt) {
    const newFormData = {
        ...formData,
        [evt.target.name]: evt.target.value,
    };
    setFormData(newFormData)
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault()
    const data = {
      ...formData,
      user: user._id,
      classification: documentData.classification,
      projectNumber: currentProject.projNum,
      imageURL: fullImages[currentImage],
      document: documentData.publicationNumber||'null',
      publicationDate: documentData.publicationDate||'null',
      title: documentData.title||'null',
      assignees: documentData.assigneeList.toString()||'null',
    }
    // console.log(data)
    try {
      await createImageAnnotation(currentProject, data)
      await updateCurrentProject(currentProject.projNum)
      formNotificationStyle1(`annotationNotification`, ` &nbsp;&nbsp;&nbsp;<span>&#x2714;</span>`, 'green', '#19243433', '1')
    } catch (error) {
      formNotificationStyle1(`annotationNotification`, 'Already exists on element', 'red', 'white', '0.5')
      // console.log(error)
    }
    
  }

  useEffect(() => {
    checkImageAnnotations()
    // eslint-disable-next-line
  }, [currentImage, currentProject, documentData])

  const submitDisabled = !formData.feature
  return (
    <div className='PhotoGallery'>
      <div className='topContent'>
        { thumbnails.length > 0 &&
          <div className='dropdown imageAnnotation'>
          { ((currentProject && currentProject.features.length > 0 && fullImages[currentImage]) &&
          ((currentProject && currentProject.status === 'inProgress') || 
          (currentProject && currentProject.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager')))) 
          &&
            <>
            <button className='imageAnnotationButton' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <svg width="20" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="14" cy="14" r="14" fill="#1A5E9B"/>
                  <circle cx="14" cy="14" r="13.5" stroke="white" strokeOpacity="0.06"/>
                  <path d="M13.9992 16.2854C15.2813 16.2854 16.3206 15.2461 16.3206 13.964C16.3206 12.6819 15.2813 11.6426 13.9992 11.6426C12.7171 11.6426 11.6777 12.6819 11.6777 13.964C11.6777 15.2461 12.7171 16.2854 13.9992 16.2854Z" fill="white"/>
                  <path d="M20.566 9.36364H18.3648C18.2529 9.36364 18.1141 9.29199 18.0059 9.17898L17.0381 7.66699C17.0228 7.64299 17.0057 7.62016 16.987 7.59866C16.6527 7.21273 16.2009 7 15.7159 7H12.2835C11.7985 7 11.3467 7.21273 11.0124 7.59866C10.9937 7.62016 10.9766 7.64299 10.9613 7.66699L9.99352 9.18119C9.91069 9.27057 9.79429 9.36585 9.67191 9.36585V9.0704C9.67191 8.91368 9.60902 8.76338 9.49708 8.65256C9.38513 8.54174 9.23329 8.47949 9.07498 8.47949H8.17957C8.02125 8.47949 7.86942 8.54174 7.75747 8.65256C7.64552 8.76338 7.58263 8.91368 7.58263 9.0704V9.36585H7.43339C6.95859 9.36634 6.50338 9.55327 6.16764 9.88561C5.83191 10.218 5.64307 10.6686 5.64258 11.1386V18.2273C5.64307 18.6973 5.83191 19.1479 6.16764 19.4802C6.50338 19.8126 6.95859 19.9995 7.43339 20H20.566C21.0408 19.9995 21.4961 19.8126 21.8318 19.4802C22.1675 19.1479 22.3564 18.6973 22.3569 18.2273V11.1364C22.3564 10.6664 22.1675 10.2157 21.8318 9.8834C21.4961 9.55105 21.0408 9.36413 20.566 9.36364ZM13.9997 17.6364C13.2913 17.6364 12.5989 17.4284 12.0099 17.0388C11.4209 16.6493 10.9618 16.0955 10.6907 15.4477C10.4196 14.7998 10.3487 14.087 10.4869 13.3992C10.6251 12.7115 10.9662 12.0797 11.4671 11.5839C11.968 11.0881 12.6062 10.7504 13.301 10.6136C13.9957 10.4768 14.7159 10.547 15.3704 10.8153C16.0248 11.0837 16.5842 11.5381 16.9777 12.1212C17.3713 12.7042 17.5814 13.3897 17.5814 14.0909C17.5803 15.0309 17.2026 15.9321 16.5311 16.5967C15.8597 17.2614 14.9493 17.6353 13.9997 17.6364Z" fill="white"/>
                </svg>
                <span>
                  &nbsp;&nbsp;Create Image Annotation
                </span>
            </button>
            <form className='dropdown-menu p-4' onSubmit={handleSubmit}>
              <div className='mb-3 d-flex'>
                <select className='form-select' id='imageAnnotation' name='feature' onChange={handleChange}>
                  <option value=''>Select a project element</option>
                  {
                    currentProject.features.map((feature, index) => (
                      <option key={index} value={feature.featureNum}>{`Element ${feature.featureNum}`}</option>
                    ))
                  }
                </select>
              </div>
              <div className='formSubmitButton'>
                <button className="form-control" type="submit" disabled={submitDisabled}>Create Image Annotation</button>
              </div>
            </form>
            <div className='annotationNotificationContainer'><div id={`annotationNotification`}className='annotationNotification'></div></div>
            </>
          }
          </div>
        }
        <div className='closeButton'>
          <div className='' onClick={hideGallery}>
            <svg width="20" height="27" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="14.2656" cy="13.5" r="13" fill="#EDEFF2" stroke="#EEEEEE"/>
              <path d="M18.2846 9.95898L10.7246 17.519M10.7246 9.95898L18.2846 17.519" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div>
      </div>
      <div className='bottomContent'>
        <div className='imageGallery'>
          {thumbnails.length === 0 ?
          <div className='no-images'><strong>No images to display</strong></div>
          :
          <>
          <div className='fullImageContainer'>
            { annotationMatch &&
              <div className='annotationMatchContainer'>
                <span className='annotationMatch'>{[<span key={0}>&#x2714; &nbsp;</span>]}</span>
                <span>
                  <small>
                    {
                      elementList.map((element, index) => (
                        <span key={index}>{`${element}`}</span>
                      ))
                    }
                  </small>
                </span>
              </div>
            }
            <div className='full'>
              <img src={fullImages[currentImage]} alt="Not found" />
            </div>
          </div>
          <div className='thumbs'>
            {thumbnails.map((thumbnail, index) => (
              <PhotoGalleryThumb key={index} thumbnail={thumbnail} updateImage={updateImage} index={index} currentImage={currentImage} />
            ))}
            {/* <div id='slideDocumentPhotoGalleryLeft' className='sliderLeft' onClick={handleGalleryScroll}>
              <svg id='slideDocumentPhotoGalleryLeftSVG' width="50" viewBox="0 0 87 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_302_6933)">
                <circle cx="19.5" cy="19.5" r="19.5" transform="matrix(-1 0 0 1 57 26)" fill="white"/>
                <circle cx="19.5" cy="19.5" r="19" transform="matrix(-1 0 0 1 57 26)" stroke="#1A5E9B" strokeOpacity="0.1"/>
                </g>
                <path d="M39.3887 52.4199L33.0983 46.1296L39.3887 39.8393" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <div id='slideDocumentPhotoGalleryRight' className='sliderRight' onClick={handleGalleryScroll}>
              <svg id='slideDocumentPhotoGalleryRightSVG' width="50" viewBox="0 0 87 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_302_6937)">
                <circle cx="49.5" cy="45.5" r="19.5" fill="white"/>
                <circle cx="49.5" cy="45.5" r="19" stroke="#1A5E9B" strokeOpacity="0.1"/>
                </g>
                <path d="M47.6113 52.4199L53.9017 46.1296L47.6113 39.8393" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div> */}
          </div>
          </>
          }
        </div>
      </div>
        
    </div>
  )
}
