import './Login.css'
import LoginForm from "../../components/LoginForm/LoginForm";


export default function Login({user, updateUser}) {
  
  return (
    <div className='Login'>
      <div className='leftSide'>
        <div className='logo'>
          <img className='logoImage' src={require('./Logo.png')} alt="not found" />
        </div>
        <div className='loginForm'>
          <LoginForm user={user} updateUser={updateUser} />
        </div>
        <footer className='footer secondaryText'>
          <p>© PatAnalysis 2024</p>
        </footer>
      </div>
      <div className='rightSide'>
        <img src={require('./LoginImage.png')} alt="not found" />
      </div>
    </div>
  )
}
