import { useEffect, useState } from 'react'
import Mark from 'mark.js'
import './MultiTextHighlighter.css'
// import { useNavigate } from 'react-router-dom';

export default function MultiTextHighlighter({keyWords, updateKeyWords, clearKeyWords, keyWordColors}) {
  const defaultKeyWords = {
    keyword1: '',
    keyword2: '',
    keyword3: '',
    keyword4: '',
    keyword5: '',
    keyword6: '',
    keyword7: '',
    keyword8: '',
  }
  const [formData, setFormData] = useState(keyWords)
  // const navigate = useNavigate()
  // console.log(keyWords)

  function handleChange(evt) {
    const newFormData = {
        ...formData,
        [evt.target.name]: evt.target.value,
    };
    setFormData(newFormData)
  }

  const handleDropdownClick = (evt) => {
    // prevent dropdown from closing when clicked
    evt.stopPropagation()
  }

  // console.log(documentData)

  const handleSubmit = (evt) => {
    evt.preventDefault()
    // console.log(formData)
    updateKeyWords(formData)
  }

  const clearKeyWordsClick = () => {
    clearKeyWords()
    setFormData(defaultKeyWords)
    if (document.querySelector('.mainDocument')) {
      const mainDocument = document.querySelector('.mainDocument')
      const instance = new Mark(mainDocument);
      instance.unmark({className: 'keywordMark'});
    }
  }

  useEffect(() => {
    setFormData(keyWords)
  }, [keyWords])

  return (
    <div className='MultiTextHighlighter'>
      { keyWords &&
        <div className='dropdown multiTextHighlighterDropDown'>
          <button className='dropdown highlighterButton' id="multiTextHighlighterDropDownToggle" type="button" data-bs-toggle="dropdown" data-title='Keyword Search' aria-expanded="false">
            <svg width="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.3583 9.46289C11.5588 8.66333 10.2626 8.66323 9.46312 9.46266C8.66369 10.2621 8.66379 11.5583 9.46335 12.3579L23.9388 26.8333C24.7383 27.6329 26.0346 27.633 26.834 26.8336C27.6334 26.0341 27.6333 24.7379 26.8338 23.9383L12.3583 9.46289Z" stroke="#1B2B41" strokeWidth="1.5" strokeMiterlimit="10"/>
              <path d="M9.46928 9.46928C9.27839 9.66013 9.12697 9.8867 9.02367 10.1361C8.92036 10.3855 8.86719 10.6527 8.86719 10.9227C8.86719 11.1926 8.92036 11.4599 9.02367 11.7092C9.12697 11.9586 9.27839 12.1852 9.46928 12.376L12.3838 15.2906L15.2906 12.3838L12.376 9.46928C12.1852 9.27839 11.9586 9.12697 11.7092 9.02367C11.4599 8.92036 11.1926 8.86719 10.9227 8.86719C10.6527 8.86719 10.3855 8.92036 10.1361 9.02367C9.8867 9.12697 9.66013 9.27839 9.46928 9.46928Z" fill="#09244E"/>
              <path d="M1 10.3536H4.11786H1ZM3.73982 3.73982L5.94441 5.94441L3.73982 3.73982ZM10.3536 1V4.11786V1ZM16.9673 3.73982L14.7628 5.94441L16.9673 3.73982ZM5.94441 14.7628L3.73982 16.9673L5.94441 14.7628Z" fill="white"/>
              <path d="M1 10.3536H4.11786M3.73982 3.73982L5.94441 5.94441M10.3536 1V4.11786M16.9673 3.73982L14.7628 5.94441M5.94441 14.7628L3.73982 16.9673" stroke="#1B2B41" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
            </svg>
          </button>
          <form className='dropdown-menu multiTextHighlighterDropDown' onSubmit={handleSubmit} onClick={handleDropdownClick} aria-labelledby='multiTextHighlighterDropDownToggle'>
            <div className='formHeader'>
              <div className='imageContainer'>
                <img src={require('./Highlighter.png')} width={30} alt="not found" />
              </div>
              <div className='textContainer'>
                <h5 className=''>Keyword Highlighter</h5>
                <p className=''>Let's highlight some text</p>
              </div>
            </div>
            <div className='formContent'>
              <div className='mb-1 d-flex contentItem'>
                <label htmlFor="keyword1">
                  <span className='highlightColor' style={{backgroundColor: `${keyWordColors[0]}`}}></span>
                </label>
                <input onChange={handleChange} className='form-control' autoComplete='true' name='keyword1' id='keyword1' type="text" defaultValue={formData.keyword1} placeholder='Enter Keyword 1' />
              </div>
              <div className='mb-1 d-flex contentItem'>
                <label htmlFor="keyword2">
                  <span className='highlightColor' style={{backgroundColor: `${keyWordColors[1]}`}}></span>
                </label>
                <input onChange={handleChange} className='form-control' autoComplete='true' name='keyword2' id='keyword2' type="text" defaultValue={formData.keyword2} placeholder='Enter Keyword 2' />
              </div>
              <div className='mb-1 d-flex contentItem'>
                <label htmlFor="keyword3">
                  <span className='highlightColor' style={{backgroundColor: `${keyWordColors[2]}`}}></span>
                </label>
                <input onChange={handleChange} className='form-control' autoComplete='true' name='keyword3' id='keyword3' type="text" defaultValue={formData.keyword3} placeholder='Enter Keyword 3' />
              </div>
              <div className='mb-1 d-flex contentItem'>
                <label htmlFor="keyword4">
                  <span className='highlightColor' style={{backgroundColor: `${keyWordColors[3]}`}}></span>
                </label>
                <input onChange={handleChange} className='form-control' autoComplete='true' name='keyword4' id='keyword4' type="text" defaultValue={formData.keyword4} placeholder='Enter Keyword 4' />
              </div>
              <div className='mb-1 d-flex contentItem'>
                <label htmlFor="keyword5">
                  <span className='highlightColor' style={{backgroundColor: `${keyWordColors[4]}`}}></span>
                </label>
                <input onChange={handleChange} className='form-control' autoComplete='true' name='keyword5' id='keyword5' type="text" defaultValue={formData.keyword5} placeholder='Enter Keyword 5' />
              </div>
              <div className='mb-1 d-flex contentItem'>
                <label htmlFor="keyword6">
                  <span className='highlightColor' style={{backgroundColor: `${keyWordColors[5]}`}}></span>
                </label>
                <input onChange={handleChange} className='form-control' autoComplete='true' name='keyword6' id='keyword6' type="text" defaultValue={formData.keyword6} placeholder='Enter Keyword 6' />
              </div>
              <div className='mb-1 d-flex contentItem'>
                <label htmlFor="keyword7">
                  <span className='highlightColor' style={{backgroundColor: `${keyWordColors[6]}`}}></span>
                </label>
                <input onChange={handleChange} className='form-control' autoComplete='true' name='keyword7' id='keyword7' type="text" defaultValue={formData.keyword7} placeholder='Enter Keyword 7' />
              </div>
              <div className='mb-1 d-flex contentItem'>
                <label htmlFor="keyword8">
                  <span className='highlightColor' style={{backgroundColor: `${keyWordColors[7]}`}}></span>
                </label>
                <input onChange={handleChange} className='form-control' autoComplete='true' name='keyword8' id='keyword8' type="text" defaultValue={formData.keyword8} placeholder='Enter Keyword 8' />
              </div>
            </div>
            <div className='buttonContainer d-flex justify-content-between'>
              <div className='formClearButton'>
                <label htmlFor="formClearButton">
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.9834 4.93751L16.9644 3.78579C16.1004 2.90415 15.0608 2.20252 13.9088 1.7236C12.7569 1.24468 11.5167 0.998491 10.2637 1.00001C5.14936 1.00001 1 5.03126 1 10C1 14.9688 5.14936 19 10.2637 19C12.1796 18.9999 14.0484 18.4229 15.6129 17.3484C17.1775 16.2739 18.3608 14.7548 19 13" stroke="#64748B" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
                    <path d="M20.7996 1.81008V7.3923C20.7996 7.60652 20.7145 7.81196 20.563 7.96343C20.4115 8.1149 20.2061 8.2 19.9919 8.2H14.4093C13.6894 8.2 13.3289 7.33021 13.8378 6.82136L19.4209 1.23863C19.9298 0.72877 20.7996 1.09022 20.7996 1.81008Z" fill="#64748B"/>
                  </svg>
                </label>
                <input type="reset" id='formClearButton' onClick={clearKeyWordsClick} className="form-control btn btn-sm btn-secondary" value="Reset all keywords" />
              </div>
              <div className='formSubmitButton'>
                <button className="form-control" type="submit">Search Keywords</button>
              </div>
            </div>
            
          </form>
        </div>
      }
      
    </div>
  )
}
