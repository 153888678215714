import { sendRequest } from "../request";
const BASE_URL = '/api/users';


export function checkToken() {
    return sendRequest(`${BASE_URL}/check-token`);
}

export function signUp(userData){
    // console.log('user data: ', userData)
    return sendRequest(BASE_URL, "POST", userData, 'Invalid Sign Up')
}

export function checkUsername(username) {
    return sendRequest(`${BASE_URL}/checkUsername/${username}`)
}

export function checkEmail(email) {
    return sendRequest(`${BASE_URL}/checkEmail/${email}`)
}

export function login(credentials) {
    return sendRequest(`${BASE_URL}/login`, "POST", credentials, 'Login Failed!')
}

export function updatePassword(data) {
    return sendRequest(`${BASE_URL}/${data.user._id}/updatePassword`, "PUT", data, 'Unable to Update Password')
}

export function updateAccountStatus(data) {
    return sendRequest(`${BASE_URL}/${data.user._id}/updateStatus`, "PUT", data, 'Unable to Update Account Status')
}

export function resetAccountPassword(data) {
    return sendRequest(`${BASE_URL}/${data.user._id}/resetPassword`, "PUT", data, 'Password Reset Failed')
}

export function getAllUsers() {
    return sendRequest(`${BASE_URL}/allUsers`)
}

export function deleteUser(userItem) {
    return sendRequest(`${BASE_URL}/deleteUser`,"DELETE", userItem,'Unable to delete')
}