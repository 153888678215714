export async function analyzeProjectSummary(documents, project) {
    let combinationsAddressingAllFeatures = []
    let combinationsWithOmittedFeatures = []
    const singleRefAddressingAllElementsList = []
    // Get the list of Project Features
    const projectFeaturesArray = []
    for (let index = 0; index < project.features.length; index++) {
        projectFeaturesArray.push(`${index+1}`)
    }
    
    // Compare the features present in a document with the list of Project Features.
    // If the features present in the document are the same as the features present in the project, 
    // then the document addresses all the features of the project
    documents.forEach((document, idx) => {
        let documentFeaturesArray = []
        for (let index = 0; index < document.features.length; index++) {
        documentFeaturesArray.push(document.features[index].feature)
        }
        const documentFeaturesSet = new Set(documentFeaturesArray)
        documentFeaturesArray = Array.from(documentFeaturesSet).sort((a, b) => a > b ? 1 : -1)
        if (documentFeaturesArray.toString() === projectFeaturesArray.toString()) {
        singleRefAddressingAllElementsList.push(idx+1)
        }
    })
    // Check if the all features of the project are addressed by any combination of two or three references.
    for (let firstRefIndex = 0; firstRefIndex < documents.length; firstRefIndex++) {
        let firstRefFeatures = []
        // Only perform operation if the first reference does not address all the features of the project
        if ((firstRefIndex !== documents.length-1) && (singleRefAddressingAllElementsList.indexOf(firstRefIndex+1) === -1)) {
            for (let featureIndex = 0; featureIndex < documents[firstRefIndex].features.length; featureIndex++) {
                firstRefFeatures.push(documents[firstRefIndex].features[featureIndex].feature)
            }
            const firstRefFeaturesSet = new Set(firstRefFeatures)
            firstRefFeatures = Array.from(firstRefFeaturesSet).sort((a, b) => a > b ? 1 : -1)
            const featuresNotPresentInFirstRef = []
            for (let index = 0; index < projectFeaturesArray.length; index++) {
                if (firstRefFeatures.indexOf(projectFeaturesArray[index]) === -1) {
                    featuresNotPresentInFirstRef.push(projectFeaturesArray[index])
                }
            }
            // Check combination with a second reference
            for (let secondRefIndex = firstRefIndex+1; secondRefIndex < documents.length; secondRefIndex++) {
                let secondRefFeatures = []
                for (let featureIndex = 0; featureIndex < documents[secondRefIndex].features.length; featureIndex++) {
                    secondRefFeatures.push(documents[secondRefIndex].features[featureIndex].feature)
                }
                const twoRefsCombinationFeaturesSet = new Set([...firstRefFeatures, ...secondRefFeatures])
                const twoRefsCombinationFeaturesArray = Array.from(twoRefsCombinationFeaturesSet).sort((a, b) => a > b ? 1 : -1)
                // Only add the combination if ref 1 and 2 do not contain the exact same features
                if (secondRefFeatures.toString() !== firstRefFeatures.toString()) {
                    const featuresNotPresentAfterCombiningTwoRefs = []
                    for (let index = 0; index < projectFeaturesArray.length; index++) {
                        if (twoRefsCombinationFeaturesArray.indexOf(projectFeaturesArray[index]) === -1) {
                            featuresNotPresentAfterCombiningTwoRefs.push(projectFeaturesArray[index])
                        }
                    }
                    // Only add the combination if it addresses all the features of the project
                    if ((projectFeaturesArray.toString() === twoRefsCombinationFeaturesArray.toString()) && singleRefAddressingAllElementsList.indexOf(secondRefIndex+1) === -1) {
                        combinationsAddressingAllFeatures= [
                            ...combinationsAddressingAllFeatures, 
                            {
                                firstRef: firstRefIndex+1, 
                                secondRef: secondRefIndex+1
                            }
                        ]
                    } else if (
                    (projectFeaturesArray.toString() !== twoRefsCombinationFeaturesArray.toString()) && 
                    (featuresNotPresentAfterCombiningTwoRefs.toString() !== featuresNotPresentInFirstRef.toString())
                    ) {
                        combinationsWithOmittedFeatures= [
                            ...combinationsWithOmittedFeatures, 
                            {
                                firstRef: firstRefIndex+1, 
                                secondRef: secondRefIndex+1, 
                                featuresNotPresent: featuresNotPresentAfterCombiningTwoRefs
                            }
                        ]
                        // Check combination with a third reference
                        if (secondRefIndex !== documents.length-1) {
                            for (let thirdRefIndex = secondRefIndex+1; thirdRefIndex < documents.length; thirdRefIndex++) {
                                let thirdRefFeatures = []
                                for (let featureIndex = 0; featureIndex < documents[thirdRefIndex].features.length; featureIndex++) {
                                    thirdRefFeatures.push(documents[thirdRefIndex].features[featureIndex].feature)
                                }
                                const threeRefsCombinationFeaturesSet = new Set([...firstRefFeatures, ...secondRefFeatures, ...thirdRefFeatures])
                                const threeRefsCombinationFeaturesArray = Array.from(threeRefsCombinationFeaturesSet).sort((a, b) => a > b ? 1 : -1)

                                // First check if first+third && second+third ref combination addresses all the features of the project, if not, combine the third ref
                                const firstAndThirdRefCombinationFeaturesSet = new Set([...firstRefFeatures, ...thirdRefFeatures])
                                const firstAndThirdRefCombinationFeaturesArray = Array.from(firstAndThirdRefCombinationFeaturesSet).sort((a, b) => a > b ? 1 : -1)
                                const secondAndThirdRefCombinationFeaturesSet = new Set([...secondRefFeatures, ...thirdRefFeatures])
                                const secondAndThirdRefCombinationFeaturesArray = Array.from(secondAndThirdRefCombinationFeaturesSet).sort((a, b) => a > b ? 1 : -1)
                                const featuresNotPresentAfterCombiningThreeRefs = []
                                for (let index = 0; index < projectFeaturesArray.length; index++) {
                                    if (threeRefsCombinationFeaturesArray.indexOf(projectFeaturesArray[index]) === -1) {
                                        featuresNotPresentAfterCombiningThreeRefs.push(projectFeaturesArray[index])
                                    }
                                }
                                const featuresNotPresentAfterCombiningSecondAndThirdRefs = []
                                for (let index = 0; index < projectFeaturesArray.length; index++) {
                                    if (secondAndThirdRefCombinationFeaturesArray.indexOf(projectFeaturesArray[index]) === -1) {
                                        featuresNotPresentAfterCombiningSecondAndThirdRefs.push(projectFeaturesArray[index])
                                    }
                                }
                                const featuresNotPresentAfterCombiningFirstAndThirdRefs = []
                                for (let index = 0; index < projectFeaturesArray.length; index++) {
                                    if (firstAndThirdRefCombinationFeaturesArray.indexOf(projectFeaturesArray[index]) === -1) {
                                        featuresNotPresentAfterCombiningFirstAndThirdRefs.push(projectFeaturesArray[index])
                                    }
                                }
                                if (
                                (firstAndThirdRefCombinationFeaturesArray.toString() !== projectFeaturesArray.toString()) && 
                                (secondAndThirdRefCombinationFeaturesArray.toString() !== projectFeaturesArray.toString())
                                ) {
                                    if ((projectFeaturesArray.toString() === threeRefsCombinationFeaturesArray.toString()) && singleRefAddressingAllElementsList.indexOf(thirdRefIndex+1) === -1) {
                                        combinationsAddressingAllFeatures= [
                                            ...combinationsAddressingAllFeatures, 
                                            {
                                                firstRef: firstRefIndex+1, 
                                                secondRef: secondRefIndex+1, 
                                                thirdRef: thirdRefIndex+1
                                            }
                                        ]
                                    } else if (
                                    (projectFeaturesArray.toString() !== threeRefsCombinationFeaturesArray.toString()) && 
                                    (featuresNotPresentAfterCombiningThreeRefs.toString() !== featuresNotPresentAfterCombiningTwoRefs.toString()) && 
                                    (featuresNotPresentAfterCombiningThreeRefs.toString() !== featuresNotPresentAfterCombiningSecondAndThirdRefs.toString()) && 
                                    (featuresNotPresentAfterCombiningThreeRefs.toString() !== featuresNotPresentAfterCombiningFirstAndThirdRefs.toString())
                                    ) {
                                        combinationsWithOmittedFeatures= [
                                            ...combinationsWithOmittedFeatures, 
                                            {
                                                firstRef: firstRefIndex+1, 
                                                secondRef: secondRefIndex+1, 
                                                thirdRef: thirdRefIndex+1, 
                                                featuresNotPresent: featuresNotPresentAfterCombiningThreeRefs
                                            }
                                        ]
                                    }
                                }
                            }
                        }
                    }
                }
            } 
        }
    }

    const maxNumberOfCombinations = 3

    // Sort CombinationsAddressingAllFeatures in a more desirable manner and truncate conditionally
    let sortedCombinationsAddressingAllFeatures = []
    if (combinationsAddressingAllFeatures.length > 0) {
        const comparingTwoRefs = []
        const comparingThreeRefs = []
        // Sort based on the number of references and truncate
        for (let index = 0; index < combinationsAddressingAllFeatures.length; index++) {
            if (combinationsAddressingAllFeatures[index].thirdRef && comparingThreeRefs.length < (maxNumberOfCombinations + 1)) {
                comparingThreeRefs.push(combinationsAddressingAllFeatures[index])
            } else if (!combinationsAddressingAllFeatures[index].thirdRef && comparingTwoRefs.length < (maxNumberOfCombinations + 1)) {
                comparingTwoRefs.push(combinationsAddressingAllFeatures[index])
            }
        }
        sortedCombinationsAddressingAllFeatures= [...comparingTwoRefs, ...comparingThreeRefs]
        // console.log(sortedCombinationsAddressingAllFeatures)
    }

    // Sort CombinationsWithOmittedFeatures in a more desirable manner
    let sortedCombinationsWithOmittedFeatures = []
    if (combinationsWithOmittedFeatures.length > 0) {
        // First sort based on the number of features omitted
        combinationsWithOmittedFeatures.sort((a, b) => a.featuresNotPresent.length > b.featuresNotPresent.length ? 1 : -1)
        const comparingTwoRefs = []
        const comparingThreeRefs = []
        // Then sort based on the number of references and truncate
        for (let index = 0; index < combinationsWithOmittedFeatures.length; index++) {
            if (combinationsWithOmittedFeatures[index].thirdRef && comparingThreeRefs.length < maxNumberOfCombinations) {
                comparingThreeRefs.push(combinationsWithOmittedFeatures[index])
            } else if (!combinationsWithOmittedFeatures[index].thirdRef && comparingTwoRefs.length < maxNumberOfCombinations) {
                comparingTwoRefs.push(combinationsWithOmittedFeatures[index])
            }
        }
        // console.log('combinationsWithOmittedFeatures', combinationsWithOmittedFeatures)
        sortedCombinationsWithOmittedFeatures= [...comparingTwoRefs, ...comparingThreeRefs]
        // console.log('sortedCombinationsWithOmittedFeatures', sortedCombinationsWithOmittedFeatures)
    }
    const summary = {
        singleRefAddressingAllElementsList, 
        sortedCombinationsAddressingAllFeatures, 
        sortedCombinationsWithOmittedFeatures
    }
    return summary
}


export async function analyzeProjectUnfiltered(documents, project) {
    // let combinationsAddressingAllFeatures = []
    // let combinationsWithOmittedFeatures = []
    const refCombinations = []
    const singleRefAddressingAllElementsList = []
    // Get the list of Project Features
    const projectFeaturesArray = []
    for (let index = 0; index < project.features.length; index++) {
        projectFeaturesArray.push(`${index+1}`)
    }
    
    // Compare the features present in a document with the list of Project Features.
    // If the features present in the document are the same as the features present in the project, 
    // then the document addresses all the features of the project
    documents.forEach((document, idx) => {
        let documentFeaturesArray = []
        for (let index = 0; index < document.features.length; index++) {
        documentFeaturesArray.push(document.features[index].feature)
        }
        const documentFeaturesSet = new Set(documentFeaturesArray)
        documentFeaturesArray = Array.from(documentFeaturesSet).sort((a, b) => a > b ? 1 : -1)
        if (documentFeaturesArray.toString() === projectFeaturesArray.toString()) {
        singleRefAddressingAllElementsList.push(idx+1)
        }
    })
    // Check if the all features of the project are addressed by any combination of two or three references.
    for (let firstRefIndex = 0; firstRefIndex < documents.length; firstRefIndex++) {
        let firstRefFeatures = []
        // Only perform operation if the first reference does not address all the features of the project
        if ((firstRefIndex !== documents.length-1) && (singleRefAddressingAllElementsList.indexOf(firstRefIndex+1) === -1)) {
            for (let featureIndex = 0; featureIndex < documents[firstRefIndex].features.length; featureIndex++) {
                firstRefFeatures.push(documents[firstRefIndex].features[featureIndex].feature)
            }
            const firstRefFeaturesSet = new Set(firstRefFeatures)
            firstRefFeatures = Array.from(firstRefFeaturesSet).sort((a, b) => a > b ? 1 : -1)
            const featuresNotPresentInFirstRef = []
            for (let index = 0; index < projectFeaturesArray.length; index++) {
                if (firstRefFeatures.indexOf(projectFeaturesArray[index]) === -1) {
                    featuresNotPresentInFirstRef.push(projectFeaturesArray[index])
                }
            }
            // Check combination with a second reference
            for (let secondRefIndex = firstRefIndex+1; secondRefIndex < documents.length; secondRefIndex++) {
                let secondRefFeatures = []
                for (let featureIndex = 0; featureIndex < documents[secondRefIndex].features.length; featureIndex++) {
                    secondRefFeatures.push(documents[secondRefIndex].features[featureIndex].feature)
                }
                const twoRefsCombinationFeaturesSet = new Set([...firstRefFeatures, ...secondRefFeatures])
                const twoRefsCombinationFeaturesArray = Array.from(twoRefsCombinationFeaturesSet).sort((a, b) => a > b ? 1 : -1)
                const featuresNotPresentAfterCombiningTwoRefs = []
                for (let index = 0; index < projectFeaturesArray.length; index++) {
                    if (twoRefsCombinationFeaturesArray.indexOf(projectFeaturesArray[index]) === -1) {
                        featuresNotPresentAfterCombiningTwoRefs.push(projectFeaturesArray[index])
                    }
                }
                if ((projectFeaturesArray.toString() === twoRefsCombinationFeaturesArray.toString())) {
                    refCombinations.push({
                        firstRef: firstRefIndex+1, 
                        secondRef: secondRefIndex+1,
                        featuresNotPresent: []
                    })
                } else if ((projectFeaturesArray.toString() !== twoRefsCombinationFeaturesArray.toString())) {
                    refCombinations.push({
                        firstRef: firstRefIndex+1, 
                        secondRef: secondRefIndex+1, 
                        featuresNotPresent: featuresNotPresentAfterCombiningTwoRefs
                    })
                }
                // Check combination with a third reference
                if (secondRefIndex !== documents.length-1) {
                    for (let thirdRefIndex = secondRefIndex+1; thirdRefIndex < documents.length; thirdRefIndex++) {
                        let thirdRefFeatures = []
                        for (let featureIndex = 0; featureIndex < documents[thirdRefIndex].features.length; featureIndex++) {
                            thirdRefFeatures.push(documents[thirdRefIndex].features[featureIndex].feature)
                        }
                        const threeRefsCombinationFeaturesSet = new Set([...firstRefFeatures, ...secondRefFeatures, ...thirdRefFeatures])
                        const threeRefsCombinationFeaturesArray = Array.from(threeRefsCombinationFeaturesSet).sort((a, b) => a > b ? 1 : -1)
                        const featuresNotPresentAfterCombiningThreeRefs = []
                        for (let index = 0; index < projectFeaturesArray.length; index++) {
                            if (threeRefsCombinationFeaturesArray.indexOf(projectFeaturesArray[index]) === -1) {
                                featuresNotPresentAfterCombiningThreeRefs.push(projectFeaturesArray[index])
                            }
                        }
                        if (projectFeaturesArray.toString() === threeRefsCombinationFeaturesArray.toString()) {
                            refCombinations.push({
                                firstRef: firstRefIndex+1, 
                                secondRef: secondRefIndex+1, 
                                thirdRef: thirdRefIndex+1,
                                featuresNotPresent: []
                            })
                        } else if (projectFeaturesArray.toString() !== threeRefsCombinationFeaturesArray.toString()) {
                            refCombinations.push({
                                firstRef: firstRefIndex+1, 
                                secondRef: secondRefIndex+1, 
                                thirdRef: thirdRefIndex+1,
                                featuresNotPresent: featuresNotPresentAfterCombiningThreeRefs
                            })
                        }
                    }
                }
            } 
        }
    }
    // Sort CombinationsAddressingAllFeatures in a more desirable manner and truncate conditionally
    const twoRefCombinations = []
    const threeRefCombinations = []
    if (refCombinations.length > 0) {
        // Sort based on the number of references
        for (let index = 0; index < refCombinations.length; index++) {
            if (refCombinations[index].thirdRef) {
                threeRefCombinations.push(refCombinations[index])
            } else if (!refCombinations[index].thirdRef) {
                twoRefCombinations.push(refCombinations[index])
            }
        }
    }
    return {twoRefCombinations: twoRefCombinations, threeRefCombinations: threeRefCombinations}
}