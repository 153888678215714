import './ProjectItem.css'
import { useNavigate } from 'react-router-dom'

export default function ProjectItem({project, updateCurrentProject}) {
  const navigate = useNavigate()

  const updateProject = async () => {
      try {
        await updateCurrentProject(project.projNum)
        navigate('/blank')
        setTimeout(() => {
          navigate('/')
        }, 4)
      } catch (err) {
        console.log(err)
      }
  }

  return (
    <li className="ProjectItem dropdown-item" onClick={updateProject}>
      <div className='projectItemInfo'>
        <div><strong>{project.projNum}</strong></div>
        <div className='projectTitle'>{project.projTitle}</div>
        <small className='tertiaryText'><span>{project.createdAt.split('T')[0]}</span></small>
      </div>
      <div className='projectItemStatus'>
        <span className={`status ${project.status}`}></span>
      </div>
    </li>
  )
}
