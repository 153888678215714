import { useEffect, useState } from 'react'
import './ImageThumbnailItem.css'
import { Popover, OverlayTrigger } from 'react-bootstrap'

export default function ImageThumbnailItem({image, fullImages, renderGallery, updateCurrentImage,currentProject, publicationNumber, index}) {
  const [imageMatch, setImageMatch] = useState(false)
  const [elementList, setElementList] = useState()
  // console.log(fullImages)

  const checkImageMatch = () => {
    let elements = []
    setImageMatch(false)
    if (fullImages[index] && currentProject) {
      currentProject.features.forEach((feature) => {
        feature.imageAnnotations.forEach((imageAnnotation) => {
          if ((imageAnnotation.imageURL === fullImages[index])) {
            setImageMatch(true)
            elements.push(` Element ${imageAnnotation.feature} `)
          }
        })
      })
    }
    
    return elements
  }

  const handleClick = (evt) => {
    renderGallery()
    updateCurrentImage(index)
  }

  const popover = (props) => (

    <Popover id={`button-Popover${index+1}`} {...props} >
      <Popover.Body>
        {elementList.toString()}
      </Popover.Body>
    </Popover>
  );

  useEffect(() => {
    setElementList(checkImageMatch())
    // eslint-disable-next-line
  }, [currentProject, publicationNumber])

  return (
    <span className='ImageThumbnailItem'>
      {imageMatch ?
        <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={popover}
        >
          <img className='present' onClick={handleClick} src={image} alt="Not found" />
        </OverlayTrigger>
        :
        <span onClick={handleClick}>
          <img className='' src={image} alt="Not found" />
        </span>
      }
    </span>
    
  )
}
