import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getProject, updateOrbitSearchHistory } from '../../utilities/api/projects'
import './SearchHistory.css'

export default function SearchHistory({user}) {
    let defaultValues = {}
    const [loading, setLoading] = useState(true)
    const [orbitSearchHistoryFormData] = useState(new FormData())
    const [patentListFormData] = useState(new FormData())
    const [formData, setFormData] = useState({})
    const projectParams = useParams()
    const [project, setProject] = useState('')
    const navigate = useNavigate()

    const getprojectData =async () => {
        try {
            const projectData = await getProject(projectParams.projectNumber)
            if (projectData) {
                if (user.userType === 'admin' || user.userType === 'manager' || user._id === projectData.analystId) {
                    setProject(projectData)
                    defaultValues = {
                        patentSearchStep1: projectData.patentSearchStep1,
                        patentSearchStep2: projectData.patentSearchStep2,
                        patentSearchStep3: projectData.patentSearchStep3,
                        patentSearchStep4: projectData.patentSearchStep4,
                        patentSearchStep5: projectData.patentSearchStep5,
                        scholarSearchStep1: projectData.scholarSearchStep1,
                        scholarSearchStep2: projectData.scholarSearchStep2,
                        scholarSearchStep3: projectData.scholarSearchStep3,
                        scholarSearchStep4: projectData.scholarSearchStep4,
                        scholarSearchStep5: projectData.scholarSearchStep5,
                        IPC1: projectData.IPC1,
                        IPC2: projectData.IPC2,
                        IPC3: projectData.IPC3,
                        IPC4: projectData.IPC4,
                        IPC5: projectData.IPC5,
                        CPC1: projectData.CPC1,
                        CPC2: projectData.CPC2,
                        CPC3: projectData.CPC3,
                        CPC4: projectData.CPC4,
                        CPC5: projectData.CPC5,
                    }
                    setFormData(defaultValues)
                } else {
                    navigate('/projects')
                }
                setLoading(false)
            } else {
                navigate('/projects')
            }
        } catch (error) {
            navigate('/projects')
        }
    }

    const handleSearchHistorySubmission = async (evt) => {
        evt.preventDefault();
        const data = {
            user: user._id,
            patentSearchStep1: formData.patentSearchStep1,
            patentSearchStep2: formData.patentSearchStep2,
            patentSearchStep3: formData.patentSearchStep3,
            patentSearchStep4: formData.patentSearchStep4,
            patentSearchStep5: formData.patentSearchStep5,
            scholarSearchStep1: formData.scholarSearchStep1,
            scholarSearchStep2: formData.scholarSearchStep2,
            scholarSearchStep3: formData.scholarSearchStep3,
            scholarSearchStep4: formData.scholarSearchStep4,
            scholarSearchStep5: formData.scholarSearchStep5,
            IPC1: formData.IPC1,
            IPC2: formData.IPC2,
            IPC3: formData.IPC3,
            IPC4: formData.IPC4,
            IPC5: formData.IPC5,
            CPC1: formData.CPC1,
            CPC2: formData.CPC2,
            CPC3: formData.CPC3,
            CPC4: formData.CPC4,
            CPC5: formData.CPC5,
        }
        try {
            const updatedProject = await updateOrbitSearchHistory(project.projNum, data)
            setProject(updatedProject)
            if (updatedProject) {
                document.getElementById('submitSearchHistory').innerHTML = 'Saved!'
                document.getElementById('submitSearchHistory').classList.remove('btn-primary')
                document.getElementById('submitSearchHistory').classList.add('btn-success')
                setTimeout(() => {
                    navigate(`/projects/${project.projNum}`)
                }, 1000);
            }
        } catch (error) {
            console.log(error)
        }
    }

    function handleOrbitSearchHistoryChange(evt) {
        if (evt.target.name === 'orbitSearchHistoryFile' && evt.target.files[0]) {
            orbitSearchHistoryFormData.delete('orbitSearchHistoryFile')
            orbitSearchHistoryFormData.append('orbitSearchHistoryFile', evt.target.files[0])
        }
    }

    const handleOrbitSearchHistorySubmission = async (evt) => {
        evt.preventDefault();
        try {
            const loader = document.getElementById('orbitSearchHistoryFileUploadLoader')
            loader.classList.remove('uploadDefault')
            loader.classList.add('uploading')
            fetch(`/api/projects/user/${user._id}/project/${project.projNum}/uploadOrbitSearchHistoryFile`, {
                method: 'POST',
                body: orbitSearchHistoryFormData,
            })
            .then((res) => res.json())
            .then((result) => {
                // console.log(result)
                loader.classList.remove('uploading')
                loader.classList.add('uploadDefault')
                if (result.projNum) {
                    setProject(result)
                    const element = document.getElementById('orbitSearchHistoryFileButton')
                    element.innerHTML= "Successfully Uploaded!"
                    element.classList.remove('btn-primary')
                    element.classList.add('btn-success')
                    setTimeout(() => {
                        element.innerHTML= "Submit"
                        element.classList.remove('btn-success')
                        element.classList.add('btn-primary')
                    }, 2000);
                } else {
                    const element = document.getElementById('orbitSearchHistoryFileButton')
                    const error = document.getElementById('orbitSearchHistoryFileUploadError')
                    element.innerHTML= "Upload Failed!"
                    element.classList.remove('btn-primary')
                    element.classList.add('btn-danger')
                    error.classList.remove('orbitSearchHistoryFileUploadError')
                    error.classList.add('showOrbitSearchHistoryFileUploadError')
                    setTimeout(() => {
                        element.innerHTML= "Submit"
                        element.classList.remove('btn-danger')
                        element.classList.add('btn-primary')
                    }, 2000);
                    setTimeout(() => {
                        error.classList.remove('showOrbitSearchHistoryFileUploadError')
                        error.classList.add('orbitSearchHistoryFileUploadError')
                    }, 4000);
                }
                
            })
        } catch (error) {
            console.log(error)
        }
    }

    function handlePatentListChange(evt) {
        if (evt.target.name === 'patentFamilies') {
            patentListFormData.delete('patentFamilies')
            patentListFormData.append('patentFamilies', evt.target.value)
        }
        if (evt.target.name === 'patentList' && evt.target.files[0]) {
            patentListFormData.delete('patentList')
            patentListFormData.append('patentList', evt.target.files[0])
        }
    }

    const handlePatentListSubmission = async (evt) => {
        evt.preventDefault();
        // // Log form data
        // for (var pair of patentListFormData.entries()) {
        //     console.log(pair[0]+ ', ' + pair[1]); 
        // }
        try {
            const loader = document.getElementById('patentListFileUploadLoader')
            loader.classList.remove('uploadDefault')
            loader.classList.add('uploading')
            fetch(`/api/projects/user/${user._id}/project/${project.projNum}/uploadPatentList`, {
                method: 'POST',
                body: patentListFormData,
            })
            .then((res) => res.json())
            .then((result) => {
                // console.log(result)
                loader.classList.remove('uploading')
                loader.classList.add('uploadDefault')
                if (result.projNum) {
                    setProject(result)
                    const element = document.getElementById('patentListButton')
                    element.innerHTML= "Successfully Uploaded!"
                    element.classList.remove('btn-primary')
                    element.classList.add('btn-success')
                    setTimeout(() => {
                        element.innerHTML= "Submit"
                        element.classList.remove('btn-success')
                        element.classList.add('btn-primary')
                    }, 2000);
                } else {
                    const element = document.getElementById('patentListButton')
                    const error = document.getElementById('patentListFileUploadError')
                    element.innerHTML= "Upload Failed!"
                    element.classList.remove('btn-primary')
                    element.classList.add('btn-danger')
                    error.classList.remove('patentListFileUploadError')
                    error.classList.add('showPatentListFileUploadError')
                    setTimeout(() => {
                        element.innerHTML= "Submit"
                        element.classList.remove('btn-danger')
                        element.classList.add('btn-primary')
                    }, 2000);
                    setTimeout(() => {
                        error.classList.remove('showPatentListFileUploadError')
                        error.classList.add('patentListFileUploadError')
                    }, 4000);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    function handleChange(evt) {
        const newFormData = {
            ...formData, // use the existing formData
            [evt.target.name]: evt.target.value, // override whatever key with the current field's value
        };
        setFormData(newFormData);
    }

    useEffect(() => {
        getprojectData()
        // eslint-disable-next-line
    },[])

  return (
    <div className='SearchHistory'>
        {loading ?
        <div className='loading'>
            <div className="spinner-border" role="status"></div>
        </div>
        :
        <>
        <div className='searchHistoryHeader'>
            <div className='container1'>
                <div className='pageTitle'>
                    Search Strategy
                </div>
                <div className='projectTitle'>
                    { (project.projTitle && project.projTitle.length>40) ?
                    project.projTitle.substring(0,40)+'...'
                    :
                    project.projTitle
                    }
                </div>
            </div>
            <div className='container2'>
                <Link to={`/projects/${project.projNum}`} className='cancelButton'>
                    <button className='cancelButton'>Back</button>
                </Link>
                <button id='submitSearchHistory' className='submitButton' type='submit' form='searchHistoryForm'>
                    <svg width="18" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="38" height="38" rx="10" fill="#FFFFFF" opacity='0.2'/>
                        <path d="M19 11V27V11ZM27 19H11H27Z" fill="#2173F0"/>
                        <path d="M19 11V27M27 19H11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span>&nbsp;&nbsp;Save Search Strategy</span>
                </button>
            </div>
        </div>
        <div className='fileUploadContainer'>
            <div className='fileUpload'>
                <div className='fielUploadHeading'>Upload Search History</div>
                <form onSubmit={handleOrbitSearchHistorySubmission} className='' encType='multipart/form-data' >
                    <input onChange={handleOrbitSearchHistoryChange} className='form-control mt-4 mb-3' type="file" name="orbitSearchHistoryFile" id="orbitSearchHistoryFile" required />
                    <button className='' id='orbitSearchHistoryFileButton' type='submit'>Submit</button>
                    <div id='orbitSearchHistoryFileUploadLoader' className='uploadDefault primaryText'>Uploading...</div>
                    <small>
                        {   project.orbitSearchHistoryURL === "https://nulll.com" ?
                        <div className='errorText'>No Search History File Uploaded</div>
                        :
                        <div className='tertiaryText'>
                            A file has been uploaded. Click&nbsp;
                            <a href={project.orbitSearchHistoryURL} target='_blank' rel="noreferrer">here</a>
                            &nbsp;to view the file.
                        </div>
                        }
                    </small>
                    <div id='orbitSearchHistoryFileUploadError' className='errorText orbitSearchHistoryFileUploadError'>Upload failed</div>
                </form>
            </div>
            <div className='fileUpload'>
                <div className='fielUploadHeading'>Upload Patent List</div>
                <form onSubmit={handlePatentListSubmission} className='' encType='multipart/form-data' >
                    <div>
                        <label htmlFor="patentFamilies">Patent Families</label>
                        <input onChange={handlePatentListChange} className='form-control mb-1' type="number" min={1} name='patentFamilies' id='patentFamilies' placeholder='Enter Number of Patent Families' defaultValue={(project.patentFamilies !== "null") ? project.patentFamilies : 0} required/>
                    </div>
                    <div>
                        <label htmlFor="patentList">Patent List</label>
                        <input onChange={handlePatentListChange} className='form-control' type="file" name="patentList" id="patentList" required />
                    </div>
                    <button className='' id='patentListButton' type='submit'>Submit</button>
                    <div id='patentListFileUploadLoader' className='uploadDefault primaryText'>Uploading...</div>
                    <small>
                        {   project.patentListURL === "https://nulll.com" ?
                        <div className='errorText'>No Patent List File Uploaded</div>
                        :
                        <div className='tertiaryText'> 
                            A file has been uploaded. Click&nbsp;
                            <a href={project.patentListURL} target='_blank' rel="noreferrer">here</a>
                            &nbsp;to view the file.
                        </div>
                        }
                    </small>
                    <div id='patentListFileUploadError' className='errorText patentListFileUploadError'>Upload failed!</div>
                </form>
            </div>
        </div>
        <div className='searchHistoryFormContainer'>
            <form className='searchHistoryForm' id='searchHistoryForm' onSubmit={handleSearchHistorySubmission}>
                <div className='formCard'>
                    <div className='searchHistoryFormHeading'>Google Patents Search History</div>
                    <div className='formContent'>
                        <label htmlFor="patentSearchStep1">Search Step 1</label>
                        <textarea id='patentSearchStep1' onChange={handleChange} rows={1} className='form-control' type="text" name='patentSearchStep1' defaultValue={formData.patentSearchStep1} placeholder='Required'/>
                        <label htmlFor="patentSearchStep2">Search Step 2</label>
                        <textarea id='patentSearchStep2' onChange={handleChange} rows={1} className='form-control' type="text" name='patentSearchStep2' defaultValue={formData.patentSearchStep2} placeholder='Required'/>
                        <label htmlFor="patentSearchStep3">Search Step 3</label>
                        <textarea id='patentSearchStep3' onChange={handleChange} rows={1} className='form-control' type="text" name='patentSearchStep3' defaultValue={formData.patentSearchStep3} placeholder='Required'/>
                        <label htmlFor="patentSearchStep4">Search Step 4</label>
                        <textarea id='patentSearchStep4' onChange={handleChange} rows={1} className='form-control' type="text" name='patentSearchStep4' defaultValue={formData.patentSearchStep4} placeholder='Optional'/>
                        <label htmlFor="patentSearchStep5">Search Step 5</label>
                        <textarea id='patentSearchStep5' onChange={handleChange} rows={1} className='form-control' type="text" name='patentSearchStep5' defaultValue={formData.patentSearchStep5} placeholder='Optional'/>
                    </div>
                </div>
                <div className='formCard'>
                    <div className='searchHistoryFormHeading'>Google Scholar Search History</div>
                    <div className='formContent'>
                        <label htmlFor="scholarSearchStep1">Search Step 1</label>
                        <textarea id='scholarSearchStep1' onChange={handleChange} rows={1} className='form-control' type="text" name='scholarSearchStep1' defaultValue={formData.scholarSearchStep1} placeholder='Required'/>
                        <label htmlFor="scholarSearchStep2">Search Step 2</label>
                        <textarea id='scholarSearchStep2' onChange={handleChange} rows={1} className='form-control' type="text" name='scholarSearchStep2' defaultValue={formData.scholarSearchStep2} placeholder='Required'/>
                        <label htmlFor="scholarSearchStep3">Search Step 3</label>
                        <textarea id='scholarSearchStep3' onChange={handleChange} rows={1} className='form-control' type="text" name='scholarSearchStep3' defaultValue={formData.scholarSearchStep3} placeholder='Required'/>
                        <label htmlFor="scholarSearchStep4">Search Step 4</label>
                        <textarea id='scholarSearchStep4' onChange={handleChange} rows={1} className='form-control' type="text" name='scholarSearchStep4' defaultValue={formData.scholarSearchStep4} placeholder='Optional'/>
                        <label htmlFor="scholarSearchStep5">Search Step 5</label>
                        <textarea id='scholarSearchStep5' onChange={handleChange} rows={1} className='form-control' type="text" name='scholarSearchStep5' defaultValue={formData.scholarSearchStep5} placeholder='Optional'/>
                    </div>
                </div>
                <div className='formCard'>
                    <div className='searchHistoryFormHeading'>IPC Classification</div>
                    <div className='formContent'>
                        <label htmlFor="IPC1">IPC 1</label>
                        <textarea id='IPC1' onChange={handleChange} rows={1} className='form-control' type="text" name='IPC1' defaultValue={formData.IPC1} placeholder='Required'/>
                        <label htmlFor="IPC2">IPC 2</label>
                        <textarea id='IPC2' onChange={handleChange} rows={1} className='form-control' type="text" name='IPC2' defaultValue={formData.IPC2} placeholder='Required'/>
                        <label htmlFor="IPC3">IPC 3</label>
                        <textarea id='IPC3' onChange={handleChange} rows={1} className='form-control' type="text" name='IPC3' defaultValue={formData.IPC3} placeholder='Required'/>
                        <label htmlFor="IPC4">IPC 4</label>
                        <textarea id='IPC4' onChange={handleChange} rows={1} className='form-control' type="text" name='IPC4' defaultValue={formData.IPC4} placeholder='Optional'/>
                        <label htmlFor="IPC5">IPC 5</label>
                        <textarea id='IPC5' onChange={handleChange} rows={1} className='form-control' type="text" name='IPC5' defaultValue={formData.IPC5} placeholder='Optional'/>
                    </div>
                    
                </div>
                <div className='formCard'>
                    <div className='searchHistoryFormHeading'>CPC Classification</div>
                    <div className='formContent'>
                        <label htmlFor="CPC1">CPC 1</label>
                        <textarea id='CPC1' onChange={handleChange} rows={1} className='form-control' type="text" name='CPC1' defaultValue={formData.CPC1} placeholder='Required'/>
                        <label htmlFor="CPC2">CPC 2</label>
                        <textarea id='CPC2' onChange={handleChange} rows={1} className='form-control' type="text" name='CPC2' defaultValue={formData.CPC2} placeholder='Required'/>
                        <label htmlFor="CPC3">CPC 3</label>
                        <textarea id='CPC3' onChange={handleChange} rows={1} className='form-control' type="text" name='CPC3' defaultValue={formData.CPC3} placeholder='Required'/>
                        <label htmlFor="CPC4">CPC 4</label>
                        <textarea id='CPC4' onChange={handleChange} rows={1} className='form-control' type="text" name='CPC4' defaultValue={formData.CPC4} placeholder='Optional'/>
                        <label htmlFor="CPC5">CPC 5</label>
                        <textarea id='CPC5' onChange={handleChange} rows={1} className='form-control' type="text" name='CPC5' defaultValue={formData.CPC5} placeholder='Optional'/>
                    </div>
                </div>
            </form>
        </div>
        </>
        }
    </div>
  )
}
