import './NewProjectFeaturesFormItem.css'

export default function NewProjectFeaturesFormItem({featureNum, handleChange, updateAddFeatureButton}) {
  const controlElementButton = (evt) => {
    if (evt.target.value === '') {
      updateAddFeatureButton('disable')
    } else {
      updateAddFeatureButton('enable')
    }
  }
  return (
    <div className='mb-3' onClick={controlElementButton} onChange={controlElementButton}>
        <label htmlFor={`feature${featureNum}`}>Element {featureNum}</label>
        <textarea className='form-control me-2' maxLength='1000' type="text" name={`feature${featureNum}`} id={`feature${featureNum}`}  onChange={handleChange} placeholder='Complete this field before adding more elements.'/>
        <small className="form-text text-muted">Max 1000 Characters</small>
    </div>
  )
}
