import { sendRequest } from "../request";
const BASE_URL = '/api/referenceNotes';

export function createReferenceNote(referenceNoteData) {
    return sendRequest(`${BASE_URL}`, "POST", referenceNoteData, 'Invalid Reference Note');
}

export function getReferenceNotes(projectId) {
    return sendRequest(`${BASE_URL}/project/${projectId}`)
}

export function updateReferenceNote(referenceNoteData) {
    return sendRequest(`${BASE_URL}/${referenceNoteData.noteId}`, "PUT", referenceNoteData, 'Unable to Update Reference Note');
}

export function deleteReferenceNote(referenceNoteData) {
    return sendRequest(`${BASE_URL}/${referenceNoteData.noteId}`, "DELETE", referenceNoteData, 'Unable to Delete Reference Note');
}