import { useEffect, useState } from 'react'
import './Users.css'

import { getAllUsers, updateAccountStatus } from '../../utilities/api/users'
import { deleteUser } from '../../utilities/api/users'

import UsersItem from '../../components/UsersItem/UsersItem'
import NewUser from '../../components/NewUser/NewUser'

export default function Users({user}) {
    const [loading, setLoading] = useState(true)
    const [users, setUsers] = useState([])
    
    const deleteUserItem = async (userItem) => {
        await deleteUser(userItem)
        const allUsers = await getAllUsers()
        setUsers(allUsers)
    }

    const updateUserStatus = async (data) => {
        await updateAccountStatus(data)
        const allUsers = await getAllUsers()
        setUsers(allUsers)
    }

    useEffect(() => {
        if (user.userType === 'admin' || user.userType === 'manager') {
            // console.log('effect hit')
            const getUsers = async () => {
                try {
                    const allUsers = await getAllUsers()
                    setUsers(allUsers)
                } catch (error) {
                    console.log(error)
                }
                setLoading(false)
            }
            getUsers()
        }
    },[user.userType])

    return (
        <div className='Users'>
            <div className='usersHeadingContainer'>
                <div className='section1'>
                    Users
                </div>
                <div className='section2'>
                    { user.userType === 'admin' &&
                    <>
                    <button className='createUser' data-bs-toggle="modal" data-bs-target={`#newUserModal`}>
                        <svg width="20" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="38" height="38" rx="10" fill="#FFFFFF" opacity='0.2'/>
                            <path d="M19 11V27V11ZM27 19H11H27Z" fill="#2173F0"/>
                            <path d="M19 11V27M27 19H11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        <span>
                        &nbsp;&nbsp;&nbsp;New User
                        </span>
                    </button>
                    <NewUser user={user} />
                    </>
                    }
                </div>
            </div> 
            {loading ?
            <div className='loading'>
              <div className="spinner-border" role="status"></div>
            </div>
            :
            <div className='usersMainSection'>
            { users && users.length>0 ?
                users.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1).map((userItem, idx) => (
                    <UsersItem currentUser={user} userItem={userItem} idx={idx} key={idx} deleteUserItem={deleteUserItem} updateUserStatus={updateUserStatus} />
                ))
                : 
                <h5 className='text-center'><strong>No Users</strong></h5>
            }
            </div>
            }
        </div>
    )
}
