import './Home.css'
import { useEffect } from 'react'
import { useParams } from "react-router-dom"
import PatentSearchForm from "../../components/PatentSearchForm/PatentSearchForm"
import Pdf from "../../components/pdf/pdf"
import Document from "../../components/Document/Document"
import AnnotateFeatureButtons from "../../components/AnnotateFeatureButtons/AnnotateFeatureButtons"
import ProjectSearch from '../../components/ProjectSearch/ProjectSearch'
import MultiTextHighlighter from '../../components/MultiTextHighlighter/MultiTextHighlighter'
import PhotoGallery from '../../components/PhotoGallery/PhotoGallery'
import ProjectFeatures from '../../components/ProjectFeatures/ProjectFeatures'
import HomeProjects from '../../components/HomeProjects/HomeProjects'
import NewProject from '../../components/NewProject/NewProject'

export default function Home({user, services, projects, updateProjects, projectsLoading, currentProject, projectDocuments, updateCurrentProject, documentData, updateDocument, toggleDocumentLoading, documentLoading, updateDocumentWithTranslatedFromBrowser, renderGallery, showGallery, hideGallery, showFeatures, toggleFeatures, currentImage, updateCurrentImage, renderPdf, showPdf, hidePdf, annotationText, updateAnnotationText, clearAnnotationText, keyWords, updateKeyWords, keyWordColors, clearKeyWords}) {  
  const patentParams = useParams()
  const handleWidthAdjust = () => {
    const documentSection = document.querySelector('.Home .mainSection .documentSection')
    if (showFeatures) {
      documentSection.style.width = '65vw'
    } else {
      documentSection.style.width = '82.5vw'
    }
  }


  useEffect(() => {
    handleWidthAdjust()
    // eslint-disable-next-line
  }, [showFeatures])
  return (
    <main className="Home">
      <div className='menuBar'>
        <div className='newProjectSection'>
          <div className='newProjectItem' data-bs-toggle="modal" data-bs-target={`#newProjectModal`}>
            <svg width="18" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="38" height="38" rx="10" fill="#1A5E9B"/>
              <path d="M19 11V27V11ZM27 19H11H27Z" fill="#2173F0"/>
              <path d="M19 11V27M27 19H11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <small>
            &nbsp;&nbsp;&nbsp;Create New Project
            </small>
          </div>
          <NewProject user={user} services={services} projects={projects} updateProjects={updateProjects} />
        </div>
        <div className='documentSearch'>
          <PatentSearchForm updateDocument={updateDocument} toggleDocumentLoading={toggleDocumentLoading} patentParams = {patentParams} renderGallery={renderGallery} hideGallery={hideGallery}/>
        </div>
        <div className='tools'>
          { currentProject &&
          <button className='toggleProjectFeatures tooltipContainer' onClick={toggleFeatures}>
            <svg width="20" height="18" viewBox="0 0 26 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.9813 1C8.80615 1 4.5233 3.50611 1.15165 8.51833C1.05541 8.66297 1.00267 8.83396 1.0001 9.00967C0.997525 9.18539 1.04523 9.35795 1.13719 9.50556C3.72779 13.7111 7.95333 17 12.9813 17C17.9547 17 22.267 13.7011 24.8624 9.48611C24.9522 9.34139 25 9.17293 25 9.00083C25 8.82874 24.9522 8.66027 24.8624 8.51556C22.2611 4.34889 17.9172 1 12.9813 1Z" stroke="#1B2B41" strokeOpacity="0.6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M13 13.5716C15.2091 13.5716 17 11.5249 17 9.00014C17 6.47541 15.2091 4.42871 13 4.42871C10.7909 4.42871 9 6.47541 9 9.00014C9 11.5249 10.7909 13.5716 13 13.5716Z" stroke="#1B2B41" strokeOpacity="0.6" strokeWidth="2" strokeMiterlimit="10"/>
            </svg>
            <span>&nbsp;&nbsp;{showFeatures? 'Hide':'Show'}</span>
            <span>&nbsp;Elements</span>
            <span className="tooltiptext">Toggle Project Elements</span>
          </button>
          }
          { documentData.classification !==null &&
          <div className='multiTextHighlighterContainer tooltipContainer'>
            <MultiTextHighlighter documentData={documentData} keyWords={keyWords} updateKeyWords={updateKeyWords} clearKeyWords={clearKeyWords} keyWordColors={keyWordColors} />
            <span className="tooltiptext">Keyword Search</span>
          </div>
          }
        </div>
      </div>
      <div className='mainSection'>
        <div className='projectSection'>
          <ProjectSearch user={user} documentData={documentData} updateDocument={updateDocument} toggleDocumentLoading={toggleDocumentLoading} currentProject={currentProject} projectDocuments={projectDocuments} updateCurrentProject={updateCurrentProject} />
        </div>
        <div className='documentSection'>
          <div className='annotationBar'>
            <AnnotateFeatureButtons user={user} documentData={documentData} updateDocument={updateDocument} currentProject={currentProject} projectDocuments={projectDocuments} updateCurrentProject={updateCurrentProject} annotationText={annotationText} clearAnnotationText={clearAnnotationText}/>
          </div>
          <div className='documentContainer'>
            { (documentLoading && !documentData.classification) &&
            <div className='documentContent'>
              <Document updateCurrentProject={updateCurrentProject} user={user} currentProject={currentProject} projectDocuments={projectDocuments} updateAnnotationText={updateAnnotationText} documentData={documentData} updateDocument={updateDocument} documentLoading={documentLoading} updateDocumentWithTranslatedFromBrowser={updateDocumentWithTranslatedFromBrowser} renderGallery={renderGallery} currentImage={currentImage} updateCurrentImage={updateCurrentImage} renderPdf={renderPdf} keyWords={keyWords} keyWordColors={keyWordColors} />
            </div>
            }
            { documentData.classification === 'PD' &&
            <>
              {(documentData) &&
              <div className='documentContent'>
                <Document updateCurrentProject={updateCurrentProject} user={user} currentProject={currentProject} projectDocuments={projectDocuments} updateAnnotationText={updateAnnotationText} documentData={documentData} updateDocument={updateDocument} documentLoading={documentLoading} updateDocumentWithTranslatedFromBrowser={updateDocumentWithTranslatedFromBrowser} renderGallery={renderGallery} currentImage={currentImage} updateCurrentImage={updateCurrentImage} renderPdf={renderPdf} keyWords={keyWords} keyWordColors={keyWordColors} />
              </div>
              }
              {showGallery &&
              <div className='galleryContent'>
                <PhotoGallery user={user} thumbnails={documentData.imageThumbnails} fullImages={documentData.fullSizeImages} currentProject={currentProject} updateCurrentProject={updateCurrentProject} documentData={documentData} hideGallery={hideGallery} currentImage={currentImage} updateCurrentImage={updateCurrentImage} />
              </div>
              }
              {showPdf &&
              <div className='pdfContent'>
                <Pdf hidePdf={hidePdf} pdf={documentData.patentPDF} />
              </div>
              }
            </>
            }
            { (documentData.classification === 'NPD'|| documentData.classification === 'PDF') &&
            <div className='documentContent'>
              <Document updateCurrentProject={updateCurrentProject} user={user} currentProject={currentProject} projectDocuments={projectDocuments} updateAnnotationText={updateAnnotationText} documentData={documentData} updateDocument={updateDocument} documentLoading={documentLoading} updateDocumentWithTranslatedFromBrowser={updateDocumentWithTranslatedFromBrowser} renderGallery={renderGallery} currentImage={currentImage} updateCurrentImage={updateCurrentImage} renderPdf={renderPdf} keyWords={keyWords} keyWordColors={keyWordColors} />
            </div>
            }
            { (documentData.classification === null && !currentProject && !documentLoading) &&
            <div className='documentContent'>
              <HomeProjects projects={projects} projectsLoading={projectsLoading} updateProjects={updateProjects} updateCurrentProject={updateCurrentProject} user={user} services={services} />
            </div>
            }
          </div>
        </div>
        { showFeatures &&
        <div className='featuresSection'>
          < div className='projectElements'>
            <h5>Project Elements</h5>
            <div className='projectElementsContent'>
              <ProjectFeatures projectFeatures={currentProject.features} />
            </div>
          </div>
        </div>
        }
      </div>
    </main>
  )
}
