import CreateUserForm from '../CreateUserForm/CreateUserForm'
import './NewUser.css'

export default function NewUser({user}) {
  return (
    <div className='NewUser modal fade' id='newUserModal' data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`newUserHeader`} aria-hidden="true">
        <CreateUserForm user={user} />
    </div>
  )
}
