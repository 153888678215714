import './AnnotateFeatureButtons.css'
import { useEffect, useState } from 'react'
import AnnotateFeatureButtonsItem from "../AnnotateFeatureButtonsItem/AnnotateFeatureButtonsItem";

export default function AnnotateFeatureButtons({user, documentData, updateDocument, currentProject, updateCurrentProject, projectDocuments, annotationText, clearAnnotationText}) {
  const [currentDocument, setCurrentDocument] = useState({})
  // console.log(projectDocuments)
  // console.log(currentDocument)
  // console.log(documentData)
  const features = []
  if (currentProject) {
    for (let index = 1; index <= currentProject.featureQuantity; index++) {
      features.push(index.toString())
    }
  }
  // console.log(features)

  useEffect(() => {
    const getDocumentInfo = async () => {
      let data = {}
      if (currentProject && projectDocuments && documentData.url) {
        projectDocuments.forEach((doc) => {
          if (doc.documentNumber === documentData.url) {
            data = {
              documentNumber: doc.documentNumber,
              documentTitle: doc.features[0].documentTitle,
              documentPublicationDate: doc.features[0].documentPublicationDate,
            }
          }
        })
      }
      setCurrentDocument(data)
    }
    getDocumentInfo()
  }, [currentProject, documentData, projectDocuments])

  return (
    <div className="AnnotateFeatureButtons">
        <div className='annotateFeatureButtonsContainer'>
        { ((currentProject && currentProject.status === 'inProgress') || 
          (currentProject && currentProject.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))) 
          &&
          <div className="AnnotateFeatureButtonsContent">
            { features &&
              features.map((feature, idx) => (
                <AnnotateFeatureButtonsItem user={user} documentData={documentData} currentDocument={currentDocument} updateDocument={updateDocument} currentProject={currentProject} updateCurrentProject={updateCurrentProject} selectedText={annotationText} key={idx} id={feature} clearAnnotationText={clearAnnotationText} />
              ))
            }
          </div>
        }
        { (currentProject && currentProject.status === 'inReview' && user.userType==='analyst') &&
          <div className="AnnotateFeatureButtonsContent notAllowed">
            <p className=''><strong><i>This Project has been submitted for review, so changes are not permitted at this time.</i></strong></p>
          </div>
        }
        { (currentProject && currentProject.status === 'completed') &&
          <div className="AnnotateFeatureButtonsContent notAllowed">
            <p className=''><strong><i>This Project is complete, so changes are not permitted.</i></strong></p>
          </div>
        }
        </div>
    </div>
  )
}
