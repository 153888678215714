import Help from '../../components/HelpList/HelpList';
import UpdatePasswordForm from '../../components/UpdatePasswordForm/UpdatePasswordForm';
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter';
import './UserProfile.css'

export default function UserProfile({user}) {

  return (
    <div className='UserProfile'>
      <div className='cover'>
        <svg viewBox="0 0 1896 200" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_225_669" style={{'maskType':'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" height="200">
            <rect width="1900" height="200" fill="url(#paint0_linear_225_669)"/>
          </mask>
          <g mask="url(#mask0_225_669)">
            <rect x="1209" y="88.8047" width="315.875" height="125.505" rx="62.7523" transform="rotate(-44.8585 1209 88.8047)" fill="white" fillOpacity="0.04"/>
            <circle cx="476.5" cy="204.5" r="52.5" fill="white" fillOpacity="0.04"/>
            <path d="M1844.3 247.569C1832.7 243.501 1822.02 237.189 1812.86 228.993C1803.71 220.798 1796.26 210.879 1790.93 199.803C1785.61 188.728 1782.52 176.712 1781.84 164.443C1781.17 152.174 1782.91 139.891 1786.98 128.296C1791.05 116.7 1797.36 106.02 1805.55 96.8644C1813.75 87.7087 1823.67 80.2569 1834.74 74.9345C1845.82 69.6121 1857.83 66.5233 1870.1 65.8444C1882.37 65.1656 1894.66 66.91 1906.25 70.9781L1875.27 159.274L1844.3 247.569Z" fill="white" fillOpacity="0.04"/>
            <path d="M-95.0976 -85.775C-79.3519 -94.8658 -61.9699 -100.766 -43.9439 -103.139C-25.9179 -105.513 -7.60094 -104.312 9.96108 -99.6063C27.5231 -94.9005 43.9863 -86.7818 58.4107 -75.7135C72.8351 -64.6453 84.9382 -50.8444 94.0289 -35.0987C103.12 -19.353 109.02 -1.97096 111.393 16.055C113.766 34.081 112.566 52.398 107.86 69.96C103.154 87.522 95.0357 103.985 83.9674 118.41C72.8992 132.834 59.0983 144.937 43.3526 154.028L-25.8725 34.1264L-95.0976 -85.775Z" fill="white" fillOpacity="0.04"/>
            <path d="M506.34 10.9651C499.748 22.728 490.904 33.0772 480.312 41.4221C469.72 49.767 457.589 55.9439 444.61 59.6004C431.631 63.2568 418.06 64.3211 404.67 62.7324C391.28 61.1438 378.334 56.9333 366.571 50.3414L416.767 -39.2311L506.34 10.9651Z" fill="white" fillOpacity="0.04"/>
            <path d="M962.334 99.7053C1003.11 112.363 1014.9 164.419 983.546 193.405L913.145 258.495C881.794 287.482 830.82 271.659 821.393 230.015L800.224 136.502C790.796 94.8573 829.985 58.624 870.764 71.2819L962.334 99.7053Z" fill="white" fillOpacity="0.04"/>
          </g>
          <defs>
            <linearGradient id="paint0_linear_225_669" x1="639.5" y1="-365" x2="940.789" y2="653.291" gradientUnits="userSpaceOnUse">
              <stop stopColor="#1A5E9B"/>
              <stop offset="1" stopColor="#192434"/>
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className='mainSection'>
        <div className='topSection'>
          <div className='summary'>
            <div className='avatar'>
              <svg xmlns="http://www.w3.org/2000/svg" width="100" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
              </svg>
            </div>
            <div className='info'>
              <div>
                <div className='name'>{`${capitalizeFirstLetter(user.firstName)} ${capitalizeFirstLetter(user.lastName)}`}</div>
                <div className='email'>{user.email}</div>
              </div>
            </div>
          </div>
          <div className="nav nav-pills flex navigation" id="v-pills-tab" role='tablist'>
            <button className="nav-link active" id='accountInfoTab' data-bs-toggle="pill" role="tab" aria-controls="accountInfo" aria-selected="true" data-bs-target="#accountInfo" type="button">
              Account Details
            </button>
            <button className="nav-link" id='updatePasswordTab' data-bs-toggle="pill" role="tab" aria-controls="updatePassword" aria-selected="false" data-bs-target="#updatePassword" type="button">
              Update Password
            </button>
            <button className="nav-link" id='helpTab' data-bs-toggle="pill" role="tab" aria-controls="help" aria-selected="false" data-bs-target="#help" type="button">
              Help
            </button>
          </div>
        </div>
        <div className='bottomSection'>
          <div className='content tab-content' id="v-pills-tabContent">
            <div className='tab-pane fade show active accountInfo' id="accountInfo" role="tabpanel" aria-labelledby="accountInfoTab">
              <div className='header'>
                Account Details
              </div>
              <div className='userProfileContentBody'>
                <div className='names'>
                  <div className='title1'>
                    Name
                  </div>
                  <div className='namesContent'>
                    <div className='section'>
                      <div className='firstName w-50 me-4'>
                        <div className='title2'>
                          First Name
                        </div>
                        <div className='content'>
                          {`${capitalizeFirstLetter(user.firstName)}`}
                        </div>
                      </div>
                      <div className='lastName w-50'>
                        <div className='title2'>
                          Last Name
                        </div>
                        <div className='content'>
                          {`${capitalizeFirstLetter(user.lastName)}`}
                        </div>
                      </div>
                    </div>
                    <div className='section'>
                      <div className='userType w-50 me-4'>
                        <div className='title2'>
                          User Type
                        </div>
                        <div className='content'>
                          {`${capitalizeFirstLetter(user.userType)}`}
                        </div>
                      </div>
                      <div className='userName w-50'>
                        <div className='title2'>
                          User Name
                        </div>
                        <div className='content'>
                          {user.userName}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='email'>
                  <div className='title1'>
                    Email
                  </div>
                  <div className='emailContent'>
                    <div className='section'>
                      <div className='emailAddress w-100'>
                        <div className='title2'>
                          Email
                        </div>
                        <div className='content'>
                          {`${user.email}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='company'>
                  <div className='title1'>
                    Company
                  </div>
                  <div className='companyContent'>
                    <div className='section'>
                      <div className='companyName w-100'>
                        <div className='title2'>
                          Company
                        </div>
                        <div className='content'>
                          {`${capitalizeFirstLetter(user.company)}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className='photoUpdate'>
                  <div className='title1'>
                    Photo
                  </div>
                  <div>

                  </div>
                </div> */}
              </div>
            </div>
            <div className='tab-pane fade updatePassword' id="updatePassword" role="tabpanel" aria-labelledby="updatePasswordTab">
              <div className='header'>
                Update Password
              </div>
              <div className='userProfileContentBody'>
                <UpdatePasswordForm user={user} />
              </div>
            </div>
            <div className='tab-pane fade help' id="help" role="tabpanel" aria-labelledby="helpTab">
              <div className='header'>
                Help
              </div>
              <div className='userProfileContentBody'>
                <Help />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
