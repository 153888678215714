import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './ProjectSearch.css'
import ProjectSearchDocuments from '../ProjectSearchDocuments/ProjectSearchDocuments'
import { findProjects } from '../../utilities/api/projects'
import ProjectItem from '../ProjectItem/ProjectItem'
import PriorArtsReference from '../PriorArtsReference/PriorArtsReference'
import DocumentFeature from '../DocumentFeature/DocumentFeature'

export default function ProjectSearch({user, documentData, updateDocument, toggleDocumentLoading, currentProject, projectDocuments, updateCurrentProject}) {
    const [userProjects, setUserProjects] = useState()
    const [loading, setLoading] = useState(true)
    const [elements, setElements]= useState([])
    const navigate = useNavigate()

    const findUserProjects = async () => {
        try {
            const projects = await findProjects(user)
            setUserProjects(projects.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1))
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    const handleDropdownClick = (evt) => {
        // prevent dropdown from closing when clicked
        evt.stopPropagation()
    }

    const countProjectElements = (project) => {
        setElements([])
        const elementsList = []
        for (let index = 0; index < project.featureQuantity; index++) {
            // console.log(index+1)
            elementsList.push((index+1).toString())
        }
        setElements(elementsList)
    }

    const handleRefresh =async (evt) => {
        if (currentProject) {
            try {
                // await updateCurrentProject(null)
                await updateCurrentProject(currentProject.projNum)
                navigate('/blank')
                setTimeout(() => {
                    navigate('/')
                }, 4);
            } catch (err) {
                console.log(err)
            }
        }
    }

    useEffect(() => {
        findUserProjects()
        // eslint-disable-next-line
    },[user])
    useEffect(() => { 
        countProjectElements(currentProject)
        // eslint-disable-next-line
    },[currentProject])

    
  return (
    <div className='ProjectSearch'>
        <div className='projectSearchContainer '>
            <div className="dropdown projectSearchDropdown">
                <button className="tooltipContainer" id='projectSearchDropdownToggle' data-title='Select a Project' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <span>
                        <svg width="18" viewBox="0 0 25 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="25" height="3" rx="1.5" fill="#1B2B41" fillOpacity="0.6"/>
                            <rect x="7" y="6" width="11" height="3" rx="1.5" fill="#1B2B41" fillOpacity="0.6"/>
                            <rect x="4" y="12" width="17" height="3" rx="1.5" fill="#1B2B41" fillOpacity="0.6"/>
                        </svg>
                        { !currentProject ?
                            <span>&nbsp;&nbsp;Select Project</span>
                        :
                            <span>&nbsp;&nbsp;{currentProject.projNum}</span>
                        }
                    </span>
                    <span>
                        <svg width="12" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L8.5 8L16 1" stroke="#262626" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>
                    <span className="tooltiptext">Select Project</span>
                </button>
                <ul className="dropdown-menu projectSearchDropdown" aria-labelledby="projectSearchDropdownToggle">
                    { loading ?
                    <div className='loading'>
                        <div className="spinner-border" role="status"></div>
                    </div>
                    :
                    <>
                    { userProjects && userProjects.length>0 ?
                    userProjects.map((project, idx) => (
                        <ProjectItem key={idx} project={project} updateCurrentProject={updateCurrentProject} />
                    ))
                    :
                    <li><span className="dropdown-item">No Projects Found</span></li>
                    }
                    </>
                    }
                    
                </ul>
            </div>
            <div className='projectNotification'>
                { currentProject &&
                <div className='projectNotificationContainer'>
                    <div className='title'>
                        <p>{`${currentProject.projTitle}`}</p>
                    </div>
                    <div className='refresh tooltipContainer' data-title='Refesh Page'>
                        <svg onClick={handleRefresh} width="20" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="13.5" cy="13.5" r="13.5" fill="#F0F1F5"/>
                            <path d="M18.2793 10.4063L17.6565 9.70245C17.1285 9.16366 16.4932 8.73488 15.7892 8.44221C15.0852 8.14953 14.3273 7.99908 13.5616 8C10.4361 8 7.90039 10.4636 7.90039 13.5001C7.90039 16.5366 10.4361 19.0001 13.5616 19.0001C14.7325 19.0001 15.8745 18.6474 16.8306 17.9908C17.7867 17.3342 18.5099 16.4058 18.9005 15.3334" stroke="#64748B" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
                            <path d="M19.9997 8.49505V11.9065C19.9997 12.0374 19.9477 12.1629 19.8551 12.2555C19.7625 12.348 19.637 12.4001 19.506 12.4001H16.0944C15.6545 12.4001 15.4342 11.8685 15.7452 11.5575L19.1571 8.14583C19.4681 7.83425 19.9997 8.05513 19.9997 8.49505Z" fill="#878499"/>
                        </svg>
                        <span className="tooltiptext">Refresh Page</span>
                    </div>
                </div>
                }
            </div>
        </div>
        { currentProject &&
        <div className='projectInfo'>
            <div className='projectDocuments'>
                <div onClick={handleDropdownClick} className='projectDetailsDropdown'>
                    <div className='projectTitle dropdown projectDetailsDropdownText tooltipContainer' data-title='View Project Summary' data-bs-toggle="dropdown" aria-expanded="false">
                        <span>
                            <svg width="12"viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.75" y="16.25" width="15.5" height="6.32353" rx="3.16176" transform="rotate(-90 0.75 16.25)" stroke="white" strokeWidth="1.5"/>
                                <rect x="7.45508" y="16.25" width="15.5" height="6.32353" rx="3.16176" transform="rotate(-90 7.45508 16.25)" stroke="white" strokeWidth="1.5"/>
                            </svg>
                        </span>
                        <span>&nbsp;&nbsp;</span>
                        <span className='projTitle'>{currentProject.projNum}</span>
                        <span className="tooltiptext">View Project Summary</span>
                    </div>
                    <div className='dropdown-menu projectDetailsDropdown'>
                        <div className='projectDocumentHeader'>
                            <Link to={`/projects/${currentProject.projNum}`} target='_blank'>Open Project Details Page in New Tab</Link>
                        </div>
                        <div className='priorArtsReferenceContainer'>
                            <PriorArtsReference project={currentProject} documents={projectDocuments} elements={elements} updateProject={updateCurrentProject} />
                        </div>
                        <div>
                            <div className='featuresHeading'>REFERENCES</div>
                            { projectDocuments ? 
                                projectDocuments.map((documentFeature, idx) => (
                                <DocumentFeature user={user} project={currentProject} documentFeature={documentFeature} key={idx} num={idx+1}  />
                                ))
                            :
                            <p className='text-center'>No Annotations</p>
                            }
                        </div>
                    </div>
                </div>
                <div className='projectDocumentContainer'>
                { projectDocuments.length>0 ?
                projectDocuments.map((projectDocument, idx) => (
                    <ProjectSearchDocuments documentData={documentData} updateDocument={updateDocument} toggleDocumentLoading={toggleDocumentLoading} projectDocument={projectDocument} key={idx} currentProject={currentProject} />
                ))
                :
                    <p className='text-center'>No Documents</p>
                }
                </div>
            </div>
        </div>
        }
        
        
    </div>
    
  )
}
