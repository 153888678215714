import './UpdateProject.css'
import UpdateProjectForm from '../UpdateProjectForm/UpdateProjectForm'

export default function UpdateProject({user, projectIdx, services, project, updateProjects}) {
  return (
    <div className='UpdateProject modal fade' id={`updateProjectModal${projectIdx}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`updateProjectHeader`} aria-hidden="true">
      <UpdateProjectForm user={user} projectIdx={projectIdx} project={project} services={services} updateProjects={updateProjects} />
    </div>
  )
}
