import {
    Document, TableRow, TableCell, Table, Paragraph, VerticalAlign, AlignmentType, WidthType, ShadingType,
    Header, TextRun, PageNumber, Footer, HeadingLevel, UnderlineType, ExternalHyperlink, Packer, SectionType,
    TableOfContents, StyleLevel, convertInchesToTwip, ImageRun, Bookmark, InternalHyperlink, /* SymbolRun */
} from 'docx'

import {saveAs} from 'file-saver'
import { analyzeProjectSummary, analyzeProjectUnfiltered } from './projectAnalysis'




const projectElementsTable = async (data) => {
    const rows = []
    data.project.features.forEach(feature => {
        const featureRow = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 700,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            text: `${feature.featureNum}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                    width: {
                        size: 8505,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            text: `${feature.featureText}`,
                            alignment: AlignmentType.JUSTIFIED,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                    }
                }),
            ],
        })
        rows.push(featureRow)
    });
    const table = new Table({
        // columnWidths: [700, 8505],
        alignment: AlignmentType.CENTER,
        rows: rows
    })
    return table
}

const priorArtReferenceTable = (data) => {
    const rows = []
    let headingRow = ['S/N', 'REFERENCE', 'PUBLICATION DATE']
    data.project.features.forEach(feature => {
        headingRow = [...headingRow, `EL ${feature.featureNum}`]
    });
    const elementsHeader = () => {
        const header = []
        headingRow.forEach((element, idx) => {
            if (idx===0) {
                const cell = new TableCell({
                    width: {
                        size: 700,
                        type: WidthType.DXA,
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            text: `${element}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                header.push(cell)
            } else if (idx===1) {
                const cell = new TableCell({
                    width: {
                        size: 4500,
                        type: WidthType.DXA,
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            text: `${element}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                header.push(cell)
            } else if (idx===2) {
                const cell = new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            text: `${element}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                header.push(cell)
            } else {
                const cell = new TableCell({
                    width: {
                        size: 500,
                        type: WidthType.DXA,
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            text: `${element}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                header.push(cell)
            }
        });
        const tableHeaderRow = new TableRow({
            children: header
        })
        return tableHeaderRow
    }
    rows.push(elementsHeader())

    const documentRows = () => {
        data.documents.forEach((document, idx) => {
            const documentCells = []

            // Number
            let cell = new TableCell({
                // width: {
                //     size: 700,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0),
                    bottom: convertInchesToTwip(0),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        text: `${idx+1}`,
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            })
            documentCells.push(cell)

            // Reference
            if (document.features[0].classification === 'PD') {
                cell = new TableCell({
                    // width: {
                    //     size: 4500,
                    //     type: WidthType.DXA,
                    // },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            // text: `${document.documentNumber}`,
                            alignment: AlignmentType.CENTER,
                            children: [
                                new ExternalHyperlink({
                                    children: [
                                        new TextRun({
                                            text: `${document.documentNumber}`,
                                            style: "Hyperlink",
                                        }),
                                    ],
                                    link: `https://patents.google.com/patent/${document.documentNumber}/en?oq=${document.documentNumber}`,
                                }),
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                documentCells.push(cell)                    
            } else {
                cell = new TableCell({
                    // width: {
                    //     size: 4500,
                    //     type: WidthType.DXA,
                    // },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            // text: `${document.features[0].documentTitle}`,
                            alignment: AlignmentType.CENTER,
                            children: [
                                new ExternalHyperlink({
                                    children: [
                                        new TextRun({
                                            text: `${document.features[0].documentTitle}`,
                                            style: "Hyperlink",
                                        }),
                                    ],
                                    link: `${document.documentNumber}`,
                                }),
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                documentCells.push(cell)
            }
            
            // Publication Date
            // const documentData = postPatentId({patentId: document.documentNumber})
            cell = new TableCell({
                // width: {
                //     size: 3500,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0),
                    bottom: convertInchesToTwip(0),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // text: `${documentData.publicationDate}`,
                        text: `${document.features[0].documentPublicationDate}`,
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            })
            documentCells.push(cell)

            // ELEMENTS
            const projectFeaturesArray = []
            for (let index = 0; index < data.project.features.length; index++) {
                projectFeaturesArray.push(`${index+1}`)
            }
            let documentFeaturesArray = []
            for (let index = 0; index < document.features.length; index++) {
                documentFeaturesArray.push(document.features[index].feature)
            }
            const documentFeaturesSet = new Set(documentFeaturesArray)
            documentFeaturesArray = Array.from(documentFeaturesSet)
            projectFeaturesArray.forEach((projectFeature, idx) => {
                if (documentFeaturesArray.includes(projectFeature)) {
                    cell = new TableCell({
                        // width: {
                        //     size: 500,
                        //     type: WidthType.DXA,
                        // },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "FF3131",
                            // fill: "FF3131",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `X`,
                                alignment: AlignmentType.CENTER,
                                // style: "MyPresentTableStyle",
                            }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                    documentCells.push(cell)
                } else {
                    cell = new TableCell({
                        // width: {
                        //     size: 500,
                        //     type: WidthType.DXA,
                        // },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "50C878",
                            // fill: "50C878",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: ``,
                                alignment: AlignmentType.CENTER,
                                // style: "MyAbsentTableStyle",
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                    documentCells.push(cell)
                }
            })

            const documentTableRow = new TableRow({
                children: documentCells
            })
            rows.push(documentTableRow)
        });
    }
    documentRows()
    const table = new Table({
        // columnWidths: [700, 8505],
        alignment: AlignmentType.CENTER,
        rows: rows
    })
    return table
}

const citationReferences = async (data) => {
    let paragraphs = [
        new Paragraph({
            pageBreakBefore: true,
            heading: HeadingLevel.HEADING_1,
            children: [
                new TextRun({
                    text: "APPENDIX I - CITATIONS",
                    allCaps: true,
                })
            ]
        })
    ]
    data.documents.forEach((document, idx) => {
        const paraHead = new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({
                    text: `Reference ${idx+1}`,
                })
            ]
        })
        paragraphs.push(paraHead)

        if (document.features[0].classification === 'PD') {
            const docNumber = new Paragraph({
                style: 'MyNormalStyle',
                shading: {
                    type: ShadingType.SOLID,
                    color: "ECECEC",
                    // fill: "ECECEC",
                },
                children: [
                    new TextRun({
                        text: `Original Document: `,
                        bold: true,
                    }),
                    // new TextRun({
                    //     text: `${document.documentNumber}`,
                    // }),
                    new ExternalHyperlink({
                        children: [
                            new TextRun({
                                text: `${document.documentNumber}`,
                                style: "Hyperlink",
                            }),
                        ],
                        link: `https://patents.google.com/patent/${document.documentNumber}/en?oq=${document.documentNumber}`,
                    }),
                ]
            })
            paragraphs.push(docNumber)
        } else {
            const docNumber = new Paragraph({
                style: 'MyNormalStyle',
                shading: {
                    type: ShadingType.SOLID,
                    color: "ECECEC",
                    // fill: "ECECEC",
                },
                children: [
                    new TextRun({
                        text: `Original Document: `,
                        bold: true,
                    }),
                    // new TextRun({
                    //     text: `${document.documentNumber}`,
                    // }),
                    new ExternalHyperlink({
                        children: [
                            new TextRun({
                                text: `${document.documentNumber.substring(0, 100)}`,
                                style: "Hyperlink",
                            }),
                        ],
                        link: `${document.documentNumber}`,
                        // link: `https://nulll.com`,
                    }),
                ]
            })
            paragraphs.push(docNumber)
        }
        

        const docTitle = new Paragraph({
            style: 'MyNormalStyle',
            shading: {
                type: ShadingType.SOLID,
                color: "ECECEC",
                // fill: "ECECEC",
            },
            alignment: AlignmentType.JUSTIFIED,
            children: [
                new TextRun({
                    text: `Title: `,
                    bold: true,
                }),
                new TextRun({
                    text: `${document.features[0].documentTitle}`,
                    allCaps: true,
                }),
            ]
        })
        paragraphs.push(docTitle)

        if (document.features[0].classification === 'PD') {
            const assignees = new Paragraph({
                style: 'MyNormalStyle',
                shading: {
                    type: ShadingType.SOLID,
                    color: "ECECEC",
                    // fill: "ECECEC",
                },
                children: [
                    new TextRun({
                        text: `Assignee(s): `,
                        bold: true,
                    }),
                    new TextRun({
                        text: `${document.features[0].assignees||'N/A'}`,
                    }),
                ]
            })
            paragraphs.push(assignees)
        }

        const docPub = new Paragraph({
            style: 'MyNormalStyle',
            shading: {
                type: ShadingType.SOLID,
                color: "ECECEC",
                // fill: "ECECEC",
            },
            children: [
                new TextRun({
                    text: `Publication Date: `,
                    bold: true,
                }),
                new TextRun({
                    text: `${document.features[0].documentPublicationDate}`,
                }),
            ]
        })
        paragraphs.push(docPub)

        for (let index = 0; index < document.features.length; index++) {
            let prepend = ''
            if (document.features[index].paragraphNumber && document.features[index].paragraphNumber !== '0000') {
                prepend = `[Para ${document.features[index].paragraphNumber}] `
            } else if (document.features[index].claimNumber) {
                prepend = `Claim ${document.features[index].claimNumber}: `
            } else if (document.features[index].abstract === 'true') {
                prepend = `Abstract: `
            } else if (document.features[index].imageURL) {
                prepend = `Image: `
            }
            let item
            if (index===0 || ((document.features[index].feature !== document.features[index-1].feature))) {
                item = new Paragraph({
                        style: 'MyItalicsStyle',
                        spacing: { before: 200, after: 50 },
                        text: `Element ${document.features[index].feature}`,
                        bullet: {
                            level: 0
                        },
                    })
                paragraphs.push(item)
                if (document.features[index].textAnnotation) {
                    item = new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: { after: 150 },
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                italics: false,
                                text: prepend,
                            }),
                            new TextRun({
                                text: `${document.features[index].textAnnotation}`,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                } else if (document.features[index].imageURL) {
                    item = new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: { after: 150 },
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                italics: false,
                                text: prepend,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                    let imageFile = ''
                    data.documentImages.forEach((documentImage, idx) => {
                        if (documentImage.url && (documentImage.url === document.features[index].imageURL)) {
                            imageFile = documentImage.image
                        }
                    })
                    item = new Paragraph({
                        // style: 'MyNormalStyle',
                        border: {
                            top: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            bottom: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            left: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            right: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                        },
                        spacing: { before: 150, after: 150 },
                        alignment: AlignmentType.CENTER,
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new ImageRun({
                                        data: imageFile,
                                        transformation: {
                                            width: 600,
                                            height: 600,
                                        },
                                    }),
                                ],
                                link: `${document.features[index].imageURL}`,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                }
            } else {
                if (document.features[index].textAnnotation) {
                    item = new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: { after: 150 },
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                italics: false,
                                text: prepend,
                            }),
                            new TextRun({
                                text: `${document.features[index].textAnnotation}`,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                } else if (document.features[index].imageURL) {
                    item = new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: { after: 150 },
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                italics: false,
                                text: prepend,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                    let imageFile = ''
                    data.documentImages.forEach( (documentImage, idx) => {
                        if (documentImage.url === document.features[index].imageURL) {
                            imageFile = documentImage.image
                        }
                    })
                    item = new Paragraph({
                        // style: 'MyNormalStyle',
                        border: {
                            top: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            bottom: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            left: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            right: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                        },
                        spacing: { before: 150, after: 150 },
                        alignment: AlignmentType.CENTER,
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new ImageRun({
                                        data: imageFile,
                                        transformation: {
                                            width: 300,
                                            height: 300,
                                        },
                                    }),
                                ],
                                link: `${document.features[index].imageURL}`,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                }
            }
            
        }
    })
    return paragraphs
}

const analysisTables = async (data) => {
    const unfilteredAnalysis = await analyzeProjectUnfiltered(data.documents, data.project)
    // console.log(unfilteredAnalysis)
    let components = [
        new Paragraph({
            pageBreakBefore: true,
            heading: HeadingLevel.HEADING_1,
            children: [
                new Bookmark({
                    id: "appendix_analysis",
                    children: [
                        new TextRun({
                            text: "APPENDIX II - ANALYSIS",
                            allCaps: true,
                        })
                    ],
                }),
            ],
        })
    ]
    const header1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
        children: [
            new TextRun({
                text: "Combination of Two References",
                underline: {
                    type: UnderlineType.SINGLE,
                },
            })
        ]
    })
    components.push(header1)

    const description1 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        spacing: { after: 300 },
        children: [
            new TextRun({
                text: `The table below illustrates a heatmap representing all potential combinations of two references cited in the report. An examiner at the patent office may use the combination of two or more references to challenge the claims of an invention through an Obviousness rejection.`,
            }),
        ]
    })
    components.push(description1)

    // Table Header List
    let headingRow = ['REFERENCES']
    data.project.features.forEach(feature => {
        headingRow = [...headingRow, `EL ${feature.featureNum}`]
    });

    // Create Table Header
    const elementsHeader = () => {
        const header = []
        headingRow.forEach((element, idx) => {
            if (idx===0) {
                const cell = new TableCell({
                    width: {
                        size: 1000,
                        type: WidthType.DXA,
                    },
                    shading: {
                        type: ShadingType.SOLID,
                        color: "d8d8d8",
                    },
                    margins: {
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${element}`,
                                    bold: true,
                                }),
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                header.push(cell)
            } else {
                const cell = new TableCell({
                    width: {
                        size: 500,
                        type: WidthType.DXA,
                    },
                    shading: {
                        type: ShadingType.SOLID,
                        color: "d8d8d8",
                    },
                    margins: {
                        left: convertInchesToTwip(0.02),
                        right: convertInchesToTwip(0.02),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `${element}`,
                                    bold: true,
                                }),
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                header.push(cell)
            }
        });
        const tableHeaderRow = new TableRow({
            // give the header row a background color
            children: header
        })
        return tableHeaderRow
    }

    // Create Table Rows for two reference combinations
    const twoRefCombinationTableRows = []
    twoRefCombinationTableRows.push(elementsHeader())

    const twoRefCombinationDocumentTableRows = () => {
        unfilteredAnalysis.twoRefCombinations.forEach((combination, idx) => {
            const documentCells = []

            // Combination
            let cell = new TableCell({
                // width: {
                //     size: 700,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0),
                    bottom: convertInchesToTwip(0),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        text: `Ref ${combination.firstRef} + Ref ${combination.secondRef}`,
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            })
            documentCells.push(cell)

            // GET PROJECT FEATURES
            const projectFeaturesArray = []
            for (let index = 0; index < data.project.features.length; index++) {
                projectFeaturesArray.push(`${index+1}`)
            }
            // ELEMENTS PRESENT OR ABSENT
            projectFeaturesArray.forEach((projectFeature, idx) => {
                if (combination.featuresNotPresent.indexOf(projectFeature) === -1) {
                    cell = new TableCell({
                        // width: {
                        //     size: 500,
                        //     type: WidthType.DXA,
                        // },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "FF3131",
                            // fill: "FF3131",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `X`,
                                alignment: AlignmentType.CENTER,
                                // style: "MyPresentTableStyle",
                            }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                    documentCells.push(cell)
                } else {
                    cell = new TableCell({
                        // width: {
                        //     size: 500,
                        //     type: WidthType.DXA,
                        // },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "50C878",
                            // fill: "50C878",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: ``,
                                alignment: AlignmentType.CENTER,
                                // style: "MyAbsentTableStyle",
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                    documentCells.push(cell)
                }
            })

            const documentTableRow = new TableRow({
                children: documentCells
            })
            twoRefCombinationTableRows.push(documentTableRow)
        });
    }
    twoRefCombinationDocumentTableRows()

    // Create Table for two reference combinations
    const twoRefCombinationTable = new Table({
        // columnWidths: [700, 8505],
        alignment: AlignmentType.CENTER,
        rows: twoRefCombinationTableRows
    })
    components.push(twoRefCombinationTable)

    
    const header2 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        // pageBreakBefore: true,
        spacing: { before: 700 },
        alignment: AlignmentType.CENTER,
        children: [
            new TextRun({
                text: "Combination of Three References",
                underline: {
                    type: UnderlineType.SINGLE,
                },
            })
        ]
    })
    components.push(header2)

    const description2 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        spacing: { after: 300 },
        children: [
            new TextRun({
                text: `The table below illustrates a heatmap representing all potential combinations of three references cited in the report. An examiner at the patent office may use the combination of two or more references to challenge the claims of an invention through an Obviousness rejection.`,
            }),
        ]
    })
    components.push(description2)

    // Create table rows for three reference combinations
    const threeRefCombinationTableRows = []
    threeRefCombinationTableRows.push(elementsHeader())

    const threeRefCombinationDocumentTableRows = () => {
        unfilteredAnalysis.threeRefCombinations.forEach((combination, idx) => {
            const documentCells = []

            // Combination
            let cell = new TableCell({
                // width: {
                //     size: 700,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0.1),
                    right: convertInchesToTwip(0.1),
                    top: convertInchesToTwip(0),
                    bottom: convertInchesToTwip(0),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        text: `Ref ${combination.firstRef} + Ref ${combination.secondRef} + Ref ${combination.thirdRef}`,
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            })
            documentCells.push(cell)

            // GET PROJECT FEATURES
            const projectFeaturesArray = []
            for (let index = 0; index < data.project.features.length; index++) {
                projectFeaturesArray.push(`${index+1}`)
            }
            // ELEMENTS PRESENT OR ABSENT
            projectFeaturesArray.forEach((projectFeature, idx) => {
                if (combination.featuresNotPresent.indexOf(projectFeature) === -1) {
                    cell = new TableCell({
                        // width: {
                        //     size: 500,
                        //     type: WidthType.DXA,
                        // },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "FF3131",
                            // fill: "FF3131",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `X`,
                                alignment: AlignmentType.CENTER,
                                // style: "MyPresentTableStyle",
                            }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                    documentCells.push(cell)
                } else {
                    cell = new TableCell({
                        // width: {
                        //     size: 500,
                        //     type: WidthType.DXA,
                        // },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "50C878",
                            // fill: "50C878",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: ``,
                                alignment: AlignmentType.CENTER,
                                // style: "MyAbsentTableStyle",
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                    documentCells.push(cell)
                }
            })

            const documentTableRow = new TableRow({
                children: documentCells
            })
            threeRefCombinationTableRows.push(documentTableRow)
        });
    }
    threeRefCombinationDocumentTableRows()

    // Create Table for three reference combinations
    const threeRefCombinationTable = new Table({
        alignment: AlignmentType.CENTER,
        rows: threeRefCombinationTableRows
    })
    components.push(threeRefCombinationTable)

    return components
}

// Section 3
const analysis = async (data) => {
    let paragraphs = []

    const mainHeading = new Paragraph({
        // pageBreakBefore: false,
        heading: HeadingLevel.HEADING_1,
        children: [
            new TextRun({
                text: "3 general notes",
                allCaps: true,
            })
        ]
    })
    paragraphs.push(mainHeading)

    const firstParagraph3p1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "3.1 How to Read Report",
                // allCaps: true,
            })
        ]
    })
    paragraphs.push(firstParagraph3p1)

    const secondParagraph3p1 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: "Here are the conditions that an idea can be patentable under patent law:",
                break: 0,
            }),
        ]
    })
    paragraphs.push(secondParagraph3p1)

    const thirdParagraph3p1 = new Paragraph({
        style: 'MyNormalStyle',
        spacing: { before: 250, after: 50 },
        text: "Utility",
        bullet: {
            level: 0
        }
    })
    paragraphs.push(thirdParagraph3p1)

    const fourthParagraph3p1 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: "An idea needs to be useful and produces an effect. The usefulness of an idea needs to be what is being claimed in the patent application.",
                break: 0,
            }),
        ]
    })
    paragraphs.push(fourthParagraph3p1)

    const fifthParagraph3p1 = new Paragraph({
        style: 'MyNormalStyle',
        spacing: { before: 250, after: 50 },
        text: "Novelty",
        bold: true,
        bullet: {
            level: 0
        }
    })
    paragraphs.push(fifthParagraph3p1)

    const sixthParagraph3p1 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: "An invention is novel if nothing identical has even existed (no one has ever done it the way you are doing it). This is where patent searching needs to be performed.",
                break: 0,
            }),
        ]
    })
    paragraphs.push(sixthParagraph3p1)

    const seventhParagraph3p1 = new Paragraph({
        style: 'MyNormalStyle',
        spacing: { before: 250, after: 50 },
        text: "Non-obviousness",
        bullet: {
            level: 0
        }
    })
    paragraphs.push(seventhParagraph3p1)

    const eighthParagraph3p1 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: `The idea is not obvious to a person skilled in your field. For example: inventing a table with 8 legs instead of four legs, it is obvious to a person in the field of furniture manufacturing that the number of legs for a table can be modified to increase the stability of the table. Many patent rejections are under obviousness.`,
                break: 0,
            }),
        ]
    })
    paragraphs.push(eighthParagraph3p1)

    const firstParagraph3p2 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "3.2 Analysis",
                // allCaps: true,
            })
        ]
    })
    paragraphs.push(firstParagraph3p2)

    const analysisSummary = await analyzeProjectSummary(data.documents, data.project)
    // console.log(analysisSummary)
    // Get combinations of two references addressing all features
    let combinationOfTwoReferencesAddressingAllFeatures = []
    analysisSummary.sortedCombinationsAddressingAllFeatures.forEach((combination, idx) => {
        if (!combination.thirdRef) {
            combinationOfTwoReferencesAddressingAllFeatures.push(`${combination.firstRef} and ${combination.secondRef}`)
        }
    })

    let secondParagraph3p2
    if (analysisSummary.singleRefAddressingAllElementsList.length === 0) {
        secondParagraph3p2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            bullet: {
                level: 0
            },
            children: [
                new TextRun({
                    text: `Based on the ${data.documents.length} references provided, none of the references address all the ${data.project.features.length} features/elements of the invention by itself. `,
                    break: 0,
                }),
                new TextRun({
                    text: `Therefore, the invention passes the "Novelty Test". `,
                    break: 0,
                }),
                new TextRun({
                    text: `If any of the prior art discloses all the features/elements of the invention, then the invention lacks novelty and can be considered as if the inventor is inventing the wheel all over again.`,
                    break: 0,
                }),
            ]
        })
    } else if (analysisSummary.singleRefAddressingAllElementsList.length === 1) {
        secondParagraph3p2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            bullet: {
                level: 0
            },
            children: [
                new TextRun({
                    text: `Based on the ${data.documents.length} references provided, reference ${analysisSummary.singleRefAddressingAllElementsList.toString()} addresses all features/elements of the invention. `,
                    break: 0,
                }),
                new TextRun({
                    text: `Therefore, the invention fails the "Novelty Test". `,
                    break: 0,
                }),
                new TextRun({
                    text: `If any of the prior art discloses all the features/elements of the invention, then the invention lacks novelty and can be considered as if the inventor is inventing the wheel all over again.`,
                    break: 0,
                }),
            ]
        })
    } else if (analysisSummary.singleRefAddressingAllElementsList.length > 1) {
        let singleRefAddressingAllElementsString = ''
        for (let index = 0; index < analysisSummary.singleRefAddressingAllElementsList.length; index++) {
            if (index === 0) {
                singleRefAddressingAllElementsString = `${analysisSummary.singleRefAddressingAllElementsList[index]}`
            } else if ((index !== 0) && (index !== analysisSummary.singleRefAddressingAllElementsList.length-1)) {
                singleRefAddressingAllElementsString = `${singleRefAddressingAllElementsString}, ${analysisSummary.singleRefAddressingAllElementsList[index]}`
            } else if (index === analysisSummary.singleRefAddressingAllElementsList.length-1) {
                singleRefAddressingAllElementsString = `${singleRefAddressingAllElementsString} and ${analysisSummary.singleRefAddressingAllElementsList[index]}`
            }
        }
        secondParagraph3p2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            bullet: {
                level: 0
            },
            children: [
                new TextRun({
                    text: `Based on the ${data.documents.length} references provided, references ${singleRefAddressingAllElementsString} each address all features/elements of the invention. `,
                    break: 0,
                }),
                new TextRun({
                    text: `Therefore, the invention fails the "Novelty Test". `,
                    break: 0,
                }),
                new TextRun({
                    text: `If any of the prior art discloses all the features/elements of the invention, then the invention lacks novelty and can be considered as if the inventor is inventing the wheel all over again.`,
                    break: 0,
                }),
            ]
        })
    }
    paragraphs.push(secondParagraph3p2)

    const thirdParagraph3p2 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        bullet: {
            level: 0
        },
        children: [
            new TextRun({
                text: `The second test is a non-obviousness test. `,
                break: 0,
            }),
            new TextRun({
                text: `If all the features/elements are addressed by combining two or three references, then the invention lacks the inventive step or it is considered obvious to anyone in the field to combine two or three references and come up with the same invention.`,
                break: 0,
            }),
        ]
    })
    paragraphs.push(thirdParagraph3p2)

    const fourthParagraph3p2 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        bullet: {
            level: 0
        },
        children: [
            new TextRun({
                text: `If by combining two references (in table 2) all the features are not found (still having green boxes), `,
                break: 0,
            }),
            new TextRun({
                text: `that means the idea is not obvious to an ordinary person in your field and you have a chance of securing a patent.`,
                break: 0,
            }),
        ]
    })
    paragraphs.push(fourthParagraph3p2)

    // Fifth to (n-1)th paragraph3p2
    analysisSummary.sortedCombinationsAddressingAllFeatures.forEach((combination, idx) => {
        if (!combination.thirdRef) {
            const nthParagraph3p2 = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                bullet: {
                    level: 0
                },
                children: [
                    new TextRun({
                        text: `By combining references ${combination.firstRef} and ${combination.secondRef}, all the features are addressed.`,
                        break: 0,
                    }),
                ]
            })
            paragraphs.push(nthParagraph3p2)
        } else {
            const nthParagraph3p2 = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                bullet: {
                    level: 0
                },
                children: [
                    new TextRun({
                        text: `By combining references ${combination.firstRef}, ${combination.secondRef} and ${combination.thirdRef}, all the features are addressed.`,
                        break: 0,
                    }),
                ]
            })
            paragraphs.push(nthParagraph3p2)
        }
    })
    analysisSummary.sortedCombinationsWithOmittedFeatures.forEach((combination, idx) => {
        let featuresNotPresentString = ''
        combination.featuresNotPresent.forEach((feature, idx) => {
            if (idx === 0) {
                featuresNotPresentString = `${feature}`
            } else if ((idx !== 0) && (idx !== combination.featuresNotPresent.length-1)) {
                featuresNotPresentString = `${featuresNotPresentString}, ${feature}`
            } else if (idx === combination.featuresNotPresent.length-1) {
                featuresNotPresentString = `${featuresNotPresentString} and ${feature}`
            }
        })
        if (!combination.thirdRef) {
            const nthParagraph3p2 = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                bullet: {
                    level: 0
                },
                children: [
                    new TextRun({
                        text: `By combining references ${combination.firstRef} and ${combination.secondRef}, all the features are addressed except feature ${featuresNotPresentString}.`,
                        break: 0,
                    }),
                ]
            })
            paragraphs.push(nthParagraph3p2)
        } else {
            const nthParagraph3p2 = new Paragraph({
                style: 'MyNormalStyle',
                alignment: AlignmentType.JUSTIFIED,
                bullet: {
                    level: 0
                },
                children: [
                    new TextRun({
                        text: `By combining references ${combination.firstRef}, ${combination.secondRef} and ${combination.thirdRef}, all the features are addressed except feature ${featuresNotPresentString}.`,
                        break: 0,
                    }),
                ]
            })
            paragraphs.push(nthParagraph3p2)
        }
    })

    // Refer to appendix for analysis tables
    const analysisReferenceParagraph3p2 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        bullet: {
            level: 0
        },
        children: [
            new TextRun({
                text: `Please refer to the `,
                break: 0,
            }),
            new InternalHyperlink({
                anchor: "appendix_analysis",
                children: [
                    new TextRun({
                        text: `appendix`,
                        style: "Hyperlink",
                    }),
                ],
            }),
            new TextRun({
                text: ` to view more reference combinations.`,
                break: 0,
            }),
        ]
    })
    paragraphs.push(analysisReferenceParagraph3p2)

    
    // Check non-obviousness
    if (combinationOfTwoReferencesAddressingAllFeatures.length > 0) {
        const conclusion3p2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            bullet: {
                level: 0
            },
            children: [
                new TextRun({
                    text: `All the features are addressed by combining the above references. Therefore, the invention fails the “Obviousness Test”.`,
                    break: 0,
                }),
            ]
        })
        paragraphs.push(conclusion3p2)
    } else {
        const conclusion3p2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            bullet: {
                level: 0
            },
            children: [
                new TextRun({
                    text: `Any combination of two references together cannot address all the elements/features of the invention. Therefore, the invention passes the “Obviousness Test”.`,
                    break: 0,
                }),
            ]
        })
        paragraphs.push(conclusion3p2)
    }
    
    // Conclusion b 3p2
    if (analysisSummary.singleRefAddressingAllElementsList.length === 0 && combinationOfTwoReferencesAddressingAllFeatures.length === 0) {
        const conclusionb3p2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            children: [
                new TextRun({
                    text: `From the technical perspective of intellectual property, the idea is viable. The idea is qualified for filing a patent application.`,
                    break: 0,
                    italics: true,
                    underline: UnderlineType.SINGLE,
                    bold: true,
                }),
            ]
        })
        paragraphs.push(conclusionb3p2)
    } else {
        const conclusionb3p2 = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            children: [
                new TextRun({
                    text: `From the technical perspective of intellectual property, the idea may be viable but may not be qualified for filing a patent application.`,
                    break: 0,
                    italics: true,
                    underline: UnderlineType.SINGLE,
                    bold: true,
                }),
            ]
        })
        paragraphs.push(conclusionb3p2)
    }

    const firstParagraph3p3 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "3.3 Interpreting the Results",
                // allCaps: true,
            })
        ]
    })
    paragraphs.push(firstParagraph3p3)

    const secondParagraph3p3 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: `Please keep in mind that Riahi Patents creates the worst-case scenario for the client and tries to examine your elements/features as if it is being examined by the patent examiner. The features are being interpreted broadly (typically, that is the case at the patent office), so the client doesn’t face surprises. So, if you feel some of the features presented by the references are disclosing your features broadly, that could be a good sign to evaluate the field that you are working in and potentially filing your patent application in.`,
                break: 0,
            }),
        ]
    })
    paragraphs.push(secondParagraph3p3)
    
    return paragraphs
}

// Section 4
const patentabilityValidation = async (data) => {
    let elements = [
        new Paragraph({
            pageBreakBefore: true,
            heading: HeadingLevel.HEADING_1,
            children: [
                new TextRun({
                    text: "4 Patentability Validation",
                    allCaps: true,
                })
            ]
        }),
        
    ]
    const rows = []
    const headingRow = new TableRow({
        tableHeader: true,
        children:[
            new TableCell({
                width: {
                    size: 5000,
                    type: WidthType.DXA,
                },
                children: [
                    new Paragraph({
                        style: 'MyNormalStyle',
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `Criteria`,
                            })
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                width: {
                    size: 1000,
                    type: WidthType.DXA,
                },
                children: [
                    new Paragraph({
                        style: 'MyNormalStyle',
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `Situation`,
                            })
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            })
        ]
    })
    rows.push(headingRow)

    const projectFeaturesArray = []
    for (let index = 0; index < data.project.features.length; index++) {
        projectFeaturesArray.push(`${index+1}`)
    }

    const analysisSummary = await analyzeProjectSummary(data.documents, data.project)
    // console.log(analysisSummary)
    let combinationOfTwoReferencesAddressingAllFeatures = []
    analysisSummary.sortedCombinationsAddressingAllFeatures.forEach((combination, idx) => {
        if (!combination.thirdRef) {
            combinationOfTwoReferencesAddressingAllFeatures.push(`${combination.firstRef} and ${combination.secondRef}`)
        }
    })

    // Novelty
    if (analysisSummary.singleRefAddressingAllElementsList.length === 0) {
        const row1 = new TableRow ({
            children:[
                new TableCell({
                    width: {
                        size: 7000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: { before: 200, after: 50 },
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Novelty`,
                                    bold: true,
                                })
                            ],
                        }),
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: { before: 50, after: 350 },
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `“To be granted a patent, the invention (as an example: door lock) must be the first of its kind in the world.”`,
                                    bold: false,
                                })
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        left: convertInchesToTwip(0.23),
                        right: convertInchesToTwip(0.23),
                    }
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `Passed`,
                                    bold: true,
                                    allCaps: true,
                                })
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
            ]
        })
        rows.push(row1)
    } else {
        const row1 = new TableRow ({
            children:[
                new TableCell({
                    width: {
                        size: 7000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: { before: 200, after: 50 },
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Novelty`,
                                    bold: true,
                                })
                            ],
                        }),
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: { before: 50, after: 350 },
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `“To be granted a patent, the invention (as an example: door lock) must be the first of its kind in the world.”`,
                                    bold: false,
                                })
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        left: convertInchesToTwip(0.23),
                        right: convertInchesToTwip(0.23),
                    }
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `Failed`,
                                    bold: true,
                                    allCaps: true,
                                })
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
            ]
        })
        rows.push(row1)
    }

    // Utility
    const row2 = new TableRow ({
        children:[
            new TableCell({
                width: {
                    size: 7000,
                    type: WidthType.DXA,
                },
                children: [
                    new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: { before: 200, after: 50 },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `Utility`,
                                bold: true,
                            })
                        ],
                    }),
                    new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: { before: 50, after: 350 },
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: `“A valid patent cannot be obtained for something that does not work or that has no useful function. Example: The door lock must work.”`,
                                bold: false,
                            })
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
                margins: {
                    left: convertInchesToTwip(0.23),
                    right: convertInchesToTwip(0.23),
                }
            }),
            new TableCell({
                width: {
                    size: 2000,
                    type: WidthType.DXA,
                },
                children: [
                    new Paragraph({
                        style: 'MyNormalStyle',
                        alignment: AlignmentType.CENTER,
                        children: [
                            new TextRun({
                                text: `Passed`,
                                bold: true,
                                allCaps: true,
                            })
                        ],
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            })
        ]
    })
    rows.push(row2)

    // Inventiveness
    if (combinationOfTwoReferencesAddressingAllFeatures.length !== 0) {
        const row3 = new TableRow ({
            children:[
                new TableCell({
                    width: {
                        size: 7000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: { before: 200, after: 50 },
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Inventiveness`,
                                    bold: true,
                                })
                            ],
                        }),
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: { before: 50, after: 350 },
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `“To be patentable, your invention must be a new development or an improvement of an existing technology that would not have been obvious to someone working in your area of specialty.`,
                                    bold: false,
                                }),
                                new TextRun({
                                    text: ` Example: The door lock must add an improvement to the field of door locks.”`,
                                    bold: false,
                                })
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        left: convertInchesToTwip(0.23),
                        right: convertInchesToTwip(0.23),
                    }
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `Failed`,
                                    bold: true,
                                    allCaps: true,
                                })
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
            ]
        })
        rows.push(row3)
    } else {
        const row3 = new TableRow ({
            children:[
                new TableCell({
                    width: {
                        size: 7000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: { before: 200, after: 50 },
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `Inventiveness`,
                                    bold: true,
                                })
                            ],
                        }),
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: { before: 50, after: 350 },
                            alignment: AlignmentType.LEFT,
                            children: [
                                new TextRun({
                                    text: `“To be patentable, your invention must be a new development or an improvement of an existing technology that would not have been obvious to someone working in your area of specialty.`,
                                    bold: false,
                                }),
                                new TextRun({
                                    text: ` The door lock must add an improvement to the field of door locks.”`,
                                    bold: false,
                                })
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        left: convertInchesToTwip(0.23),
                        right: convertInchesToTwip(0.23),
                    }
                }),
                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            alignment: AlignmentType.CENTER,
                            children: [
                                new TextRun({
                                    text: `Passed`,
                                    bold: true,
                                    allCaps: true,
                                })
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
            ]
        })
        rows.push(row3)
    }

    const table = new Table({
        rows: rows,
        alignment: AlignmentType.CENTER,
    })

    elements.push(table)

    // 4.1 HORIZONTAL APPROACH

    const section4p1 = new Paragraph({
        pageBreakBefore: false,
        spacing: { before: 50, after: 350 },
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "4.1 Horizontal Approach",
                // allCaps: true,
            })
        ]
    })
    elements.push(section4p1)

    if (analysisSummary.singleRefAddressingAllElementsList.length === 0) {
        const section4p1a = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            text: `None of the references found addresses all the ${data.project.features.length} elements. Therefore, the idea is not lacking novelty.`,
            bullet: {
                level: 0,
            }
        })
        elements.push(section4p1a)
    } else {
        const section4p1a = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            text: `At least one of the references found addresses all the ${data.project.features.length} elements. Therefore, the idea lacks novelty.`,
            bullet: {
                level: 0,
            }
        })
        elements.push(section4p1a)
    }

    const section4p1b = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        text: `Apparently, the idea works and it is not lacking utility.`,
        bullet: {
            level: 0,
        }
    })
    elements.push(section4p1b)
    
    if (combinationOfTwoReferencesAddressingAllFeatures.length !== 0) {
        const section4p1c = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            text: `A combination of two references can address all the elements.`,
            bullet: {
                level: 0,
            }
        })
        elements.push(section4p1c)
    } else {
        const section4p1c = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            text: `No combination of up to two references can address all the elements.`,
            bullet: {
                level: 0,
            }
        })
        elements.push(section4p1c)
    }

    // 4.2 VERTICAL APPROACH (GAP ANALYSIS)
    const section4p2Heading = new Paragraph({
        pageBreakBefore: true,
        spacing: { before: 50, after: 350 },
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "4.2 Vertical Approach (Gap analysis)",
                // allCaps: true,
            })
        ]
    })
    elements.push(section4p2Heading)

    const section4p2Rows = []

    const section4p2TableHeading = new TableRow({
        tableHeader: true,
        children: [
            new TableCell({
                width: {
                    size: 2500,
                    type: WidthType.DXA,
                },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0),
                    bottom: convertInchesToTwip(0),
                },
                children: [
                    new Paragraph({
                        style: 'MyNormalStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "FF3131",
                        //     // fill: "FF3131",
                        // },
                        text: `Feature`,
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                width: {
                    size: 2500,
                    type: WidthType.DXA,
                },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0),
                    bottom: convertInchesToTwip(0),
                },
                children: [
                    new Paragraph({
                        style: 'MyNormalStyle',
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "FF3131",
                        //     // fill: "FF3131",
                        // },
                        text: `% found by the prior art in the reference table`,
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            })
        ],
        
    })
    section4p2Rows.push(section4p2TableHeading)

    let featurePercentagesList = []
    projectFeaturesArray.forEach(projectFeature => {
        const documentsContainingFeature = []
        data.documents.forEach((document) => {
            document.features.forEach((documentFeature) => {
                if (documentFeature.feature === projectFeature) {
                    if (!documentsContainingFeature.includes(document.documentNumber)) {
                        documentsContainingFeature.push(document.documentNumber)
                    }
                }
            });
        })
        // console.log(documentsContainingFeature)
        const percentage = Math.round((documentsContainingFeature.length / data.documents.length) *100)
        // console.log(percentage)
        const featurePercent = {
            feature: projectFeature,
            percentage: percentage
        }
        featurePercentagesList= [...featurePercentagesList, featurePercent]
        if (percentage >= 50) {
            const row = new TableRow({
                children: [
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "FF3131",
                            // fill: "FF3131",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `${projectFeature}`,
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "FF3131",
                            // fill: "FF3131",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `${percentage}`,
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                ],
                
            })
            section4p2Rows.push(row)
        } else {
            const row = new TableRow({
                children: [
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "50C878",
                            // fill: "50C878",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `${projectFeature}`,
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "50C878",
                            // fill: "50C878",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `${percentage}`,
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                ]
            })
            section4p2Rows.push(row)
        }
    });
    
    const section4p2Table = new Table({
        columnWidths: [2500, 2500],
        alignment: AlignmentType.CENTER,
        rows: section4p2Rows
    })
    elements.push(section4p2Table)

    const createConclusionTextRuns = () => {
        
        // console.log(featurePercentagesList.length)
        const percentLessThan50 = []
        featurePercentagesList.forEach(element => {
            if (element.percentage < 50) {
                percentLessThan50.push(element)
            }
        });
        percentLessThan50.sort((a, b) => a.percentage > b.percentage ? 1 : -1)
        let textRuns = []
        if (percentLessThan50.length > 0) {
            textRuns = [
                new TextRun({
                    text: "The presented values (percentage) above show: ",
                })
            ]
        } else {
            textRuns = [
                new TextRun({
                    text: "All references disclosed more than 50% of the features. ",
                })
            ]
        }
        
        percentLessThan50.forEach((item, idx) => {
            if (item.percentage === 0 && (idx !== percentLessThan50.length-2) && (idx !== percentLessThan50.length-1)) {
                const conclusionTextRun = new TextRun({
                    text: `Feature ${item.feature} was not disclosed by any of the references; `,
                })
                textRuns.push(conclusionTextRun)
            } else if (item.percentage === 0 && (idx === percentLessThan50.length-2)) {
                const conclusionTextRun = new TextRun({
                    text: `Feature ${item.feature} was not disclosed by any of the references and `,
                })
                textRuns.push(conclusionTextRun)
            } else if (item.percentage === 0 && (idx === percentLessThan50.length-1)) {
                const conclusionTextRun = new TextRun({
                    text: `Feature ${item.feature} was not disclosed by any of the references. `,
                })
                textRuns.push(conclusionTextRun)
            } else if (item.percentage < 50 && (idx !== percentLessThan50.length-2) && (idx !== percentLessThan50.length-1)) {
                const conclusionTextRun = new TextRun({
                    text: `Feature ${item.feature} was disclosed by ${item.percentage}% of the references; `,
                })
                textRuns.push(conclusionTextRun)
            } else if (item.percentage < 50 && (idx === percentLessThan50.length-2)) {
                const conclusionTextRun = new TextRun({
                    text: `Feature ${item.feature} was disclosed by ${item.percentage}% of the references and `,
                })
                textRuns.push(conclusionTextRun)
            } else if (item.percentage < 50 && (idx === percentLessThan50.length-1)) {
                const conclusionTextRun = new TextRun({
                    text: `Feature ${item.feature} was disclosed by ${item.percentage}% of the references. `,
                })
                textRuns.push(conclusionTextRun)
            }
        })
        if (percentLessThan50.length > 0) {
            const conclusionTextRun2 = new TextRun({
                text: `The values from the table portray the likelihood of securing a patent on the idea.`,
            })
            textRuns.push(conclusionTextRun2)
        } else {
            const conclusionTextRun2 = new TextRun({
                text: `The values from the table portray the likelihood of securing a patent on the idea. `,
            })
            textRuns.push(conclusionTextRun2)
        }

        const conclusionTextRun3 = new TextRun({
            text: ` This shows where the less crowded features are located (identification of gaps in the technology) in the field (low percentage values for the features, `,
        })
        textRuns.push(conclusionTextRun3)
        
        const conclusionTextRun4 = new TextRun({
            text: `less than 50%`,
            bold: true,
            underline: {
                type: UnderlineType.SINGLE
            }
        })
        textRuns.push(conclusionTextRun4)

        const conclusionTextRun5 = new TextRun({
            text: `) and this provides a higher possibility for the client to secure a patent based on the presented features. The gap analysis provides how the technology can be positioned in the market and fill the gaps and therefore chances for securing intellectual property for the client will be higher.`,
        })
        textRuns.push(conclusionTextRun5)
        return textRuns
    }
    
    const summary = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        spacing: { before: 200, after: 50 },
        children: createConclusionTextRuns()
    })
    elements.push(summary)

    if (analysisSummary.singleRefAddressingAllElementsList.length === 0 && combinationOfTwoReferencesAddressingAllFeatures.length === 0) {
        const conclusionSummary = new Paragraph({
            style: 'MyNormalStyle',
            spacing: { before: 200, after: 50 },
            children: [
                new TextRun({
                    text: "Based on information provided, the idea passes the Novelty test, Non-obviousness (inventiveness step) test, and Utility test, therefore the idea may be viable and qualified for filing a patent application.",
                    // allCaps: true,
                    bold: true,
                    underline: {
                        type: UnderlineType.SINGLE,
                        color: "000000",
                    }
                })
            ]
        })
        elements.push(conclusionSummary)
    } else if (analysisSummary.singleRefAddressingAllElementsList.length === 0 && combinationOfTwoReferencesAddressingAllFeatures.length !== 0) {
        const conclusionSummary = new Paragraph({
            style: 'MyNormalStyle',
            spacing: { before: 200, after: 50 },
            children: [
                new TextRun({
                    text: "Based on information provided, the idea passes the Novelty test and Utility test, but fails the Non-obviousness (inventiveness step) test, therefore the idea may be viable but may not qualify for filing a patent application.",
                    // allCaps: true,
                    bold: true,
                    underline: {
                        type: UnderlineType.SINGLE,
                        color: "000000",
                    }
                })
            ]
        })
        elements.push(conclusionSummary)
    } else if (analysisSummary.singleRefAddressingAllElementsList.length !== 0 && combinationOfTwoReferencesAddressingAllFeatures.length === 0) {
        const conclusionSummary = new Paragraph({
            style: 'MyNormalStyle',
            spacing: { before: 200, after: 50 },
            children: [
                new TextRun({
                    text: "Based on information provided, the idea passes the Non-obviousness (inventiveness step) test and Utility test, but fails the Novelty test, therefore the idea may be viable but may not qualify for filing a patent application.",
                    // allCaps: true,
                    bold: true,
                    underline: {
                        type: UnderlineType.SINGLE,
                        color: "000000",
                    }
                })
            ]
        })
        elements.push(conclusionSummary)
    } else if (analysisSummary.singleRefAddressingAllElementsList.length !== 0 && combinationOfTwoReferencesAddressingAllFeatures.length !== 0) {
        const conclusionSummary = new Paragraph({
            style: 'MyNormalStyle',
            spacing: { before: 200, after: 50 },
            children: [
                new TextRun({
                    text: "Based on information provided, the idea passes the Utility test, but fails the Novelty test and the Non-obviousness (inventiveness step) test, therefore the idea may be viable but may not qualify for filing a patent application.",
                    // allCaps: true,
                    bold: true,
                    underline: {
                        type: UnderlineType.SINGLE,
                        color: "000000",
                    }
                })
            ]
        })
        elements.push(conclusionSummary)
    }


    const section4p2DisclaimerPart1 = new Paragraph({
        style: 'MyNormalStyle',
        pageBreakBefore: true,
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: "Legal basis: ",
                italics: true,
            }),
            new ExternalHyperlink({
                children: [
                    new TextRun({
                        text: "Canadian Patent Act",
                        style: "Hyperlink",
                        italics: true,
                    }),
                ],
                link: "https://laws-lois.justice.gc.ca/eng/acts/p-4/",
            }),
        ]
    })
    elements.push(section4p2DisclaimerPart1)

    const section4p2DisclaimerPart2 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: `Please note that Riahi Patents gives the above information ONLY for reference, based on technical terms as of the creation of this report. Riahi Patents can not be held liable for the information, once it does not carry any promise or legal value.`,
                italics: true,
            }),
        ]
    })
    elements.push(section4p2DisclaimerPart2)

    const section4p2DisclaimerPart3 = new Paragraph({
        style: 'MyNormalStyle',
        spacing: {before: 500, after: 150},
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: `This report was generated by PatAnalysis, an Intellectual Property (IP) analysis software developed by Riahi Patents.`,
                bold: true,
                italics: true,
            }),
        ]
    })
    elements.push(section4p2DisclaimerPart3)

    return elements
}

// Section 6
const searchHistory = (data) => {
    let elements = [
        new Paragraph({
            heading: HeadingLevel.HEADING_1,
            children: [
                new TextRun({
                    text: "6 Search Tools and Search Histories",
                    allCaps: true,
                })
            ]
        }),
        new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({
                    text: "6.1 Patent Search Tools and Patent Search History",
                })
            ]
        }),
        new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            children: [
                new TextRun({
                    text: "The search and analysis of patent literature were carried out using Questel’s Orbit software suite and Google Patents. ",
                }),
                new TextRun({
                    text: "A non-patent literature search was carried out using Google Web/Scholar. You can access the Orbit search history for patent literature ",
                }),
                new ExternalHyperlink({
                    children: [
                        new TextRun({
                            text: "here",
                            style: "Hyperlink",
                            italics: false,
                        }),
                    ],
                    link: `${data.project.orbitSearchHistoryURL}`,
                }),
            ]
        }),
        new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({
                    text: "6.2 Related Patent List",
                })
            ]
        }),
        new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            children: [
                new TextRun({
                    text: `${data.project.patentFamilies}`,
                }),
                new TextRun({
                    text: " patent families were tagged throughout the patent search process. You may download the interactive list from ",
                }),
                new ExternalHyperlink({
                    children: [
                        new TextRun({
                            text: "here",
                            style: "Hyperlink",
                            italics: false,
                        }),
                    ],
                    link: `${data.project.patentListURL}`,
                }),
            ]
        }),
        new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({
                    text: "6.3 Google Patents Search History",
                })
            ]
        })
    ]

    // 6.3 Patent Search History
    const patentSearchHistoryRows = []

    const PatentSearchHistoryHeader = new TableRow({
        children: [
            new TableCell({
                width: {
                    size: 1500,
                    type: WidthType.DXA,
                },
                children: [
                    new Paragraph({
                        spacing: {before: 150, after: 150},
                        style: 'MyNormalStyle',
                        children: [
                            new TextRun({
                                text: `Search Step`,
                                bold: true,
                            })
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                width: {
                    size: 8000,
                    type: WidthType.DXA,
                },
                children: [
                    new Paragraph({
                        spacing: {before: 150, after: 150},
                        style: 'MyNormalStyle',
                        children: [
                            new TextRun({
                                text: `Query`,
                                bold: true,
                            })
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ]
    })
    patentSearchHistoryRows.push(PatentSearchHistoryHeader)

    let patentSearchStepCount = 1
    while (data.project[`patentSearchStep${patentSearchStepCount}`]) {
        const PatentSearchHistoryRow = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 500,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            text: `${patentSearchStepCount}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            text: `${data.project[`patentSearchStep${patentSearchStepCount}`]}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
            ]
        })
        patentSearchHistoryRows.push(PatentSearchHistoryRow)
        patentSearchStepCount ++
    }

    
    const patentSearchHistoryTable = new Table({
        alignment: AlignmentType.CENTER,
        rows: patentSearchHistoryRows
    })

    elements.push(patentSearchHistoryTable)


    // 6.4 Scholar Search History
    const scholarSearchHistoryTitle = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: {before: 350},
        children: [
            new TextRun({
                text: "6.4 Google Scholar Search History",
                // allCaps: true,
            })
        ]
    })
    elements.push(scholarSearchHistoryTitle)
    
    const scholarSearchHistoryRows = []

    const scholarSearchHistoryHeader = new TableRow({
        children: [
            new TableCell({
                width: {
                    size: 1500,
                    type: WidthType.DXA,
                },
                children: [
                    new Paragraph({
                        spacing: {before: 150, after: 150},
                        style: 'MyNormalStyle',
                        children: [
                            new TextRun({
                                text: `Search Step`,
                                bold: true,
                            })
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                width: {
                    size: 8000,
                    type: WidthType.DXA,
                },
                children: [
                    new Paragraph({
                        spacing: {before: 150, after: 150},
                        style: 'MyNormalStyle',
                        children: [
                            new TextRun({
                                text: `Query`,
                                bold: true,
                            })
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ]
    })
    scholarSearchHistoryRows.push(scholarSearchHistoryHeader)

    let scholarSearchStepCount = 1
    while (data.project[`scholarSearchStep${scholarSearchStepCount}`]) {
        const scholarSearchHistoryRow = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 500,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            text: `${scholarSearchStepCount}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            text: `${data.project[`scholarSearchStep${scholarSearchStepCount}`]}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
            ]
        })
        scholarSearchHistoryRows.push(scholarSearchHistoryRow)
        scholarSearchStepCount ++
    }

    const scholarSearchHistoryTable = new Table({
        alignment: AlignmentType.CENTER,
        rows: scholarSearchHistoryRows
    })
    elements.push(scholarSearchHistoryTable)


    // 6.5 Classification Search
    const classificationSearchTitle = new Paragraph({
        pageBreakBefore: true,
        heading: HeadingLevel.HEADING_2,
        spacing: {before: 350},
        children: [
            new TextRun({
                text: "6.5 Classification Search",
                // allCaps: true,
            })
        ]
    })

    elements.push(classificationSearchTitle)
    const classificationSearchText = new Paragraph({
        style: 'MyNormalStyle',
        spacing: {before: 250, after: 300},
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: "IPC (International Patent Classification) and CPC (Cooperative Patent Classification) are hierarchical classification systems used primarily to classify and search patent documents (patent applications, specifications of granted patents, utility models, etc.)",
            }),
            new TextRun({
                text: " according to the technical fields they pertain, serving as instruments for the orderly arrangement of the patent documents and a basis for investigating the state of the art in given fields of technology.",
            }),
        ]
    })
    elements.push(classificationSearchText)

    const classificationSearchRows = []

    const classificationSearchHeader = new TableRow({
        children: [
            new TableCell({
                width: {
                    size: 5000,
                    type: WidthType.DXA,
                },
                shading: {
                    type: ShadingType.SOLID,
                    color: "d8d8d8",
                    // fill: "50C878",
                },
                children: [
                    new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: {before: 150, after: 150},
                        
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new TextRun({
                                        text: "IPC Classification (text limit)",
                                        style: "Hyperlink",
                                        italics: false,
                                        bold: true,
                                    }),
                                ],
                                link: "https://ipcpub.wipo.int",
                            }),
                            // new TextRun({
                            //     text: `IPC Classification (text limit)`,
                            //     bold: true,
                            // })
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                width: {
                    size: 5000,
                    type: WidthType.DXA,
                },
                shading: {
                    type: ShadingType.SOLID,
                    color: "d8d8d8",
                    // fill: "50C878",
                },
                children: [
                    new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: {before: 150, after: 150},
                        
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new TextRun({
                                        text: "CPC Classification (text limit)",
                                        style: "Hyperlink",
                                        italics: false,
                                        bold: true,
                                    }),
                                ],
                                link: "https://worldwide.espacenet.com/patent/cpc-browser",
                            }),
                            // new TextRun({
                            //     text: `CPC Classification (text limit)`,
                            //     bold: true,
                            // })
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ]
    })
    classificationSearchRows.push(classificationSearchHeader)

    let classificationSearchBodyCount = 1
    while (data.project[`CPC${classificationSearchBodyCount}`] || data.project[`IPC${classificationSearchBodyCount}`]) {
        const classificationSearchBody = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: {before: 100, after: 150},
                            children: [
                                new TextRun({
                                    text: `${data.project[`IPC${classificationSearchBodyCount}`]}`,
                                })
                            ],
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: {before: 100, after: 100},
                            children: [
                                new TextRun({
                                    text: `${data.project[`CPC${classificationSearchBodyCount}`]}`,
                                })
                            ],
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
            ]
        })
        classificationSearchRows.push(classificationSearchBody)
        classificationSearchBodyCount++
    }
    

    const classificationSearchTable = new Table({
        alignment: AlignmentType.CENTER,
        rows: classificationSearchRows
    })
    elements.push(classificationSearchTable)

    return elements
}

const disclaimer= () => {
    const elements = [
        new Paragraph({
            style: 'MyNormalStyle',
            pageBreakBefore: true,
            spacing: {before: 12000},
            alignment: AlignmentType.JUSTIFIED,
            children: [
                new TextRun({
                    text: "Disclaimer: ",
                    italics: true,
                }),
                new TextRun({
                    text: "This IP Gap analysis report, generated by technical experts at Riahi Patents, is not a legal opinion. It is prepared by comparing technical features of invention with the content available in the patent and non-patent documents. Riahi Patents disclaims any liability for loss or damage resulting from reliance on this report.",
                    italics: true,
                }),
            ]
        }),
    ]
    return elements
}

async function saveDocumentToFile(doc, fileName, mimeType) {
    // Create a mime type that will associate the new file with Microsoft Word
    // const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    // Create a Blob containing the Document instance and the mimeType
    Packer.toBlob(doc).then(blob => {
      const docblob = blob.slice(0, blob.size, mimeType)
      // Save the file using saveAs from the file-saver package
      saveAs(docblob, fileName)
    })
}

export const generateReportTypeA = async (data) => {
    // console.log(data)
    const doc = new Document({
        creator: "Riahi-IP",
        description: `Report generated for ${data.project.projNum}`,
        title: `${data.project.projNum}`,
        // background: {
        //     color: "FFFFFF",
        // },
        // features: {
        //     updateFields: true,
        // },
        styles: {
            default: {
                heading1: {
                    run: {
                        size: 28,
                        bold: true,
                        italics: false,
                        color: "000000",
                        font: "arial",
                    },
                    paragraph: {
                        spacing: {
                            after: 120,
                        },
                    },
                },
                heading2: {
                    run: {
                        size: 26,
                        bold: true,
                        font: "arial",
                        // underline: {
                        //     type: UnderlineType.DOUBLE,
                        //     color: "000000",
                        // },
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                        font: "arial",
                    },
                },
                listParagraph: {
                    run: {
                        color: "000000",
                        font: "arial",
                    },
                },
            },
            paragraphStyles: [
                {
                    id: "MySpectacularStyle",
                    name: "My Spectacular Style",
                    basedOn: "Heading1",
                    next: "Heading1",
                    quickFormat: true,
                    run: {
                        italics: true,
                        color: "990000",
                        font: "arial",
                        size: 24,
                    },
                },
                {
                    id: "MyNormalStyle",
                    name: "My Normal Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: false,
                        color: "000000",
                        font: "arial",
                        size: 24,
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: {
                            line: 276,
                        },
                    },
                },
                {
                    id: "MyTableStyle",
                    name: "My Table Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: false,
                        color: "000000",
                        font: "arial",
                        size: 20,
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: {
                            line: 276,
                        },
                    },
                },
                {
                    id: "MyItalicsStyle",
                    name: "My Italics Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: true,
                        color: "000000",
                        font: "arial",
                        size: 24,
                    },
                    paragraph: {
                        spacing: {
                            line: 276,
                        },
                    },
                },
            ],
        },
        sections: [
            {
                // COVER PAGE
                properties: {
                    type: SectionType.CONTINUOUS,
                    // add background color (not working)
                    background: {
                        color: "000000",
                    },
                    page: {
                        margin: {
                            top: convertInchesToTwip(3.2),
                            bottom: convertInchesToTwip(0.5),
                            left: convertInchesToTwip(1),
                            right: convertInchesToTwip(0.5),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "FF3131",
                            // fill: "FF3131",
                        },
                    },
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: "Riahi Patents, Inc.",
                                size: 40,
                                bold: true,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 600 },
                        children: [
                            new TextRun({
                                text: "IP GAP ANALYSIS REPORT",
                                size: 56,
                                bold: true,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 1000 },
                        children: [
                            new TextRun({
                                text: `${data.project.projNum}`,
                                size: 36,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 300 },
                        children: [
                            new TextRun({
                                text: `Title: `,
                                size: 36,
                                bold: false,
                                font: "arial",
                            }),
                            new TextRun({
                                text: `${data.project.projTitle}`,
                                size: 36,
                                bold: false,
                                font: "arial",
                                allCaps: true,
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 1800 },
                        children: [
                            new TextRun({
                                text: "Prepared for ",
                                size: 36,
                                font: "arial",
                            }),
                            new TextRun({
                                text: `${data.project.company}`,
                                size: 36,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 400 },
                        children: [
                            new TextRun({
                                text: `${data.project.submissionDate}`,
                                size: 36,
                                font: "arial",
                            }),
                        ],
                    }),
                ],
            },
            {
                // TABLE OF CONTENT
                children: [
                    new TableOfContents("Table of Content", {
                        run: {
                            size: 28,
                            bold: true,
                            italics: false,
                            color: "000000",
                            font: "arial",
                        },
                        hyperlink: true,
                        headingStyleRange: "1-5",
                        stylesWithLevels: [
                            new StyleLevel('MyNormalStyle', 1),
                            // new StyleLevel("MyNormalStyle", 2),
                            // new StyleLevel("MyNormalStyle", 3),
                        ],
                        // entryAndPageNumberSeparator: '',
                    })
                ]
            },
            {
                // 1 PROJECT ELEMENTS TABLE
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        children: [PageNumber.CURRENT],
                                        font: 'arial',
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                spacing: {after: 400 },
                                children: [
                                    // new ImageRun({
                                    //     // data: "https://images.unsplash.com/photo-1530595467537-0b5996c41f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
                                    //     transformation: {
                                    //         width: 50,
                                    //         height: 50,
                                    //     },
                                    // }),
                                    new TextRun({
                                        text: `${data.project.projNum}`,
                                        allCaps: true,
                                        font: "arial",
                                        size: 24,
                                        bold: false,
                                    }),
                                    // new TextRun({
                                    //     children: [PageNumber.CURRENT],
                                    // })
                                ]
                            }),
                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                border: {
                                    top: {
                                        color: 'auto',
                                        space: 1,
                                        style: "single",
                                        size: 10,
                                    }
                                },
                                children: [
                                    // new SymbolRun({
                                    //     char: "+1F310",
                                    //     size: 30,
                                    //     font: 'arial',
                                    //     // bold: true,
                                    //     // italics: true,
                                    // }),
                                    new TextRun({
                                        text: "Riahi Patents, Inc.",
                                        size: 24,
                                        font: 'arial',
                                    }),
                                    new TextRun({
                                        text: "                                                                    ",
                                        size: 24,
                                        font: 'arial',
                                    }),
                                    new ExternalHyperlink({
                                        children: [
                                            new TextRun({
                                                text: "https://riahipatents.com",
                                                style: "Hyperlink",
                                                size: 24,
                                                font: 'arial',
                                            }),
                                        ],
                                        link: "https://riahipatents.com",
                                    }),
                                    // new TextRun({
                                    //     children: [PageNumber.CURRENT],
                                    // }),
                                    
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: "Confidential Document",
                                        size: 24,
                                        font: 'arial',
                                        italics: true,
                                    }),
                                    // new TextRun({
                                    //     children: [PageNumber.CURRENT],
                                    // }),
                                    
                                ]
                            }),
                           
                        ],
                    }),
                },
                children: [
                    new Paragraph({
                        pageBreakBefore: true,
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: "1 introduction",
                                allCaps: true,
                            })
                        ]
                    }),
                    new Paragraph({
                        spacing: { before: 250, after: 200 },
                        alignment: AlignmentType.JUSTIFIED,
                        style: 'MyNormalStyle',
                        children: [
                            new TextRun({
                                text: `Riahi Patents, Inc. has received the search request from ${data.project.company}.`,
                                italics: false,
                            }),
                        ]
                        
                        
                    }),
                    await projectElementsTable(data)
                ]
            },
            {
                // 2 PRIOR ART REFERENCE TABLE
                // properties: {
                //     type: SectionType.CONTINUOUS,
                // },
                children: [
                    new Paragraph({
                        pageBreakBefore: true,
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: "2 prior reference table",
                                allCaps: true,
                            })
                        ]
                    }),
                    priorArtReferenceTable(data)
                ]
            },
            {
                // 3 GENERAL NOTES
                properties: {
                    type: SectionType.CONTINUOUS,
                },
                children: await analysis(data)
            },
            {
                // 4 PATENTABILITY VALIDATION
                properties: {
                    type: SectionType.NEXT_PAGE,
                },
                children: await patentabilityValidation(data)
            },
            {
                // 5 IP VIABILITY RECOMMENDATIONS
                // type: SectionType.CONTINUOUS,
                style: 'MyNormalStyle',
                children: [
                    new Paragraph({
                        // pageBreakBefore: true,
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: "5 IP VIABILITY RECOMMENDATIONS",
                                allCaps: true,
                            })
                        ]
                    }),
                    new Paragraph({
                        style: 'MyNormalStyle',
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `One of the most important parameters that both investors and startups are looking for is how viable the idea is in terms of intellectual property.`,
                                break: 1,
                            }),
                        ]
                    }),
                    new Paragraph({
                        style: 'MyNormalStyle',
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `Based on the prior art table provided in the IP Strategy Report, there are ${data.project.features.length} features/elements that need to be found by the prior art - either alone, by a single reference, or in combination, using no more than two references. In case a single reference addresses all the elements, the idea lacks novelty and, if two references address all the elements, the idea lacks an inventive step (obviousness).`,
                                break: 1,
                            }),
                        ]
                    }),
                    new Paragraph({
                        style: 'MyNormalStyle',
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `The client will have the option to file for a US provisional patent application, which accommodates the client to receive the patent-pending status for 12 months.`,
                                break: 1,
                            }),
                        ]
                    }),
                    new Paragraph({
                        style: 'MyNormalStyle',
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `The inventors are highly encouraged to file for a US Provisional patent application based on the IP analysis provided in this report and use the 12-month period to work on the prototype and get ready for their Non-provisional patent application by the end of the 12-month time frame.`,
                                underline: {
                                    type: UnderlineType.SINGLE,
                                    color: "000000",
                                },
                                bold: true,
                                break: 1,
                            }),
                        ]
                    }),
                    new Paragraph({
                        style: 'MyNormalStyle',
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `Please keep in mind that Riahi Patents just provides the technical opinion and our reports don’t provide any legal opinion.`,
                                break: 1,
                            }),
                        ]
                    }),
                    new Paragraph({
                        heading: HeadingLevel.HEADING_2,
                        children: [
                            new TextRun({
                                text: "5.1 Patent Filing Options",
                            })
                        ]
                    }),
                    // new Paragraph({
                    //     style: 'MyNormalStyle',
                    //     alignment: AlignmentType.JUSTIFIED,
                    //     children: [
                    //         new TextRun({
                    //             text: `The client has the choice to file for a US provisional patent application and within 12 months pursue a US non-provisional patent application (please reach out to the patent attorney for the details on the cost).`,
                    //             break: 1,
                    //         }),
                    //     ]
                    // }),
                    new Paragraph({
                        style: 'MyNormalStyle',
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `The client has the option to file a Canadian application, a US provisional patent application and/or a US non-provisional patent application.`,
                                break: 1,
                            }),
                        ]
                    }),
                    new Paragraph({
                        style: 'MyNormalStyle',
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                text: `Another option for the client would be to file a `,
                                break: 1,
                            }),
                            new ExternalHyperlink({
                                children: [
                                    new TextRun({
                                        text: "PCT application",
                                        style: "Hyperlink",
                                    }),
                                ],
                                link: "https://www.wipo.int/pct/en/faqs/faqs.html",
                            }),
                            // new TextRun({
                            //     text: `PCT application`,
                            //     underline: {
                            //         type: UnderlineType.SINGLE,
                            //         color: "000000",
                            //     },
                            //     break: 1,
                            // }),
                            new TextRun({
                                text: ` (Patent Cooperation Treaty) which allows the client to file a patent application in multiple countries within 18 months of filing the PCT application. It should be noted that the client needs to pay the attorney fees and patent filing fees in each of the jurisdictions separately.`,
                            }),
                        ]
                    }),
                ]
            },
            {
                // 6 SEARCH TOOLS AND SEARCH HISTORIES
                properties: {
                    type: SectionType.NEXT_PAGE,
                },
                children: searchHistory(data)
            },
            {
                // APPENDIX - CITATIONS
                // type: SectionType.CONTINUOUS,
                style: 'MyNormalStyle',
                children: await citationReferences(data)
            },
            {
                // APPENDIX - ANALYSIS
                // type: SectionType.CONTINUOUS,
                style: 'MyNormalStyle',
                children: await analysisTables(data)
            },
            {
                // DISCLAIMER
                // type: SectionType.CONTINUOUS,
                style: 'MyNormalStyle',
                children: disclaimer()
            },
            {
                // BACK COVER PAGE
                properties: {
                    type: SectionType.NEXT_PAGE,
                    page: {
                        margin: {
                            top: convertInchesToTwip(1.5),
                            left: convertInchesToTwip(1),
                        },
                    },
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { after: 200 },
                        children: [
                            new TextRun({
                                text: "https://riahipatents.com",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { after: 200 },
                        children: [
                            new TextRun({
                                text: "bit.ly/dr_riahi",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { after: 200 },
                        children: [
                            new TextRun({
                                text: "projects@riahi-ip.com",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 0, after: 100 },
                        children: [
                            new TextRun({
                                text: "5307 Victoria Drive #267 Vancouver, BC V5P 3V6",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 0, after: 100 },
                        children: [
                            new TextRun({
                                text: "1300-1969 Upper Water St., Mcinnes Cooper Tower, Purdy's Wharf, Halifax, NS B3J 3R7",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { after: 200 },
                        children: [
                            new TextRun({
                                text: "295 Hagey Blvd., Waterloo, ON, N2L 6R5",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { after: 20 },
                        children: [
                            new TextRun({
                                text: "+1(226)458-8423",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                ],
            }
        ],
    });
    const projectTitle = (`IP Gap Analysis Report - ${data.project.projNum.replaceAll('-','.')}.docx`)
    await saveDocumentToFile(doc, projectTitle, "application/vnd.openxmlformats-officedocument.wordprocessingml.document")

    // return Packer.toBuffer(doc).then((buffer) => {
    //     fs.writeFileSync(`${data.project.projNum}.docx`, buffer);
    // });
}