import './AnnotateFeatureButtonsItem.css'

import { useState } from 'react';

import { createTextAnnotation } from '../../utilities/api/projects';
import { formNotificationStyle2 } from '../../utilities/services/formNotification';


export default function AnnotateFeatureButtonsItem({user, documentData, updateDocument, id, selectedText, clearAnnotationText, currentProject, updateCurrentProject, currentDocument}) {
    // const NumReg = /^[0-9]{1,}$/g
    const [defaultState] = useState({
        annotationType: '',
        Number: '',
        publicationDate: '',
        documentTitle: ''
    })
    const [formData, setFormData] = useState(defaultState)
    const [showMore, setShowMore] = useState(false)

    const handleFeatureTextPreview = () => {
        showMore ? setShowMore(false) : setShowMore(true)
    }

    const handleDropdownClick = (evt) => {
        // prevent dropdown from closing when clicked
        evt.stopPropagation()
    }

    const handleChange = (evt) => {
        const newFormData = {
            ...formData,// use the existing formData
            [evt.target.name]: evt.target.value, // override whatever key with the current field's value
        };
        setFormData(newFormData);
    }

    const handleSubmit = async (evt) => {
        evt.preventDefault()
        const submissionButton = document.querySelector('.annotationSubmissionButton')
        submissionButton.disabled = true
        try {
            let data = {
                classification: documentData.classification
            }
            if (documentData.classification === 'PD') {
                if (formData.annotationType === 'claim') {
                    data = {
                        ...data,
                        user: user._id,
                        projectNumber: currentProject.projNum,
                        claimNumber: formData.Number,
                        paragraphNumber: '',
                        abstract: `${false}`,
                        document: documentData.publicationNumber||'null',
                        publicationDate: documentData.publicationDate||'null',
                        assignees: documentData.assigneeList.toString()||'null',
                        title: documentData.title||'null',
                        text: selectedText,
                        feature: id
                    }
                } else if (formData.annotationType === 'paragraph') {
                    let paragraph = ''
                    if (formData.Number.length === 1) {
                        paragraph = `000${formData.Number}`
                    } else if (formData.Number.length === 2) {
                        paragraph = `00${formData.Number}`
                    } else if (formData.Number.length === 3) {
                        paragraph = `0${formData.Number}`
                    } else if (formData.Number.length === 4) {
                        paragraph = `${formData.Number}`
                    }
                    data = {
                        ...data,
                        user: user._id,
                        projectNumber: currentProject.projNum,
                        paragraphNumber: paragraph,
                        claimNumber: '',
                        abstract: `${false}`,
                        document: documentData.publicationNumber||'null',
                        publicationDate: documentData.publicationDate||'null',
                        assignees: documentData.assigneeList.toString()||'null',
                        title: documentData.title||'null',
                        text: selectedText,
                        feature: id
                    }
                } else if (formData.annotationType === 'abstract') {
                    data = {
                        ...data,
                        user: user._id,
                        projectNumber: currentProject.projNum,
                        paragraphNumber: '',
                        claimNumber: '',
                        abstract: `${true}`,
                        document: documentData.publicationNumber||'null',
                        publicationDate: documentData.publicationDate||'null',
                        assignees: documentData.assigneeList.toString()||'null',
                        title: documentData.title||'null',
                        text: selectedText,
                        feature: id
                    }
                }
            } else if (documentData.classification === 'NPD') {
                data = {
                    ...data,
                    user: user._id,
                    projectNumber: currentProject.projNum,
                    paragraphNumber: '',
                    claimNumber: '',
                    abstract: ``,
                    document: documentData.url,
                    publicationDate: currentDocument.documentPublicationDate? `${currentDocument.documentPublicationDate}` :`${formData.publicationDate}`,
                    title: currentDocument.documentPublicationDate? `${currentDocument.documentTitle}` :`${formData.documentTitle}`,
                    text: selectedText,
                    feature: id
                }
            } else if (documentData.classification === 'PDF') {
                data = {
                    ...data,
                    user: user._id,
                    projectNumber: currentProject.projNum,
                    paragraphNumber: '',
                    claimNumber: '',
                    abstract: ``,
                    document: documentData.url,
                    publicationDate: currentDocument.documentPublicationDate? `${currentDocument.documentPublicationDate}` : `${formData.publicationDate}`,
                    title: currentDocument.documentPublicationDate? `${currentDocument.documentTitle}` : `${formData.documentTitle}`,
                    text: selectedText,
                    feature: id
                }
            }
            
            // console.log(data)
            await createTextAnnotation(currentProject, data)
            await updateCurrentProject(currentProject.projNum)
            formNotificationStyle2(`annotationNotification-Feature${id}`, 'Successfully Added', 'green', '0.7')
            setFormData(defaultState)
            clearAnnotationText()
            
        } catch (error) {
            formNotificationStyle2(`annotationNotification-Feature${id}`, 'Error- Unable to add annotation', 'red', '1')
            setFormData({
                ...formData,
            })
        }
        submissionButton.disabled = false
    }
    // const disabled = !(NumReg.test(formData.Number)) || !selectedText || !formData.annotationType

  return (
    <div className='m-1 AnnotateFeatureButtonsItem'>
        <div className="dropdown annotateFeatureButtonsItemDropdown">
            <div className="annotateFeatureButtonsItemDropdownToggle" id='annotateFeatureButtonsItemDropdownToggle' data-title='Create annotation for element' type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <div className='feature'>
                    <div className='featureNum'>
                        {id}
                    </div>
                    <div className='element'>
                        Element
                    </div>
                </div>
                <div className='arrow'>
                    <svg width="9" viewBox="0 0 17 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L8.5 8L16 1" stroke="#64748B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </div>
            </div>
            <div className="dropdown-menu annotateFeatureButtonsItemDropdown" aria-labelledby="annotateFeatureButtonsItemDropdownToggle" onClick={handleDropdownClick}>
                <div className='annotateFeatureButtonsItemDropdownContainer'>
                    <h6>{`Element ${id}`}</h6>
                    {currentProject.features[id -1].featureText.length < 100 ?
                    <div className='previewFeatureText'><p>{currentProject.features[id -1].featureText}</p></div>
                    :
                    <>
                    { showMore ?
                    <div className='previewFeatureText'><p>{currentProject.features[id -1].featureText} <span className='featureTextPreview' onClick={handleFeatureTextPreview}><small>Show Less</small></span></p></div>
                    :
                    <div className='previewFeatureText'><p>{currentProject.features[id -1].featureText.substring(0,100)} ...<span className='featureTextPreview' onClick={handleFeatureTextPreview}><small>Show More</small></span></p></div>
                    }
                    </>
                    }
                    <div className='previewSelectedText'><p>{selectedText}</p></div>
                    <form onSubmit={handleSubmit} className="annotationTextForm">
                        <div className='formFields'>
                            {documentData.classification === 'PD' &&
                            <>
                                <div className='mb-3 w-100'>
                                    <select className='form-select me-2 text-center' title='annotationType' name="annotationType" id="annotationType" value={formData.annotationType} onChange={handleChange} required >
                                        <option value=""  >---</option>
                                        <option value="abstract">Abstract</option>
                                        <option value="paragraph">Description</option>
                                        <option value="claim">Claim</option>
                                    </select>
                                </div>
                                {   formData.annotationType==='abstract' ?
                                <div className='mb-3 bg-danger'>
                                    {/* <input onChange={handleChange} type="text" name='Number' id={`feature${id}Number`} placeholder='N/A' className='form-control me-2 ' value={'N/A'} disabled={true}/> */}
                                </div>
                                :
                                formData.annotationType==='paragraph' ?
                                <div className='mb-3 w-100'>
                                    <input onChange={handleChange} type="number" min={0} name='Number' id={`feature${id}Number`} placeholder='Paragraph num' className='form-control me-2 ' value={formData.Number} required />
                                </div>
                                :
                                formData.annotationType==='claim' ?
                                <div className='mb-3 w-100'>
                                    <input onChange={handleChange} type="number" min={0} name='Number' id={`feature${id}Number`} placeholder='Claim Number' className='form-control me-2 ' value={formData.Number} required />
                                </div>
                                :
                                <div></div>
                                }
                            </>
                            }
                            {documentData.classification === 'NPD' &&
                            <>
                                <div className='NPD text-center w-100'>
                                    { currentDocument.documentTitle && currentDocument.documentPublicationDate ?
                                    <>
                                        <div className='mb-2'>
                                            <label htmlFor="publicationDate">Publication Date</label>
                                            <input onChange={handleChange} type="date" name='publicationDate' title='Publication Date' id={`publicationDate`} className='form-control me-2 ' value={currentDocument.documentPublicationDate} disabled />
                                        </div>
                                        <div className='mb-2'>
                                            <label htmlFor="documentTitle">Document Title</label>
                                            <input onChange={handleChange} type="text" name='documentTitle' title='Document Title' id={`documentTitle`} className='form-control me-2 ' value={currentDocument.documentTitle} disabled />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='mb-2'>
                                            <label htmlFor="publicationDate">Publication Date</label>
                                            <input onChange={handleChange} type="date" name='publicationDate' id={`publicationDate`} title='Publication Date' className='form-control me-2 ' value={formData.publicationDate} required />
                                        </div>
                                        <div className='mb-2'>
                                            <label htmlFor="documentTitle">Document Title</label>
                                            <input onChange={handleChange} type="text" name='documentTitle' title='Document Title' id={`documentTitle`} className='form-control me-2 ' value={formData.documentTitle} placeholder='Enter document title' required />
                                        </div>
                                    </>
                                    }
                                    
                                </div>
                            </>
                            }
                            {documentData.classification === 'PDF' &&
                            <>
                                <div className='NPD text-center w-100'>
                                    { currentDocument.documentTitle && currentDocument.documentPublicationDate ?
                                    <>
                                        <div className='mb-2'>
                                            <label htmlFor="publicationDate">Publication Date</label>
                                            <input onChange={handleChange} type="date" name='publicationDate' title='Publication Date' id={`publicationDate`} className='form-control me-2 ' value={currentDocument.documentPublicationDate} disabled />
                                        </div>
                                        <div className='mb-2'>
                                            <label htmlFor="documentTitle">Document Title</label>
                                            <input onChange={handleChange} type="text" name='documentTitle' title='Document Title' id={`documentTitle`} className='form-control me-2 ' value={currentDocument.documentTitle} disabled />
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='mb-2'>
                                            <label htmlFor="publicationDate">Publication Date</label>
                                            <input onChange={handleChange} type="date" name='publicationDate' title='Publication Date' id={`publicationDate`} className='form-control me-2 ' value={formData.publicationDate} required />
                                        </div>
                                        <div className='mb-2'>
                                            <label htmlFor="documentTitle">Document Title</label>
                                            <input onChange={handleChange} type="text" name='documentTitle' title='Document Title' id={`documentTitle`} className='form-control me-2 ' value={formData.documentTitle} placeholder='Enter document title' required />
                                        </div>
                                    </>
                                    }
                                    
                                </div>
                            </>
                            }
                        </div>
                        <div className=''>
                            <button type="submit" className="annotationSubmissionButton" disabled={false}>Create Annotation</button>
                        </div>
                    </form>
                    <div><p id={`annotationNotification-Feature${id}`}className='annotationNotification text-center'><small></small></p></div>
                </div>
            </div>
        </div>
    </div>
    
  )
}
