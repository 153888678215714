import { useParams } from 'react-router-dom'
import './DocumentFeatureItem.css'
import { useEffect, useState } from 'react'
import { rotateImageAnnotation } from '../../utilities/api/projects'

export default function DocumentFeatureItem({user, project, feature, documentFeatureNum, index, removeTextAnnotation, removeImageAnnotation, updateProject}) {

  const [rotating, setRotating] = useState(false)

  const params = useParams()
  // console.log(feature)

  const handleTextAnnotationDelete = (evt) => {
    // console.log(feature)
    const data = { feature: feature, user: user }
    removeTextAnnotation(data)
  }

  const handleImageAnnotationDelete = (evt) => {
    // console.log(feature)
    const data = { feature: feature, user: user }
    removeImageAnnotation(data)
  }
  
  const rotateImage = async () => {
    setRotating(true)
    const newRotate = feature.rotate !== 270 ? feature.rotate + 90 : 0
    feature.rotate = newRotate
    try {
      await rotateImageAnnotation(project.projNum, feature)
      await updateProject(project.projNum)
    } catch (error) {
      console.log(error)
    }
    setRotating(false)
  }

  // Rotate image based on rotate value
  useEffect(() => {
    if (feature.imageURL) {
      const img = new Image();
      img.src = feature.imageURL;
      img.onload = function() {
        const imgElement = document.querySelector(`.DocumentFeatureItem .element img[src='${feature.imageURL}']`)
        imgElement.style.transform = `rotate(${feature.rotate}deg)`
      }
    }
  }, [feature])

  return (
    <div className='DocumentFeatureItem'>
      <div className='topContent'>
        <div className='elementNum'>
          <div className='elementNumContainer'>
            <span className='item1'>
              {feature.feature}
            </span>
            <span className='item2'>
              &nbsp;&nbsp;Element
            </span>
          </div>
        </div>
        {feature.imageURL && (((project.status === 'inProgress') || 
        (project.status === 'inReview' && (user.userType==='admin' || user.userType==='manager'))))
        &&
        <div className={`imageRotation ${rotating && 'disabled'}`} title='Rotate image clockwise' onClick={rotateImage}>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16">
            <path fillRule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
            <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
          </svg>
        </div>
        }
      </div>
      { feature.classification === 'PD' &&
        <div className='annotation'>
          {feature.paragraphNumber &&
          <div className='annotationContainer'>
            <div className='element'>
              {
                feature.paragraphNumber === '0000' ?
                ''
                :
                <strong className='d-inline notranslate'>{`[Para ${feature.paragraphNumber}] `}</strong>
              }
              <p className='d-inline'>{feature.textAnnotation}</p>
            </div>
            {params.projectNumber &&
            <>
            { project && (((project.status === 'inProgress') || 
              (project.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))))
              &&
              <div className='annotationDeleteContainer'>
                <button className='annotationDeleteButton' title='delete' data-bs-toggle="modal" data-bs-target={`#deleteFeature${documentFeatureNum}Item${index+1}`}>
                  <svg width="13" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7101 7.7017C17.9316 7.7017 18.1326 7.80175 18.2914 7.9708C18.4395 8.15135 18.514 8.3756 18.4924 8.61249C18.4924 8.69069 17.9003 16.4416 17.562 19.7041C17.3502 21.7063 16.1032 22.9218 14.2327 22.9552C12.7944 22.9885 11.3885 23 10.0042 23C8.53458 23 7.09736 22.9885 5.70229 22.9552C3.89442 22.9103 2.64632 21.6729 2.44532 19.7041C2.09736 16.4301 1.51599 8.69069 1.50519 8.61249C1.49438 8.3756 1.56786 8.15135 1.71699 7.9708C1.86395 7.80175 2.07575 7.7017 2.29836 7.7017H17.7101ZM12.2941 0C13.2764 0 14.1539 0.709543 14.4078 1.72153L14.5894 2.56102C14.7363 3.24527 15.3091 3.72941 15.9682 3.72941H19.2079C19.6402 3.72941 20 4.10086 20 4.5735V5.0105C20 5.47165 19.6402 5.85459 19.2079 5.85459H0.793171C0.359844 5.85459 0 5.47165 0 5.0105V4.5735C0 4.10086 0.359844 3.72941 0.793171 3.72941H4.03285C4.69094 3.72941 5.26367 3.24527 5.41171 2.56217L5.58137 1.77788C5.84504 0.709543 6.71277 0 7.70586 0H12.2941Z" fill="#E21D12"/>
                  </svg>
                </button>
                <div className="modal fade" id={`deleteFeature${documentFeatureNum}Item${index+1}`} data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`} aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`}>
                                Delete Annotation
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className='modalAnnotationText'>
                                {feature.textAnnotation}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="cancel" data-bs-dismiss="modal">Cancel</button>
                            <button onClick={handleTextAnnotationDelete} type="button" className="submit" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            }
            </>
            }
          </div>
          }
          {feature.claimNumber &&
          <div className='annotationContainer'>
            <div className='element'>
              <strong className='d-inline notranslate'>{`Claim ${feature.claimNumber}: `}</strong>
              <p className='d-inline'>{feature.textAnnotation}</p>
            </div>
            {params.projectNumber &&
            <>
            { project && (((project.status === 'inProgress') || 
              (project.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))))
              &&
              <div className='annotationDeleteContainer'>
                <button className='annotationDeleteButton' title='delete' data-bs-toggle="modal" data-bs-target={`#deleteFeature${documentFeatureNum}Item${index+1}`}>
                  <svg width="13" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7101 7.7017C17.9316 7.7017 18.1326 7.80175 18.2914 7.9708C18.4395 8.15135 18.514 8.3756 18.4924 8.61249C18.4924 8.69069 17.9003 16.4416 17.562 19.7041C17.3502 21.7063 16.1032 22.9218 14.2327 22.9552C12.7944 22.9885 11.3885 23 10.0042 23C8.53458 23 7.09736 22.9885 5.70229 22.9552C3.89442 22.9103 2.64632 21.6729 2.44532 19.7041C2.09736 16.4301 1.51599 8.69069 1.50519 8.61249C1.49438 8.3756 1.56786 8.15135 1.71699 7.9708C1.86395 7.80175 2.07575 7.7017 2.29836 7.7017H17.7101ZM12.2941 0C13.2764 0 14.1539 0.709543 14.4078 1.72153L14.5894 2.56102C14.7363 3.24527 15.3091 3.72941 15.9682 3.72941H19.2079C19.6402 3.72941 20 4.10086 20 4.5735V5.0105C20 5.47165 19.6402 5.85459 19.2079 5.85459H0.793171C0.359844 5.85459 0 5.47165 0 5.0105V4.5735C0 4.10086 0.359844 3.72941 0.793171 3.72941H4.03285C4.69094 3.72941 5.26367 3.24527 5.41171 2.56217L5.58137 1.77788C5.84504 0.709543 6.71277 0 7.70586 0H12.2941Z" fill="#E21D12"/>
                  </svg>
                </button>
                <div className="modal fade" id={`deleteFeature${documentFeatureNum}Item${index+1}`} data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`} aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`}>
                                Delete Annotation
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className='modalAnnotationText'>
                                {feature.textAnnotation}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="cancel" data-bs-dismiss="modal">Cancel</button>
                            <button onClick={handleTextAnnotationDelete} type="button" className="submit" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            }
            </>
            }
          </div>
          }
          {feature.abstract &&
          <div className='annotationContainer'>
            <div className='element'>
              <strong className='d-inline'>{`Abstract: `}</strong>
              <p className='d-inline'>{feature.textAnnotation}</p>
            </div>
            {params.projectNumber &&
            <>
            { project && (((project.status === 'inProgress') || 
              (project.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))))
              &&
              <div className='annotationDeleteContainer'>
                <button className='annotationDeleteButton' title='delete' data-bs-toggle="modal" data-bs-target={`#deleteFeature${documentFeatureNum}Item${index+1}`}>
                  <svg width="13" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7101 7.7017C17.9316 7.7017 18.1326 7.80175 18.2914 7.9708C18.4395 8.15135 18.514 8.3756 18.4924 8.61249C18.4924 8.69069 17.9003 16.4416 17.562 19.7041C17.3502 21.7063 16.1032 22.9218 14.2327 22.9552C12.7944 22.9885 11.3885 23 10.0042 23C8.53458 23 7.09736 22.9885 5.70229 22.9552C3.89442 22.9103 2.64632 21.6729 2.44532 19.7041C2.09736 16.4301 1.51599 8.69069 1.50519 8.61249C1.49438 8.3756 1.56786 8.15135 1.71699 7.9708C1.86395 7.80175 2.07575 7.7017 2.29836 7.7017H17.7101ZM12.2941 0C13.2764 0 14.1539 0.709543 14.4078 1.72153L14.5894 2.56102C14.7363 3.24527 15.3091 3.72941 15.9682 3.72941H19.2079C19.6402 3.72941 20 4.10086 20 4.5735V5.0105C20 5.47165 19.6402 5.85459 19.2079 5.85459H0.793171C0.359844 5.85459 0 5.47165 0 5.0105V4.5735C0 4.10086 0.359844 3.72941 0.793171 3.72941H4.03285C4.69094 3.72941 5.26367 3.24527 5.41171 2.56217L5.58137 1.77788C5.84504 0.709543 6.71277 0 7.70586 0H12.2941Z" fill="#E21D12"/>
                  </svg>
                </button>
                <div className="modal fade" id={`deleteFeature${documentFeatureNum}Item${index+1}`} data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`} aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`}>
                                Delete Annotation
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className='modalAnnotationText'>
                                {feature.textAnnotation}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="cancel" data-bs-dismiss="modal">Cancel</button>
                            <button onClick={handleTextAnnotationDelete} type="button" className="submit" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            }
            </>
            }
          </div>
          }
          { feature.imageURL &&
          <div className='annotationContainer'>
            <div className='element d-flex justify-content-center align-items-center'>
              <img className='d-inline border' src={feature.imageURL} alt="Not found" />
            </div>
            {params.projectNumber &&
            <>
            { project && (((project.status === 'inProgress') || 
              (project.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))))
              &&
              <div className='annotationDeleteContainer'>
                <button className='annotationDeleteButton' title='delete' data-bs-toggle="modal" data-bs-target={`#deleteFeature${documentFeatureNum}Item${index+1}`}>
                  <svg width="13" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7101 7.7017C17.9316 7.7017 18.1326 7.80175 18.2914 7.9708C18.4395 8.15135 18.514 8.3756 18.4924 8.61249C18.4924 8.69069 17.9003 16.4416 17.562 19.7041C17.3502 21.7063 16.1032 22.9218 14.2327 22.9552C12.7944 22.9885 11.3885 23 10.0042 23C8.53458 23 7.09736 22.9885 5.70229 22.9552C3.89442 22.9103 2.64632 21.6729 2.44532 19.7041C2.09736 16.4301 1.51599 8.69069 1.50519 8.61249C1.49438 8.3756 1.56786 8.15135 1.71699 7.9708C1.86395 7.80175 2.07575 7.7017 2.29836 7.7017H17.7101ZM12.2941 0C13.2764 0 14.1539 0.709543 14.4078 1.72153L14.5894 2.56102C14.7363 3.24527 15.3091 3.72941 15.9682 3.72941H19.2079C19.6402 3.72941 20 4.10086 20 4.5735V5.0105C20 5.47165 19.6402 5.85459 19.2079 5.85459H0.793171C0.359844 5.85459 0 5.47165 0 5.0105V4.5735C0 4.10086 0.359844 3.72941 0.793171 3.72941H4.03285C4.69094 3.72941 5.26367 3.24527 5.41171 2.56217L5.58137 1.77788C5.84504 0.709543 6.71277 0 7.70586 0H12.2941Z" fill="#E21D12"/>
                  </svg>
                </button>
                <div className="modal fade" id={`deleteFeature${documentFeatureNum}Item${index+1}`} data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`} aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`}>
                                Delete Annotation
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className='modalAnnotationImage'>
                              <img className='d-inline border' width='200px' src={feature.imageURL} alt="Not found" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="cancel" data-bs-dismiss="modal">Cancel</button>
                            <button onClick={handleImageAnnotationDelete} type="button" className="submit" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            }
            </>
            }
          </div>
          }
        </div>
      }
      { (feature.classification === 'NPD' || feature.classification === 'PDF') &&
        <div className='annotation'>
          { feature.imageURL ?
          <div className='annotationContainer'>
            <div className='element d-flex justify-content-center  align-items-center'>
              <img className='d-inline border' src={feature.imageURL} alt="Not found" />
            </div>
            {params.projectNumber &&
            <>
            { project && (((project.status === 'inProgress') || 
              (project.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))))
              &&
              <div className='annotationDeleteContainer'>
                <button className='annotationDeleteButton' title='delete' data-bs-toggle="modal" data-bs-target={`#deleteFeature${documentFeatureNum}Item${index+1}`}>
                  <svg width="13" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7101 7.7017C17.9316 7.7017 18.1326 7.80175 18.2914 7.9708C18.4395 8.15135 18.514 8.3756 18.4924 8.61249C18.4924 8.69069 17.9003 16.4416 17.562 19.7041C17.3502 21.7063 16.1032 22.9218 14.2327 22.9552C12.7944 22.9885 11.3885 23 10.0042 23C8.53458 23 7.09736 22.9885 5.70229 22.9552C3.89442 22.9103 2.64632 21.6729 2.44532 19.7041C2.09736 16.4301 1.51599 8.69069 1.50519 8.61249C1.49438 8.3756 1.56786 8.15135 1.71699 7.9708C1.86395 7.80175 2.07575 7.7017 2.29836 7.7017H17.7101ZM12.2941 0C13.2764 0 14.1539 0.709543 14.4078 1.72153L14.5894 2.56102C14.7363 3.24527 15.3091 3.72941 15.9682 3.72941H19.2079C19.6402 3.72941 20 4.10086 20 4.5735V5.0105C20 5.47165 19.6402 5.85459 19.2079 5.85459H0.793171C0.359844 5.85459 0 5.47165 0 5.0105V4.5735C0 4.10086 0.359844 3.72941 0.793171 3.72941H4.03285C4.69094 3.72941 5.26367 3.24527 5.41171 2.56217L5.58137 1.77788C5.84504 0.709543 6.71277 0 7.70586 0H12.2941Z" fill="#E21D12"/>
                  </svg>
                </button>
                <div className="modal fade" id={`deleteFeature${documentFeatureNum}Item${index+1}`} data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`} aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`}>
                                Delete Annotation
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className='modalAnnotationImage'>
                              <img className='d-inline border' width='200px' src={feature.imageURL} alt="Not found" />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="cancel" data-bs-dismiss="modal">Cancel</button>
                            <button onClick={handleImageAnnotationDelete} type="button" className="submit" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            }
            </>
            }
          </div>
          :
          <div className='annotationContainer'>
            <div className='element'>
              <p className='d-inline'>{feature.textAnnotation}</p>
            </div>
            {params.projectNumber &&
            <>
            { project && (((project.status === 'inProgress') || 
              (project.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))))
              &&
              <div className='annotationDeleteContainer'>
                <button className='annotationDeleteButton' title='delete' data-bs-toggle="modal" data-bs-target={`#deleteFeature${documentFeatureNum}Item${index+1}`}>
                  <svg width="13" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.7101 7.7017C17.9316 7.7017 18.1326 7.80175 18.2914 7.9708C18.4395 8.15135 18.514 8.3756 18.4924 8.61249C18.4924 8.69069 17.9003 16.4416 17.562 19.7041C17.3502 21.7063 16.1032 22.9218 14.2327 22.9552C12.7944 22.9885 11.3885 23 10.0042 23C8.53458 23 7.09736 22.9885 5.70229 22.9552C3.89442 22.9103 2.64632 21.6729 2.44532 19.7041C2.09736 16.4301 1.51599 8.69069 1.50519 8.61249C1.49438 8.3756 1.56786 8.15135 1.71699 7.9708C1.86395 7.80175 2.07575 7.7017 2.29836 7.7017H17.7101ZM12.2941 0C13.2764 0 14.1539 0.709543 14.4078 1.72153L14.5894 2.56102C14.7363 3.24527 15.3091 3.72941 15.9682 3.72941H19.2079C19.6402 3.72941 20 4.10086 20 4.5735V5.0105C20 5.47165 19.6402 5.85459 19.2079 5.85459H0.793171C0.359844 5.85459 0 5.47165 0 5.0105V4.5735C0 4.10086 0.359844 3.72941 0.793171 3.72941H4.03285C4.69094 3.72941 5.26367 3.24527 5.41171 2.56217L5.58137 1.77788C5.84504 0.709543 6.71277 0 7.70586 0H12.2941Z" fill="#E21D12"/>
                  </svg>
                </button>
                <div className="modal fade" id={`deleteFeature${documentFeatureNum}Item${index+1}`} data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`} aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="modal-title" id={`deleteFeature${documentFeatureNum}ItemHeader${index+1}`}>
                                Delete Annotation
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className='modalAnnotationText'>
                                {feature.textAnnotation}
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="cancel" data-bs-dismiss="modal">Cancel</button>
                            <button onClick={handleTextAnnotationDelete} type="button" className="submit" data-bs-dismiss="modal">Delete</button>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
            }
            </>
            }
          </div>
          }
        </div>
      }
    </div>
  )
}
