import './App.css';
import { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { getProjects, getProject, getProjectDocuments } from '../../utilities/api/projects';
import { getUser } from '../../utilities/services/users';
import { getServices } from '../../utilities/api/services';
import Home from '../Home/Home';
import UserProfile from '../UserProfile/UserProfile';
import ProjectDetails from '../ProjectDetails/ProjectDetails'; 
import Login from '../Login/Login'
import Blank from '../Blank/Blank'
import Nav from '../../components/Nav/Nav';
import Projects from '../Projects/Projects';
import Users from '../Users/Users';
import SearchHistory from '../SearchHistory/SearchHistory';
import ProjectAnalysis from '../ProjectAnalysis/ProjectAnalysis';
import Help from '../Help/Help';


export default function App() {
  const [services, setServices] = useState([])
  const [user, setUser] = useState(getUser());
  const [projects, setProjects] = useState([])
  const [projectsLoading, setProjectsLoading] = useState(true)
  const [documentData, setDocumentData] = useState({classification: null})
  const [currentProject, setCurrentProject] = useState('')
  const [projectDocuments, setProjectDocuments] = useState()
  const [showGallery, setShowGallery] = useState(false)
  const [showPdf, setShowPdf] = useState(false)
  const [showFeatures, setShowFeatures] = useState(false)
  const [annotationText, setAnnotationText] = useState('')
  const [currentImage, setCurrentImage] = useState(0)
  const [documentLoading, setDocumentLoading] = useState(false)
  const defaultKeyWords = {
    keyword1: '',
    keyword2: '',
    keyword3: '',
    keyword4: '',
    keyword5: '',
    keyword6: '',
    keyword7: '',
    keyword8: '',
  }
  const [keyWords, setKeyWords] = useState(defaultKeyWords)
  // This list of colors is not currently being used. It is a list of colors that can be used to highlight the keywords.
  const [keyWordColors] = useState(['#e1affd', '#90ee90', '#0398dc', '#fdbb6d', '#ff595e', '#eea2ad', '#bd9a73', '#bebebe'])

  const updateUser = (user) => {
    setUser(user)
  }

  const updateCurrentImage = (index) => {
    setCurrentImage(index)
  }

  // console.log(documentData)
  const updateDocument =async (patentData) => {
    // console.log('New data', patentData)
    // setDocumentData({})
    setDocumentData(patentData)
  }

  const updateProjects = (projects) => {
    setProjects(projects)
  }

  const toggleDocumentLoading = (boolean) => {
    setDocumentLoading(boolean)
  }

  const projectWithSortedFeatures = (project) => {
    const projectFeatures = project.features
    projectFeatures.forEach((feature) => {
      feature.featureNum = parseInt(feature.featureNum)
    })
    const sortedProjectFeatures = projectFeatures.sort((a, b) => a.featureNum > b.featureNum ? 1 : -1)
    project.features = sortedProjectFeatures
    return project
  }

  const updateCurrentProject = async (projectNumber) => {
    try {
      const project = await getProject(projectNumber)
      // console.log(project)
      const documents = await getProjectDocuments(project.projNum)
      if (project.analystId === user._id || user.userType === 'admin' || user.userType === 'manager') {
        setCurrentProject(projectWithSortedFeatures(project))
        setProjectDocuments(documents)
        setShowFeatures(true)
      }else{
        setCurrentProject(null)
        setTimeout(() => {
          setCurrentProject('')
        }, 1000);
      }
    } catch (err) {
      console.log(err)
    }
  }

  const renderGallery = (evt) => {
    setShowPdf(false)
    setShowGallery(true)
    // console.log(evt.target)
  }

  const hideGallery = (evt) => {
    setShowGallery(false)
  }

  const renderPdf = (evt) => {
    setShowGallery(false)
    setShowPdf(true)
    // console.log(evt.target)
  }

  const hidePdf = (evt) => {
    setShowPdf(false)
  }

  const toggleFeatures = (evt) => {
    setShowFeatures(!showFeatures)    
  }

  const updateAnnotationText = (txt) => {
    setAnnotationText(txt)
  }
  // console.log(annotationText)
  const clearAnnotationText = () => {
    setAnnotationText('')
  }

  const updateKeyWords = (words) => {
    setKeyWords(words)
  }
  
  const clearKeyWords = () => {
    setKeyWords(defaultKeyWords)
  }

  useEffect(() => {
    const getServicesData = async () => {
      const data = await getServices()
      setServices(data)
    }
    getServicesData()
  },[])

  useEffect(() => {
    if (!user) return
    const getRelevantProjects = async () => {
      try {
        const myProjects = await getProjects(user._id)
        updateProjects(myProjects)
      } catch (error) {
        console.log(error)
      }
      setProjectsLoading(false)
    }
    getRelevantProjects()
  },[user])
  return (
    <div className='App'>
      {user? 
      <main className='body'>
        <Nav user={user} updateUser={updateUser}/>
        <Routes>
          <Route path='/blank' element={<Blank />}/>
          { (user.userType === 'admin' || user.userType === 'manager') &&
          <>
          <Route path='/document/:patentId' element={<Home 
          user={user}
          services={services}
          annotationText={annotationText} 
          updateAnnotationText={updateAnnotationText}
          clearAnnotationText={clearAnnotationText}
          projects={projects}
          updateProjects={updateProjects}
          projectsLoading={projectsLoading}
          currentProject={currentProject}
          projectDocuments={projectDocuments}
          updateCurrentProject={updateCurrentProject}
          documentData={documentData} 
          updateDocument={updateDocument} 
          toggleDocumentLoading={toggleDocumentLoading}
          documentLoading={documentLoading}
          showGallery={showGallery} 
          renderGallery={renderGallery} 
          hideGallery={hideGallery}
          currentImage={currentImage}
          updateCurrentImage={updateCurrentImage}
          showPdf={showPdf}
          renderPdf={renderPdf}
          hidePdf={hidePdf}
          toggleFeatures={toggleFeatures}
          showFeatures={showFeatures}
          keyWords={keyWords}
          updateKeyWords={updateKeyWords}
          clearKeyWords={clearKeyWords}
          keyWordColors={keyWordColors} />}
          />
          <Route path='/' element={<Home 
          user={user}
          services={services}
          annotationText={annotationText} 
          updateAnnotationText={updateAnnotationText}
          clearAnnotationText={clearAnnotationText}
          currentProject={currentProject}
          projects={projects}
          updateProjects={updateProjects}
          projectsLoading={projectsLoading}
          projectDocuments={projectDocuments}
          updateCurrentProject={updateCurrentProject}
          documentData={documentData} 
          updateDocument={updateDocument} 
          toggleDocumentLoading={toggleDocumentLoading}
          documentLoading={documentLoading}
          showGallery={showGallery} 
          renderGallery={renderGallery} 
          hideGallery={hideGallery} 
          currentImage={currentImage}
          updateCurrentImage={updateCurrentImage}
          showPdf={showPdf}
          renderPdf={renderPdf}
          hidePdf={hidePdf}
          toggleFeatures={toggleFeatures}
          showFeatures={showFeatures}
          keyWords={keyWords}
          updateKeyWords={updateKeyWords}
          clearKeyWords={clearKeyWords}
          keyWordColors={keyWordColors} />} 
          />
          <Route path='/users' element={<Users user={user} />} />
          <Route path='/projects' element={<Projects user={user} projects={projects} projectsLoading={projectsLoading} updateProjects={updateProjects} services={services} />} />
          <Route path='/projects/:projectNumber' element={<ProjectDetails user={user} services={services} updateProjects={updateProjects} />} />
          <Route path='/userprofile' element={<UserProfile user={user} />} />
          <Route path='/orbitSearchHistory/:projectNumber' element={<SearchHistory user={user} />}/>
          <Route path='/project/:projectNumber/projectAnalysis' element={<ProjectAnalysis user={user} />} />
          <Route path='/help' element={<Help />} />
          </>
          }
          { user.userType === 'analyst' &&
          <>
          <Route path='/document/:patentId' element={<Home 
          user={user}
          services={services}
          annotationText={annotationText} 
          updateAnnotationText={updateAnnotationText}
          clearAnnotationText={clearAnnotationText}
          projects={projects}
          updateProjects={updateProjects}
          projectsLoading={projectsLoading}
          currentProject={currentProject}
          projectDocuments={projectDocuments}
          updateCurrentProject={updateCurrentProject}
          documentData={documentData} 
          updateDocument={updateDocument} 
          toggleDocumentLoading={toggleDocumentLoading}
          documentLoading={documentLoading}
          showGallery={showGallery} 
          renderGallery={renderGallery} 
          hideGallery={hideGallery} 
          currentImage={currentImage}
          updateCurrentImage={updateCurrentImage}
          showPdf={showPdf}
          renderPdf={renderPdf}
          hidePdf={hidePdf}
          toggleFeatures={toggleFeatures}
          showFeatures={showFeatures}
          keyWords={keyWords}
          updateKeyWords={updateKeyWords}
          clearKeyWords={clearKeyWords}
          keyWordColors={keyWordColors} />} 
          />
          <Route path='/' element={<Home 
          user={user}
          services={services}
          annotationText={annotationText} 
          updateAnnotationText={updateAnnotationText}
          clearAnnotationText={clearAnnotationText}
          currentProject={currentProject}
          projects={projects}
          updateProjects={updateProjects}
          projectsLoading={projectsLoading}
          projectDocuments={projectDocuments}
          updateCurrentProject={updateCurrentProject}
          documentData={documentData} 
          updateDocument={updateDocument} 
          toggleDocumentLoading={toggleDocumentLoading}
          documentLoading={documentLoading}
          showGallery={showGallery} 
          renderGallery={renderGallery} 
          hideGallery={hideGallery} 
          currentImage={currentImage}
          updateCurrentImage={updateCurrentImage}
          showPdf={showPdf}
          renderPdf={renderPdf}
          hidePdf={hidePdf}
          toggleFeatures={toggleFeatures}
          showFeatures={showFeatures}
          keyWords={keyWords}
          updateKeyWords={updateKeyWords}
          clearKeyWords={clearKeyWords}
          keyWordColors={keyWordColors} />} 
          />
          <Route path='/userprofile' element={<UserProfile user={user} />} />
          <Route path='/projects' element={<Projects user={user} projects={projects} projectsLoading={projectsLoading} updateProjects={updateProjects} services={services} />} />
          <Route path='/projects/:projectNumber' element={<ProjectDetails user={user} services={services} updateProjects={updateProjects} />} />
          <Route path='/orbitSearchHistory/:projectNumber' element={<SearchHistory user={user} />}/>
          <Route path='/project/:projectNumber/projectAnalysis' element={<ProjectAnalysis user={user} />} />
          <Route path='/help' element={<Help />} />
          </>
          }
          { user.userType === 'client' &&
          <>
          <Route path='/' element={<UserProfile user={user} />} />
          <Route path='/projects' element={<Projects user={user} projects={projects} projectsLoading={projectsLoading} updateProjects={updateProjects} services={services} />} />
          <Route path='/projects/:projectNumber' element={<ProjectDetails user={user} services={services} />} />
          <Route path='/*' element={<UserProfile user={user} />} />
          </>
          }
          <Route path='/*' element={<Home 
          user={user}
          annotationText={annotationText} 
          updateAnnotationText={updateAnnotationText}
          clearAnnotationText={clearAnnotationText}
          currentProject={currentProject}
          projects={projects}
          updateProjects={updateProjects}
          projectDocuments={projectDocuments}
          updateCurrentProject={updateCurrentProject}
          documentData={documentData} 
          updateDocument={updateDocument} 
          toggleDocumentLoading={toggleDocumentLoading}
          documentLoading={documentLoading}
          showGallery={showGallery} 
          renderGallery={renderGallery} 
          hideGallery={hideGallery} 
          currentImage={currentImage}
          updateCurrentImage={updateCurrentImage}
          showPdf={showPdf}
          renderPdf={renderPdf}
          hidePdf={hidePdf}
          toggleFeatures={toggleFeatures}
          showFeatures={showFeatures}
          keyWords={keyWords}
          updateKeyWords={updateKeyWords}
          clearKeyWords={clearKeyWords}
          keyWordColors={keyWordColors} />} />
        </Routes>
      </main>
      :
      <main className='login body'>
        <Routes>
          <Route path='/*' element={<Login user={user} updateUser={updateUser} />} />
        </Routes>
      </main>
      
      }
      
    </div>
  );
}


