import { useState } from 'react'
import './UsersItem.css'
import { resetAccountPassword } from '../../utilities/api/users'
import { formNotificationStyle1 } from '../../utilities/services/formNotification'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter'

export default function UsersItem({currentUser, idx, userItem, deleteUserItem, updateUserStatus}) {
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  })
  const [newPassword, setNewPassword] = useState('')

  const handleDeleteUser = (evt) => {
    deleteUserItem(userItem)
  }

  const handleUpdateUserStatus = (evt) => {
    const data = {
      currentUser: currentUser,
      user: userItem,
      newStatus: userItem.status === 'active' ? 'inactive' : 'active',
    }
    updateUserStatus(data)
  }

  const handleResetUserPassword =async (evt) => {
    evt.preventDefault()
    const data = {
      currentUser: currentUser,
      user: userItem,
      newPassword: formData.newPassword,
    }
    try {
      const response = await resetAccountPassword(data)
      formNotificationStyle1('passwordResetNotification', `${response.msg}`, '#158444', 'white', 1)
      // console.log(response)
      setNewPassword(formData.newPassword)
      setFormData({
        newPassword: '',
        confirmPassword: '',
      })
    } catch (error) {
      formNotificationStyle1('passwordResetNotification', `${error.message}`, '#E21D12', 'white', 1)
    }
    
  }

  function handleChange(evt) {
    const newFormData = {
        ...formData,
        [evt.target.name]: evt.target.value,
    };
    setFormData(newFormData)
  }

  const disabled = formData.newPassword === '' || formData.newPassword.length < 5 || formData.confirmPassword === '' || formData.newPassword !== formData.confirmPassword

  return (
    <div className='UsersItem'>
      <div className='firstContainer'>
        <div className='section1'>
          <svg xmlns="http://www.w3.org/2000/svg" width="30" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
            <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
          </svg>
          <div className='userInfo'>
            <div className='usersItemName'>
              {`${capitalizeFirstLetter(userItem.firstName)} ${capitalizeFirstLetter(userItem.lastName)}`}
            </div>
            <div className='tertiaryText'>
              {`${userItem.email}`}
            </div>
          </div>
        </div>
        <div className='section2'>
          { ((currentUser._id !== userItem._id) && (currentUser.userType === 'admin')) &&
          <>
          <div className='disableUser'>
            { userItem.status === 'inactive' ?
            <>
            <label className="switch" onClick={handleUpdateUserStatus}>
              <input type="checkbox"/>
              <span className="slider round"></span>
            </label>
            <span className='errorText'>Disabled</span>
            </>
            :
            <>
            <label className="switch" onClick={handleUpdateUserStatus}>
              <input type="checkbox" defaultChecked />
              <span className="slider round"></span>
            </label>
            <span className='primaryText'>Enabled</span>
            </>
            }
          </div>
          <div className='resetPassword'>
            <button type='button' className='btn btn-light btn-sm' title='Reset Password' data-bs-toggle="modal" data-bs-target={`#resetPasswordModal${idx}`}>
              Reset Password
            </button>
            <div className='modal fade' id={`resetPasswordModal${idx}`} data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`resetPasswordHeader`} aria-hidden="true" >
              <div className='modal-dialog modal-dialog-centered'>
                <div className='formContainer modal-content'>
                  <div className='header'>
                    <div className='left'>
                      <div className='first'>
                        <svg width="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M7.875 12.375V7.875C7.875 6.38316 8.46763 4.95242 9.52252 3.89752C10.5774 2.84263 12.0082 2.25 13.5 2.25C14.9918 2.25 16.4226 2.84263 17.4775 3.89752C18.5324 4.95242 19.125 6.38316 19.125 7.875V12.375M5.625 12.375H21.375C22.6176 12.375 23.625 13.3824 23.625 14.625V22.5C23.625 23.7426 22.6176 24.75 21.375 24.75H5.625C4.38236 24.75 3.375 23.7426 3.375 22.5V14.625C3.375 13.3824 4.38236 12.375 5.625 12.375Z" stroke="#344054" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </div>
                      <span>&nbsp;&nbsp;</span>
                      <div className='second'>
                        <div className='sec1'>
                          Reset Password
                        </div>
                        <div className='sec2 tertiaryText'>
                          Reset Password for 
                          <span>{`${capitalizeFirstLetter(userItem.firstName)} ${capitalizeFirstLetter(userItem.lastName)}`}</span>
                        </div>
                      </div>
                    </div>
                    <div className='right'>
                      <svg data-bs-dismiss="modal" width="20" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="16.2666" cy="16.2666" r="15.7666" fill="#EDEFF2" stroke="#EEEEEE"/>
                        <path d="M21.1093 12L12 21.1093M12 12L21.1093 21.1093" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                      </svg>
                    </div>
                  </div>
                  <form onSubmit={handleResetUserPassword} id={`resetPassword${idx}`}>
                    <div className='formContent'>
                      <div className=''>
                        <label htmlFor={`newPassword${idx}`}>New Password</label>
                        <input className='form-control' type="text" name="newPassword" id={`newPassword${idx}`} placeholder='Enter New Password' value={formData.newPassword} onChange={handleChange} required autoComplete='off'/>
                      </div>
                      { (formData.newPassword && formData.newPassword.length < 5) && 
                      <small className="errorText">&nbsp;Must be at least 5 characters long!</small>
                      }
                      <div className='mt-3'>
                        <label htmlFor={`confirmPassword${idx}`}>Confirm Password</label>
                        <input className='form-control' type="text" name="confirmPassword" id={`confirmPassword${idx}`} placeholder='Enter Confirm Password' value={formData.confirmPassword} onChange={handleChange} required autoComplete='off' />
                      </div>
                      { (formData.newPassword && formData.confirmPassword && (formData.newPassword !== formData.confirmPassword)) && 
                      <small className="errorText">&nbsp;Passwords do not match!</small>
                      }
                    </div>
                    <div className='d-flex justify-content-center formButtonContainer'>
                      <button data-bs-dismiss="modal" className='cancel'>Cancel</button>
                      <button className="primary submit" form={`resetPassword${idx}`} type="submit" disabled={disabled}>Change Password</button>
                    </div>
                  </form>
                  <div><p id='passwordResetNotification'className='passwordResetNotification text-center'></p></div>
                  { newPassword &&
                  <div className='showNewPassword'>
                    <div>The new Password is: <strong>{newPassword}</strong></div>
                    <small className='errorText'>Please copy this password now, as it may not be shown again.</small>
                  </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='deleteButton'>
            <button className='deleteButtonModalTrigger' data-bs-toggle="modal" data-bs-target={`#deleteConfirmation${idx}`} title='delete'>
              <svg width="15" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.9391 7.27913C16.1384 7.27913 16.3193 7.36233 16.4623 7.50289C16.5955 7.65302 16.6626 7.83949 16.6432 8.03648C16.6432 8.1015 16.1102 14.5466 15.8058 17.2594C15.6152 18.9242 14.4929 19.935 12.8094 19.9627C11.5149 19.9904 10.2496 20 9.00379 20C7.68112 20 6.38763 19.9904 5.13206 19.9627C3.50498 19.9254 2.38168 18.8965 2.20079 17.2594C1.88763 14.537 1.36439 8.1015 1.35467 8.03648C1.34494 7.83949 1.41108 7.65302 1.54529 7.50289C1.67756 7.36233 1.86818 7.27913 2.06852 7.27913H15.9391ZM11.0647 0.875C11.9488 0.875 12.7385 1.465 12.967 2.30649L13.1304 3.00455C13.2627 3.57351 13.7781 3.97609 14.3714 3.97609H17.2871C17.6761 3.97609 18 4.28495 18 4.67797V5.04134C18 5.42479 17.6761 5.74322 17.2871 5.74322H0.713853C0.32386 5.74322 0 5.42479 0 5.04134V4.67797C0 4.28495 0.32386 3.97609 0.713853 3.97609H3.62957C4.22185 3.97609 4.7373 3.57351 4.87054 3.0055L5.02323 2.35335C5.26054 1.465 6.0415 0.875 6.93527 0.875H11.0647Z" fill="#E21D12"/>
              </svg>
            </button>
            <div className="modal fade" id={`deleteConfirmation${idx}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`deleteConfirmationHeader${idx}`} aria-hidden="true">
              <div className="modal-dialog ">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title errorText" id={`deleteConfirmationHeader${idx}`}>
                      Delete {`${capitalizeFirstLetter(userItem.firstName)} ${capitalizeFirstLetter(userItem.lastName)}`}'s Account
                    </h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <p>Are you sure you want to delete <strong>{`${userItem.firstName} ${userItem.lastName}`}</strong>'s Account?</p>
                    <p>You could disable the account instead.</p>
                    <p className='errorText'><strong>This action cannot be undone!</strong></p>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="closeButton" data-bs-dismiss="modal">Close</button>
                    <button onClick={handleDeleteUser} type="button" className="deleteUserButton" data-bs-dismiss="modal">Delete</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </>
          }
        </div>
      </div>
      <div className='secondContainer'>
        <div className='section1'>
          <div className='userType'>{capitalizeFirstLetter(userItem.userType)}</div>
          <div className='userCreationDate'>{userItem.createdAt.split('T')[0]}</div>
        </div>
        <div className='section2'>
          <div className='userCompany'>
            <span>
              Company:&nbsp;
            </span>
            <span>
            {capitalizeFirstLetter(userItem.company)}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
