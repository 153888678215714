import { useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import DocumentFeature from '../../components/DocumentFeature/DocumentFeature'
import PriorArtsReference from '../../components/PriorArtsReference/PriorArtsReference'
import { deleteImageAnnotation, deleteReferenceElements, deleteTextAnnotation, getProject, getProjectDocuments } from '../../utilities/api/projects'
import './ProjectDetails.css'
import ProjectFeatures from '../../components/ProjectFeatures/ProjectFeatures'
import ProjectStatusActions from '../../components/ProjectStatusActions/ProjectStatusActions'
import ProjectComments from '../../components/ProjectComments/ProjectComments'
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter'
import { getReferenceNotes } from '../../utilities/api/referenceNotes'


export default function ProjectDetails({user, services, updateProjects}) {
  const projectParams = useParams()
  const [loading, setLoading] = useState(true)
  const [project, setProject] = useState('')
  const [elements, setElements]= useState([])
  const [documents, setDocuments] = useState()
  const [referenceNotes, setReferenceNotes] = useState()
  const [showProjectElements, setShowProjectElements] = useState(false)
  const navigate = useNavigate()

  // console.log(user)
  // console.log(project)
  // console.log(services)
  // console.log(documents)
  const projectWithSortedFeatures = (project) => {
    const projectFeatures = project.features
    projectFeatures.forEach((feature) => {
      feature.featureNum = parseInt(feature.featureNum)
    })
    const sortedProjectFeatures = projectFeatures.sort((a, b) => a.featureNum > b.featureNum ? 1 : -1)
    project.features = sortedProjectFeatures
    return project
  }

  const updateProject = async (projectNumber) => {
    try {
      const projectData = await getProject(projectNumber)
      const documentFeatures = await getProjectDocuments(projectNumber)
      // console.log(projectData)
      // console.log(documentFeatures)
      if (projectData) {
        const referenceNotesData = await getReferenceNotes(projectData._id)
        if (user.userType === 'admin' || user.userType === 'manager') {
          setProject(projectWithSortedFeatures(projectData))
          setDocuments(documentFeatures)
          setReferenceNotes(referenceNotesData)
        } else if (user.userType === 'analyst') {
          if (user._id === projectData.analystId) {
            setProject(projectWithSortedFeatures(projectData))
            setDocuments(documentFeatures)
            setReferenceNotes(referenceNotesData)
          }else{
            navigate('/projects')
            window.location.reload()
          }
        } else if (user.company && (user.userType === 'client')) {
          if (user.company === projectData.company) {
            setProject(projectWithSortedFeatures(projectData))
            setDocuments(documentFeatures)
            setReferenceNotes(referenceNotesData)
          } else {
            navigate('/projects')
            window.location.reload()
          }
        }
        setLoading(false)
      } else {
        navigate('/projects')
        window.location.reload()
      }
    } catch (error) {
      navigate('/projects')
      // window.location.reload()
    }
  }

  const countProjectElements = (project) => {
    setElements([])
    const elementsList = []
    for (let index = 0; index < project.featureQuantity; index++) {
      // console.log(index+1)
      elementsList.push((index+1).toString())
    }
    setElements(elementsList)
  }

  const toggleProjectElements = () => {
    setShowProjectElements(!showProjectElements)
  }

  const removeTextAnnotation = async (data) => {
    await deleteTextAnnotation(project.projNum, data)
    await updateProject(project.projNum)
  }

  const removeImageAnnotation = async (data) => {
    await deleteImageAnnotation(project.projNum, data)
    await updateProject(project.projNum)
  }

  const removeReferenceDocuments = async (data) => {
    await deleteReferenceElements(project.projNum, data)
    await updateProject(project.projNum)
  }

  useEffect(() => {
    updateProject(projectParams.projectNumber)
    // eslint-disable-next-line
  },[])

  useEffect(() => { 
    countProjectElements(project)
    // eslint-disable-next-line
  },[project])

  return (
    <div className='ProjectDetails'>
      { loading &&
      <div className='loading'>
        <div className='spinner-border' role='status'>
        </div>
      </div>
      }
      { (!loading && project) &&
      <>
        <div className='content'>
          <div className='details'>
            <div className='projectTitle'>
              <svg width="45" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="80" height="80" rx="15" fill="white"/>
                <rect x="1.5" y="1.5" width="77" height="77" rx="13.5" stroke="#1A5E9B" strokeOpacity="0.13" strokeWidth="3"/>
                <g clipPath="url(#clip0_269_1459)">
                  <path d="M52.7721 41.9385V49.4926C52.7721 55.8626 46.8309 61.0281 39.5013 61.0281C32.1717 61.0281 26.2305 55.8626 26.2305 49.4926V41.9385C26.2305 48.3085 32.1717 52.8614 39.5013 52.8614C46.8309 52.8614 52.7721 48.3085 52.7721 41.9385Z" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M52.7721 31.7298C52.7721 33.5877 52.2617 35.3027 51.3634 36.7727C49.1788 40.366 44.6871 42.6527 39.5013 42.6527C34.3155 42.6527 29.8238 40.366 27.6392 36.7727C26.7409 35.3027 26.2305 33.5877 26.2305 31.7298C26.2305 28.5448 27.7209 25.666 30.1096 23.5835C32.5188 21.4806 35.8263 20.1943 39.5013 20.1943C43.1763 20.1943 46.4838 21.4806 48.893 23.5631C51.2817 25.666 52.7721 28.5448 52.7721 31.7298Z" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  <path d="M52.7721 31.7298V41.9381C52.7721 48.3081 46.8309 52.861 39.5013 52.861C32.1717 52.861 26.2305 48.3081 26.2305 41.9381V31.7298C26.2305 25.3598 32.1717 20.1943 39.5013 20.1943C43.1763 20.1943 46.4838 21.4806 48.893 23.5631C51.2817 25.666 52.7721 28.5448 52.7721 31.7298Z" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                <defs>
                  <clipPath id="clip0_269_1459">
                    <rect width="49" height="49" fill="white" transform="translate(15 20)"/>
                  </clipPath>
                </defs>
              </svg>
              <div className='title'>{project.projTitle}</div>
            </div>
            <div className='projectInfo'>
              <div className='top'>
                <div className='sec1'>
                  <span className='image'>
                    <svg width="25" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect width="40" height="40" rx="7" fill="#193B67" fillOpacity="0.05"/>
                      <rect x="12.1758" y="30.1084" width="17.9286" height="7.44118" rx="3.72059" transform="rotate(-90 12.1758 30.1084)" stroke="#1A5E9B" strokeWidth="1.5"/>
                      <rect x="19.8398" y="30.1084" width="17.9286" height="7.44118" rx="3.72059" transform="rotate(-90 19.8398 30.1084)" stroke="#1A5E9B" strokeWidth="1.5"/>
                    </svg>
                  </span>
                  <span className='projNum'>
                    {project.projNum}
                  </span>
                </div>
                <div className='sec2'>
                  <div className='sec2p1'>
                    <span>
                      Submission Date:
                    </span>
                    <span>
                      {new Date(project.submissionDate).toISOString().split('T')[0]}
                    </span>
                  </div>
                  <div className='sec2p2'>
                    <span>
                      Creation Date:
                    </span>
                    <span>
                      {project.createdAt.split('T')[0]}
                    </span>
                  </div>
                </div>
              </div>
              <div className='bottom'>
                <div className='sec1'>
                  <div className='headings'>
                    <div>
                      Project Type:
                    </div>
                    <div>
                      Company:
                    </div>
                    <div>
                      Created By:
                    </div>
                    <div>
                      Number of Elements:
                    </div>
                  </div>
                  <div className='values'>
                    <div>
                      { services.map((service, idx) => (
                        service.code === project.projType &&
                        <span key={idx}> {service.name}</span>
                      ))
                      }
                    </div>
                    <div>
                      {capitalizeFirstLetter(project.company)}
                    </div>
                    <div>
                      {capitalizeFirstLetter(project.analystName)}
                    </div>
                    <div className='featureQuantity'>
                      {project.featureQuantity}
                    </div>
                  </div>
                </div>
                <div className='actionButtons'>
                  { (user.userType === 'analyst' || user.userType === 'admin' ||user.userType === 'manager') &&
                  <>
                    <div className={`projectStatus ${project.status}`}>
                      <span className={`statusContainer ${project.status}`}>
                        <span className={`statusColor ${project.status}`}></span>
                        { project.status === 'inProgress' &&
                        <span className={`statusText ${project.status}`}>Project is in Progress</span>
                        }
                        { project.status === 'inReview' &&
                        <span className={`statusText ${project.status}`}>Project is Under Review</span>
                        }
                        { project.status === 'completed' &&
                        <span className={`statusText ${project.status}`}>Project is Complete</span>
                        }
                      </span>
                    </div>
                    <div className='searchStrategyNotification'>
                      { ((user.userType === 'admin'||user.userType === 'manager') && project.status !== 'completed') &&
                      <span>Ensure "Search Strategy" is complete before generating Report.</span>
                      }
                      { (user.userType === 'analyst' && project.status === 'inProgress') &&
                      <span>Ensure "Search Strategy" is complete before submitting for review.</span>
                      }
                    </div>
                    <div className='buttonContainer'>
                      {(((user.userType === 'admin'||user.userType === 'manager') && project.status !== 'completed') || 
                      (user.userType === 'analyst' && project.status === 'inProgress')) &&
                      <div className='orbitSearchHistory'>
                        <Link to={`/orbitSearchHistory/${project.projNum}`}>
                          <button className=''>Search Strategy</button>
                        </Link>
                      </div>
                      }
                      <ProjectStatusActions user={user} project={project} updateProject={updateProject} updateProjects={updateProjects} />
                    </div>
                  </>
                  }
                </div>
              </div>
            </div>
            <div className='mainContainer'>
              <div className='toggleProjectElements' onClick={toggleProjectElements}>
                <span className='featuresHeading toggleElements'>Inventive Elements&nbsp;&nbsp;</span>
                <span className='toggleElements'>
                  { !showProjectElements ?
                  <svg width="30"viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="45" height="45" rx="10" transform="matrix(0 -1 -1 0 45 45)" fill="white"/>
                    <path d="M16 20L23 27L30 20" stroke="#262626" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  :
                  <svg width="30" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="45" height="45" rx="10" transform="matrix(0 -1 -1 0 45 45)" fill="white"/>
                    <path d="M20 16L27 23L20 30" stroke="#262626" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  }
                </span>
              </div>
              <PriorArtsReference documents={documents} project={project} elements={elements} updateProject={updateProject} />
              { (documents.length>0 && project.projType==='IPGAPS') &&
              <div className='analyzeProject'>
                <Link to={`/project/${project.projNum}/projectAnalysis`} target='_blank'>
                  <button>
                    <svg width="35" viewBox="0 0 87 87" fill="none" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                      <mask id="mask0_269_1754" style={{"maskType":"alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="87" height="87">
                        <rect width="87" height="87" fill="url(#pattern0)"/>
                      </mask>
                      <g mask="url(#mask0_269_1754)">
                        <rect width="82.4688" height="84.2812" fill="white"/>
                      </g>
                      <defs>
                        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                        <use href="#image0_269_1754" transform="scale(0.0104167)"/>
                        </pattern>
                        <image id="image0_269_1754" width="96" height="96" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAAEAklEQVR4nO1dwWoUQRB9oklMJHgMMV4CGk85iYooZM+JRwNp/QA1JgFzc2Q/IP6B/oH+gbkbNxcVFL0KXkTQFbODCmKkoRdyyGane7q7qqfrQcGyO/RUv1dd3TOz1QMIBAKBQCAQCNLAGIANAB0APWP687r5TRAQMwDeAtgfYG/MMYIAGBtC/kERZCQEwEYF8vu2FsKB3LFrIcAramebiD0LAfSxWWACwByAFoAlAMsAVMNt2fS1ZfquOYiOcQCXAKwwIEQRm+bgGoBTscg/m0mkK0u7GWMJfEGiHsNGg05LwSJfUg4qpSTvI0FPNJJ2YJWO9DzpDVcY5FiVmF32Gf2SemAtwIqvJeocg2hSidp5HwK0GHREJWoLPgS4waAjKlHTV8y1IasfOAuguasN6ihSiZsIABGAPAqVjAB6IpSkIL62af5Z0TXWMd/JHIDw5LcBlIc8Vet5EkEmYdiT3zc9EkQAhIn8wkT5Uc+Wf+QqwGbAnFwl8vvWzVGA9gBySvNbLPKzTEHtIeTUFcGG/DK3SbiokJPriFC1fZ+jLRkBCgtyXAiiIj8JAQpL8m2JoiSfvQCFI/lVCaMmn7UARU3yhxHHgXy2Avgif38AgVzIZymA7VKwtCTStv2Q5LMTwIWcdmDBVC4C1EkLhWV9ABfy2QjgIycXnkSIST4LATY9poW2RVscyGchQMczOW1HESjIZyFANwA5hWU6oiI/CQHKGjfX9piTzz4FlYFvL1OTz2YS7hE8YCkZkM9CgEGPGB947GTo9pMXIGerDeoOqMRNBIAIQB6FKuURIAUaqFWuWhtSogTaEiUp0gNtkZ6UqYK2TFUKtUFbqA1Tdk+9mlCJmd5PyRvGzYxO3SmV62YdGtOyZwTItqs5OCHLxh2g2bCpD62upCMcmnai7dqrd6a9KKMB/ai/GiLnV8GEWevmuG3lguk7ybaVPrDk+NcS/Sx4EB45trmIzHAcwHsHoj4PibiTAD45tPsRwAgywn3HSL1Voe3bjm2vIhNMAvjiQNAugGMV2tfHvHRo/yuA08gAjx0j9LrFOfSq5J/DObbQcMwC+O1AzDOHcz13OM8fAOfQYMQkZRbAr0hiJwGKtLAVId0lAaqJcTLwhJ8MXJeG9zycezXgkjcJuF4cfQBwwtNF37sAF33JYI3B7YFFRx/0BWNWbz/aN7YdwI8XDn404m1MtgXZfwHMB/Bj3rRt48tPZCjA04C+PMlRgI5lh6cC+jJlzlHVnx00AOue7vX7wsPc7pCOVnwJ52tzbAx/OhVfCtqYZwRnTIeOIn86oj8zQ1Znsf2JglGzrtZLu29mct4xw3yEyJ875hbJd1Nbpj/fjTQSBQKBQCAQCAQCAfLEf6oDUl0nT9DPAAAAAElFTkSuQmCC"/>
                      </defs>
                    </svg>
                    <span>
                    &nbsp;&nbsp;&nbsp;&nbsp;Analyze Project
                    </span>
                  </button>
                </Link>
              </div>
              }
              <div className='projectComments'>
                <ProjectComments project={project} user={user} updateProject={updateProject} />
              </div>
              <div className='references'>
                <div className='referencesHeading'>
                  <span>
                    <svg width="22" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.2727 0H4.72727C3.47394 0.00132347 2.27234 0.48338 1.3861 1.3404C0.499859 2.19742 0.00136859 3.35942 0 4.57143V27.4286C0.00136859 28.6406 0.499859 29.8026 1.3861 30.6596C2.27234 31.5166 3.47394 31.9987 4.72727 32H21.2727C22.5261 31.9987 23.7277 31.5166 24.6139 30.6596C25.5001 29.8026 25.9986 28.6406 26 27.4286V4.57143C25.9986 3.35942 25.5001 2.19742 24.6139 1.3404C23.7277 0.48338 22.5261 0.00132347 21.2727 0ZM13 19.4286H7.09091C6.77747 19.4286 6.47687 19.3082 6.25524 19.0938C6.0336 18.8795 5.90909 18.5888 5.90909 18.2857C5.90909 17.9826 6.0336 17.6919 6.25524 17.4776C6.47687 17.2633 6.77747 17.1429 7.09091 17.1429H13C13.3134 17.1429 13.614 17.2633 13.8357 17.4776C14.0573 17.6919 14.1818 17.9826 14.1818 18.2857C14.1818 18.5888 14.0573 18.8795 13.8357 19.0938C13.614 19.3082 13.3134 19.4286 13 19.4286ZM18.9091 13.7143H7.09091C6.77747 13.7143 6.47687 13.5939 6.25524 13.3796C6.0336 13.1652 5.90909 12.8745 5.90909 12.5714C5.90909 12.2683 6.0336 11.9776 6.25524 11.7633C6.47687 11.549 6.77747 11.4286 7.09091 11.4286H18.9091C19.2225 11.4286 19.5231 11.549 19.7448 11.7633C19.9664 11.9776 20.0909 12.2683 20.0909 12.5714C20.0909 12.8745 19.9664 13.1652 19.7448 13.3796C19.5231 13.5939 19.2225 13.7143 18.9091 13.7143ZM18.9091 8H7.09091C6.77747 8 6.47687 7.87959 6.25524 7.66527C6.0336 7.45094 5.90909 7.16025 5.90909 6.85714C5.90909 6.55404 6.0336 6.26335 6.25524 6.04902C6.47687 5.83469 6.77747 5.71429 7.09091 5.71429H18.9091C19.2225 5.71429 19.5231 5.83469 19.7448 6.04902C19.9664 6.26335 20.0909 6.55404 20.0909 6.85714C20.0909 7.16025 19.9664 7.45094 19.7448 7.66527C19.5231 7.87959 19.2225 8 18.9091 8Z" fill="#5F6B7A"/>
                    </svg>
                  </span>
                  <span className='referenceHeadingText'>
                  References
                  </span>
                  <span className='referenceCount'>
                    { documents.length > 0 ?
                    documents.length
                    :
                    0
                    }
                  </span>
                </div>
                { documents.length > 0 ? 
                  documents.map((documentFeature, idx) => (
                    <DocumentFeature user={user} project={project} documentFeature={documentFeature} referenceNotes={referenceNotes} key={idx} num={idx+1} removeTextAnnotation={removeTextAnnotation} removeImageAnnotation={removeImageAnnotation} removeReferenceDocuments={removeReferenceDocuments} updateProject={updateProject} />
                  ))
                :
                <div className='text-center p-3'>No Annotations</div>
                }
              </div>
            </div>
            
          </div>
          { showProjectElements &&
          <div className='projectElements'>
            <div onClick={toggleProjectElements}>
              <svg className='closeButton' width="20" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="14.2656" cy="13.5" r="13" fill="#EDEFF2" stroke="#EEEEEE"/>
                <path d="M18.2846 9.95898L10.7246 17.519M10.7246 9.95898L18.2846 17.519" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
            <ProjectFeatures projectFeatures={project.features} />
          </div>
          }
        </div>
      </>
      }
    </div>
  )
}
