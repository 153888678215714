import './HomeProjectsItem.css'

export default function HomeProjectsItem({project, updateCurrentProject}) {
    const updateProject = async () => {
        try {
          await updateCurrentProject(project.projNum)
        } catch (err) {
            console.log(err)
        }
    }

  return (
    <div className='HomeProjectsItem' onClick={updateProject}>
        <div className='section1'>
            <div className='section1a'>
                <span>
                    <svg width="25" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="35" height="35" rx="7" fill="#E5E8ED"/>
                        <rect x="10.75" y="26.25" width="15.5" height="6.32353" rx="3.16176" transform="rotate(-90 10.75 26.25)" stroke="#1A5E9B" strokeWidth="1.5"/>
                        <rect x="17.457" y="26.25" width="15.5" height="6.32353" rx="3.16176" transform="rotate(-90 17.457 26.25)" stroke="#1A5E9B" strokeWidth="1.5"/>
                    </svg>
                    &nbsp;&nbsp;
                </span>
                <span className='projectNumber'>
                    {project.projNum}
                </span>
            </div>
            <div className='section1b'>
                {project.featureQuantity}
            </div>
        </div>
        <div className='section2'>
            {project.projTitle.length > 70 ? 
            project.projTitle.substring(0, 70) + '...' 
            : 
            project.projTitle
            }
        </div>
        <div className='section3'>
            <div className='tertiaryText company'>
                {project.company}
            </div>
            <div className={`status ${project.status}`}>
                <div className={`statusColor ${project.status}`}></div>
                { project.status === 'inProgress' &&
                <div className={`statusText ${project.status}`}>In Progress</div>
                }
                { project.status === 'inReview' &&
                <div className={`statusText ${project.status}`}>Under Review</div>
                }
                { project.status === 'completed' &&
                <div className={`statusText ${project.status}`}>Completed</div>
                }
            </div>
        </div>
    </div>
  )
}
