import './PDFData.css'

export default function PDFData({documentData, currentProject, projectDocuments}) {
    
  return (
    <div className='PDFData'>
      {documentData.parsedPageData}
    </div>
  )
}
