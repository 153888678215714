import ProjectFeaturesItem from '../ProjectFeaturesItem/ProjectFeaturesItem'
import './ProjectFeatures.css'

export default function ProjectFeatures({projectFeatures}) {
  return (
    <div className='ProjectFeatures'>
        { projectFeatures.length > 0 ?
            projectFeatures.sort((a, b) => a.featureNum > b.featureNum ? 1 : -1).map((feature, idx) => (
            <ProjectFeaturesItem feature={feature} key={idx} num={idx+1} />
            ))
        :
        <p className='text-center'>No Elements</p>
        }
    </div>
  )
}
