import { sendRequest } from "../request";
const BASE_URL = '/api/projects';


export function createProject(projectData) {
    return sendRequest(`${BASE_URL}/newProject`, "POST", projectData, 'Invalid Project');
}

export function updateProject(projectData) {
    return sendRequest(`${BASE_URL}/${projectData.projNum}/edit`, "PUT", projectData, 'Unable to Update Project');
}

export function updateProjectStatus(data) {
    return sendRequest(`${BASE_URL}/${data.projNum}/updateStatus`, "PUT", data, 'Unable to Update Project Status');
}

export function findProject(projectNumber){
    return sendRequest(`${BASE_URL}/findProject/${projectNumber}`)
}

export function findProjects(user){
    return sendRequest(`${BASE_URL}/findProjects/${user._id}`, "POST", {user}, 'No Projects')
}

export function addProjectFeatures(projectNumber, formData) {
    return sendRequest(`${BASE_URL}/newProject/${projectNumber}/addFeaturesText`, "POST", formData, 'Invalid Project');
}

export function getProjects(user_id) {
    return sendRequest(`${BASE_URL}/allProjects/${user_id}`)
}

export function deleteProject(data) {
    return sendRequest(`${BASE_URL}/deleteProject`,"DELETE", data,'Unable to delete project')
}

export function getProject(projectNumber) {
    return sendRequest(`${BASE_URL}/${projectNumber}`)
}

export function createTextAnnotation(currentProject, annotationData) {
    return sendRequest(`${BASE_URL}/${currentProject._id}/createTextAnnotation`, "POST", annotationData, 'Invalid Annotation');
}

export function createManualTextAnnotations(currentProject, data) {
    return sendRequest(`${BASE_URL}/${currentProject._id}/createManualTextAnnotations`, "POST", data, 'Invalid Annotations');
}

export function createImageAnnotation(currentProject, annotationData) {
    return sendRequest(`${BASE_URL}/${currentProject._id}/createImageAnnotation`, "POST", annotationData, 'Invalid Annotation');
}

export function getProjectDocuments(projectNumber) {
    return sendRequest(`${BASE_URL}/projectDocuments/${projectNumber}`)
}

export function deleteTextAnnotation(projectNum, data) {
    return sendRequest(`${BASE_URL}/${projectNum}/deleteTextAnnotation/${data.feature.id}`,"PUT", data,'Unable to delete annotation')
}

export function deleteImageAnnotation(projectNum, data) {
    return sendRequest(`${BASE_URL}/${projectNum}/deleteImageAnnotation/${data.feature.id}`,"PUT", data,'Unable to delete annotation')
}

export function deleteReferenceElements(projectNum, data) {
    return sendRequest(`${BASE_URL}/${projectNum}/deleteReferenceElements`,"PUT",data,'Unable to delete annotation')
}

// export function generateReport(data) {
//     return sendRequest(`${BASE_URL}/${data.project.projNum}/generateReport`,"POST",data,'Unable to generate report')
// }

export function updateOrbitSearchHistory(projectNum, data) {
    return sendRequest(`${BASE_URL}/${projectNum}/updateOrbitSearchHistory`,"POST",data,'Unable to update search history')
}

export function createEmailNotifications(data) {
    return sendRequest(`${BASE_URL}/projectEmailNotifications`,"POST",data,'Unable to send email notifications')
}

export function createProjectComment(data) {
    return sendRequest(`${BASE_URL}/${data.projectId}/newComment`,"POST",data,'Unable to create comment')
}

export function rankProjectReferences(projectID, referenceData) {
    return sendRequest(`${BASE_URL}/${projectID}/rankReferences`,"PUT",referenceData,'Unable to update reference ranking')
}

export function rotateImageAnnotation(projectNum, data) {
    return sendRequest(`${BASE_URL}/${projectNum}/rotateImageAnnotation`,"PUT",data,'Unable to rotate image annotation')
}