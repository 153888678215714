import { Link } from 'react-router-dom'
import PriorArtsReferenceDocumentElement from '../PriorArtsReferenceDocumentElement/PriorArtsReferenceDocumentElement'
import './PriorArtsReferenceDocument.css'

export default function PriorArtsReferenceDocument({documents, document, elements, number}) {

  return (
    <>
        <td className='refNum'>
          Ref {number}
        </td>
        { document.features[0].classification === 'PD' ?
        <td className='documentNumber'>
          <Link draggable={false} className='documentNumber' to={`https://patents.google.com/patent/${document.documentNumber}/en?oq=${document.documentNumber}`} target='_blank'>
            {document.documentNumber}
          </Link>
        </td>
        :
        <td className='documentNumber'>
          <Link draggable={false} className='documentNumber' to={document.documentNumber} target='_blank'>
            {document.features[0].documentTitle}
          </Link>
        </td> 
        }
        <td className='docPubDate'>
          <span>
            {document.features[0].documentPublicationDate}
          </span>
        </td>
        {elements.map((element, idx) => (
            <PriorArtsReferenceDocumentElement element={element} key={idx} document={document} />
        ))}
    </>
  )
}
