import { useEffect, useState } from 'react'
import HomeProjectsItem from '../HomeProjectsItem/HomeProjectsItem'
import NewProject from '../NewProject/NewProject'
import './HomeProjects.css'

export default function HomeProjects({user, services, projects, updateProjects, projectsLoading, updateCurrentProject}) {
    const [partialProjectList, setPartialProjectList] = useState([])

    useEffect(() => {
        if(projects.length > 0) {
            setPartialProjectList(projects.slice(0, 12))
        }
    }, [projects])
  return (
    <div className='HomeProjects'>
        {projectsLoading ?
        <div className='loading'>
            <div className="spinner-border" role="status"></div>
        </div>
        :
        <>
        { projects.length === 0 ?
        <div className='noProjects'>
            <div className='imageContainer'>
                <img src={require('./NoProject.png')} alt="Not Found" />
                <div className='imageOverlay'>
                </div>
            </div>
            <div className='newProject'>
                <h5 className='header'>Create Your First Project</h5>
                <button className='createProject' data-bs-toggle="modal" data-bs-target={`#newProjectModal`}>
                    <svg width="18" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="38" height="38" rx="10" fill="#FFFFFF" opacity='0.2'/>
                        <path d="M19 11V27V11ZM27 19H11H27Z" fill="#2173F0"/>
                        <path d="M19 11V27M27 19H11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span>
                    &nbsp;&nbsp;Create Project
                    </span>
                </button>
                <NewProject user={user} services={services} updateProjects={updateProjects} />
            </div>
        </div>
        :
        <div className='projects'>
            <div className='title'>
                <h5 className='header'>Select Project</h5>
                <small className='tertiaryText'>Please select a recent project from the list below</small>
            </div>
            <div className='projectList'>
            {partialProjectList.map((project, idx) => (
                <HomeProjectsItem project={project} key={idx} updateCurrentProject={updateCurrentProject} />
            ))} 
            </div>
        </div>
        }
        </>
        }
        
    </div>
  )
}
