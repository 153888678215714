import './CreateUserForm.css'

import { useState } from 'react';

import { signUp } from '../../utilities/services/users'
import { checkUsername, checkEmail } from '../../utilities/api/users';
import { formNotificationStyle1 } from '../../utilities/services/formNotification';

const defaultState = {
  firstName: '',
  lastName:'',
  userName:'',
  userType: '',
  company: 'Riahi Patents',
  email: '',
  password: '',
  confirm: '',
}

export default function CreateUserForm({user}) {

  const [isAvailableUserName, setIsAvailableUserName] = useState(false)
  const [isValidUserName, setIsValidUserName] = useState(false)
  const [isAvailableEmail, setIsAvailableEmail] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [formData, setFormData] = useState(defaultState)
  const userNameReg = /^[a-zA-Z0-9._\-?]{5,}$/
  const emailReg = /^[a-zA-Z0-9._\-?]+@[a-zA-Z0-9._\-?]+\.[a-zA-Z0-9._\-?]{2,}$/
  if (user.userType !== 'admin') {
    return (
      <div className="CreateUserForm">
        <h1 className='text-center'>You are not authorized to view this form!</h1>
      </div>
    )
  }

  const checkUserName = async (evt) => {
    const userNameInput = evt.target.value
    // console.log(userName)
    if (userNameReg.test(userNameInput)) {
      setIsValidUserName(true)
      try {
        const userInput = await checkUsername(userNameInput)
        const userInputToLowerCase = await checkUsername(userNameInput.toLowerCase())
        // console.log(userInput)
        if (userInput || userInputToLowerCase) {
          setIsAvailableUserName(false)
        } else {
          setIsAvailableUserName(true)
        }
      } catch (err) {

      }
    } else {
      setIsValidUserName(false)
    }
  }

  const checkEmailInput = async (evt) => {
    const emailInput = evt.target.value
    // console.log(email)
    if (emailReg.test(emailInput)) {
      setIsValidEmail(true)
      try {
        const emailAddressInput = await checkEmail(emailInput)
        const emailAddressInputToLowerCase = await checkEmail(emailInput.toLowerCase())
        if (emailAddressInput || emailAddressInputToLowerCase) {
          setIsAvailableEmail(false)
        } else {
          setIsAvailableEmail(true)
        }
      } catch (err) {
        
      }
    } else {
      setIsValidEmail(false)
    }
  }

  const { firstName, lastName, userName, company, userType, email, password, confirm } = formData;

  const handleSubmit = async (evt) =>{
      evt.preventDefault();

      if (!isAvailableUserName || !isAvailableEmail || !isValidUserName || !isValidEmail) {
        if (!isAvailableUserName) {
          formNotificationStyle1(`userCreationNotification`, `'${userName}' is already taken!`, '#E21D12', 'white', '0.9')
        } else if (!isAvailableEmail) {
          formNotificationStyle1(`userCreationNotification`, `'${email}' is already taken!`, '#E21D12', 'white', '0.9')
        } else if (!isValidUserName) {
          formNotificationStyle1(`userCreationNotification`, `'${userName}' is not a valid username!`, '#E21D12', 'white', '0.9')
        } else if (!isValidEmail) {
          formNotificationStyle1(`userCreationNotification`, `'${email}' is not a valid email!`, '#E21D12', 'white', '0.9')
        }
      } else {
        try{
          const { firstName, lastName, userName, userType, password, email } = formData;
          const data = {firstName:firstName.toLowerCase(), lastName:lastName.toLowerCase(), userName:userName.toLowerCase(), company:company, userType:userType, password, email:email }
          // console.log(data)
          await signUp(data)
          formNotificationStyle1(`userCreationNotification`, 'Successfully Created User!', 'white', 'green', '0.9')
          setFormData(defaultState)
          window.location.reload()
        }catch (err) {
            setFormData({
                ...formData,
            })
        }
      }
  }

  function handleChange(evt) {
      // Replace with new object and use a computed property
      // to update the correct property
      const newFormData = {
          ...formData, // use the existing formData
          [evt.target.name]: evt.target.value, // override whatever key with the current fieldd's value
          error: '' // clear any old errors as soon as the user interacts with the form
      };
      setFormData(newFormData);
      if (evt.target.name === 'userName') {
        checkUserName(evt)
      }
      if (evt.target.name === 'email') {
        checkEmailInput(evt)
      }
  }

  const disabled = (password !== confirm) || !firstName || !lastName || !company || !userType || !password || !confirm || !email || !userName || !isValidUserName || !isValidEmail || !isAvailableUserName || !isAvailableEmail

  return (
    <div className="CreateUserForm modal-dialog">
      <div className="modal-content formContainer">
        <div className='header'>
          <div className='left'>
            <div className='first'>
            <svg width="52" height="53" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_225_6075)">
              <rect x="2" y="1.86328" width="48" height="48" rx="10" fill="white"/>
              <rect x="2.5" y="2.36328" width="47" height="47" rx="9.5" stroke="#1A3860" strokeOpacity="0.08"/>
              </g>
              <path d="M25.6667 28.2718C30.3668 28.2718 34.3333 29.0355 34.3333 31.9822C34.3333 34.93 30.3408 35.6667 25.6667 35.6667C20.9676 35.6667 17 34.9029 17 31.9562C17 29.0084 20.9925 28.2718 25.6667 28.2718ZM25.6667 14C28.8507 14 31.4018 16.5502 31.4018 19.732C31.4018 22.9138 28.8507 25.465 25.6667 25.465C22.4837 25.465 19.9315 22.9138 19.9315 19.732C19.9315 16.5502 22.4837 14 25.6667 14Z" fill="#344054"/>
            </svg>
              &nbsp;&nbsp;
            </div>
            <div className='second'>
              <div className='sec1'>
                Create New User
              </div>
              <div className='sec2 tertiaryText'>
                Let's create a user
              </div>
            </div>
          </div>
          <div className='right'>
            <svg data-bs-dismiss="modal" width="20" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16.2666" cy="16.2666" r="15.7666" fill="#EDEFF2" stroke="#EEEEEE"/>
              <path d="M21.1093 12L12 21.1093M12 12L21.1093 21.1093" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          
        </div>
        <form onSubmit={handleSubmit} id='newUserForm'>
          <div className='formContent'>
            <div className='mb-2'>
              <label htmlFor="userType">User Type</label>
              <select className='form-select me-2 text-center' title='userType' name="userType" id="userType" defaultValue={userType} onChange={handleChange} required autoComplete='off' >
                <option value=""  >---Select an Option---</option>
                <option value="admin">Admin</option>
                <option value="manager">Manager</option>
                <option value="analyst">Analyst</option>
                <option value="client">Client</option>
              </select>
            </div>
            { userType === 'client' &&
            <div className='mb-2'>
              <label htmlFor="company">Company</label>
              <input className='form-control me-2 ' title='company' type="text" name="company" id="newCompany" defaultValue={company} onChange={handleChange} required autoComplete='off'/>
            </div>
            }
            <div className='groupContainer'>
              <div className='firstName firstHalf'>
                <label htmlFor="newUserFirstName">First Name</label>
                <input className='form-control' title='firstName' type="text" name="firstName" id="newUserFirstName" placeholder='Enter First Name' defaultValue={firstName} onChange={handleChange} required autoComplete='off'/>
              </div>
              <div className='lastName secondHalf'>
                <label htmlFor="newUserLastName">Last Name</label>
                <input className='form-control' title='lastName' type="text" name="lastName" id="newUserLastName"  placeholder='Enter Last Name' defaultValue={lastName} onChange={handleChange} required autoComplete='off'/>
              </div>
            </div>
            <div className='groupContainer'>
              <div className='userName firstHalf'>
                <label htmlFor="newUserName">Username</label>
                <input className='form-control me-2 ' title='userName' type="text" name="userName" id="newUserName"  placeholder='Enter User Name' defaultValue={userName} onChange={handleChange} required autoComplete='off'/>
                <small className="checkIsValidUser" style={{backgroundColor:`${isValidUserName? ' #158444' : '#E21D12'}`, color:'white'}}>Must contain at least 5 characters</small>
                {isValidUserName &&
                    <small className="checkIsAvailableUser" style={{color:`${isAvailableUserName? ' #158444' : '#E21D12'}`}}>
                        {isAvailableUserName ? <span>{[<span key={0}>&nbsp;&nbsp;&#x2714;</span>]}</span> : <div className="checkIsAvailableUser">Username is already in use!</div>}
                    </small>
                }
                <div className=''>
                  <small className='tertiaryText'>Accepted Characters: a-z,0-9,.,-,_</small>
                </div>
              </div>
              <div className='email secondHalf'>
                <label htmlFor="newUserEmail">Email</label>
                <input className='form-control me-2 ' title='email' type="text" name="email" id="newUserEmail"  placeholder='Enter Email Address' defaultValue={email} onChange={handleChange} required autoComplete='off'/>
                <small className="form-text checkIsValidEmail" style={{backgroundColor:`${isValidEmail? ' #158444' : '#E21D12'}`, color:'white'}}>example@email.com</small>
                {isValidEmail &&
                    <small className="form-text checkIsAvailableEmail" style={{color:`${isAvailableEmail? ' #158444' : '#E21D12'}`}}>
                        {isAvailableEmail ? <span>{[<span key={0}>&nbsp;&nbsp;&#x2714;</span>]}</span> : <div className='checkIsAvailableEmail'>Email is already in use!</div>}
                    </small>
                }
              </div>
            </div>
            <div className='groupContainer'>
            <div className='password firstHalf'>
              <label htmlFor="newUserPassword">Password</label>
              <input className='form-control me-2 ' title='password' type="password" name="password" id="newUserPassword"  placeholder='Enter Password' defaultValue={password} onChange={handleChange} required autoComplete='off'/>
              <small className="tertiaryText" style={{color:(password.length<5 && password.length>0)? '#E21D12':'' }}>
                Must contain at least 5 characters
                  {password.length>=5 &&
                  <span style={{color:'green'}}>{[<span key={0}>&nbsp;&nbsp;&#x2714;</span>]}</span>
                  }
              </small>
            </div>
            <div className='confirmPassword secondHalf'>
              <label htmlFor="newUserPasswordConfirm">Confirm Password</label>
              <input className='form-control me-2 ' title='confirm' type="password" name="confirm" id="newUserPasswordConfirm"  placeholder='Confirm Password' defaultValue={confirm} onChange={handleChange} required autoComplete='off'/>
              <small className="form-text tertiaryText">
                Re-enter password
                {password.length>0 && (password === confirm) &&
                  <span style={{color:'green'}}>{[<span key={0}>&nbsp;&nbsp;&#x2714;</span>]}</span>
                }
              </small>
              <small>
                {(confirm.length>0) && (password !== confirm) && 
                <div className="errorText">Password does not match</div>
                }
              </small>
            </div>
            </div>
          </div>
        </form>
        <div className='d-flex justify-content-center formButtonContainer'>
          <button data-bs-dismiss="modal" className='cancel'>Cancel</button>
          <button data-bs-dismiss="modal" className="primary submit" type="submit" form='newUserForm' disabled={disabled}>Create User</button>
        </div>
        <p id='userCreationNotification'className='userCreationNotification text-center mb-0'></p>
      </div>
      
    </div>
  )
}
