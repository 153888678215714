import './UpdateProjectForm.css'
import { useEffect, useState } from 'react'
import UpdateProjectFormItem from '../../components/UpdateProjectFormItem/UpdateProjectFormItem'
import { updateProject } from '../../utilities/api/projects'
import { formNotificationStyle1 } from '../../utilities/services/formNotification'

export default function UpdateProjectForm({user, project, projectIdx, services, updateProjects}) {
    const defaultState = {
        projType: project.projType,
        projNum: project.projNum,
        projTitle:project.projTitle,
        submissionDate: new Date(project.submissionDate).toISOString().split('T')[0],
        company: project.company,
        featureQuantity: project.featureQuantity,
        features: project.features,
    }
    const [formData, setFormData] = useState(defaultState)
    const [projectFeatureCount, setProjectFeatureCount] = useState(1)
    const [featureNums, setFeatureNums] = useState([])
    const [featuresArray, setFeaturesArray] = useState([])
    const [disableAddFeatureButton, setDisableAddFeatureButton] = useState(false)
  
    const updateAddFeatureButton = (action) => {
      if (action === 'disable') {
        setDisableAddFeatureButton(true)
      } else if (action === 'enable') {
        setDisableAddFeatureButton(false)
      }
    }

    const fillFeaturesArray = (data) => {
        let tempFeaturesArray = []

        if (project) {
          // Populate featuresArray with existing features
          if (project.features.length>0) {
              for (let index = 0; index < project.features.length; index++) {
                  let featureObject = {
                    featureNum: project.features[index].featureNum.toString(), 
                    featureText: project.features[index].featureText, 
                    _id: project.features[index]._id, 
                    textAnnotations: project.features[index].textAnnotations,
                    imageAnnotations: project.features[index].imageAnnotations,
                  }
                  tempFeaturesArray = [...tempFeaturesArray, featureObject]
                  // console.log(featureObject)
              }
          }

          // Populate featuresArray with new features
          let count = project.features.length+1
          while (data[`feature${count}`]) {
              let featureObject = {featureNum: count.toString(), featureText: data[`feature${count}`]}
              tempFeaturesArray = [...tempFeaturesArray, featureObject]
              count++
          }
          
          setFeaturesArray(tempFeaturesArray)
        }
    }
    
    function handleChange(evt) {
        const newFormData = {
            ...formData, // use the existing formData
            [evt.target.name]: evt.target.value, // override whatever key with the current field's value
            error: '' // clear any old errors as soon as the user interacts with the form
        };
        fillFeaturesArray(newFormData)
        setFormData(newFormData)
    }

    const handleSubmit = async (evt) =>{
      evt.preventDefault();
      fillFeaturesArray(formData)
      try{
          const data = {
            user: user,
            projNum: project.projNum,
            projTitle: formData.projTitle,
            submissionDate: formData.submissionDate,
            company: formData.company,
            // featureQuantity: formData.featureQuantity,
            featureQuantity: featuresArray.length,
            features:featuresArray,
            analystName: `${user.firstName} ${user.lastName}`,
            analystId: user._id,
          }
          const projects = await updateProject(data)
          updateProjects(projects)
          formNotificationStyle1(`projectUpdateNotification`, `Successfully Updated Project`, 'white', 'green', '0.9')
      }catch (err) {
        formNotificationStyle1(`projectUpdateNotification`, `Project Update Failed - Try again!`, 'white', 'red', '0.9')
      }
    }

    const addProjectFeature = (evt) => {
        const newFeaturesArray = [...featureNums, project.features.length+projectFeatureCount]
        setFeatureNums(newFeaturesArray)
        setProjectFeatureCount(projectFeatureCount+1)
        setDisableAddFeatureButton(true)
    }

    useEffect(() => {
        setFormData(defaultState)
        if (formData) {
            fillFeaturesArray(formData)
        }
        // eslint-disable-next-line
    },[project])

  return (
    <div className='UpdateProjectForm modal-dialog'>
      <div className="modal-content formContainer">
        <div className='header'>
          <div className='left'>
            <div className='first'>
              <svg width="35" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_207_4032)">
                <rect x="2" y="1.86328" width="48" height="48" rx="10" fill="white"/>
                <rect x="2.5" y="2.36328" width="47" height="47" rx="9.5" stroke="#1A3860" strokeOpacity="0.08"/>
                </g>
                <path d="M19.808 27.9404C21.0091 27.9404 21.979 28.9106 21.979 30.1355V34.056C21.979 35.271 21.0076 36.25 19.808 36.25H15.921C14.731 36.25 13.75 35.269 13.75 34.056V30.1355C13.75 28.9125 14.7294 27.9404 15.921 27.9404H19.808ZM33.079 27.9404C34.2706 27.9404 35.25 28.9125 35.25 30.1355V34.056C35.25 35.269 34.269 36.25 33.079 36.25H29.192C27.9924 36.25 27.021 35.271 27.021 34.056V30.1355C27.021 28.9106 27.9909 27.9404 29.192 27.9404H33.079ZM19.808 14.75C21.0074 14.75 21.979 15.7288 21.979 16.9452V20.8656C21.979 22.0902 21.0095 23.0596 19.808 23.0596H15.921C14.729 23.0596 13.75 22.0883 13.75 20.8656V16.9452C13.75 15.7308 14.7311 14.75 15.921 14.75H19.808ZM33.079 14.75C34.2689 14.75 35.25 15.7308 35.25 16.9452V20.8656C35.25 22.0883 34.271 23.0596 33.079 23.0596H29.192C27.9905 23.0596 27.021 22.0902 27.021 20.8656V16.9452C27.021 15.7288 27.9926 14.75 29.192 14.75H33.079Z" fill="#344054" stroke="#344054" strokeWidth="1.5"/>
              </svg>
              &nbsp;&nbsp;
            </div>
            <div className='second'>
              <div className='sec1'>
                {`${project.projNum}`}
              </div>
              <div className='sec2 tertiaryText'>
                Update Project
              </div>
            </div>
          </div>
          <div className='right'>
            <svg data-bs-dismiss="modal" width="20" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16.2666" cy="16.2666" r="15.7666" fill="#EDEFF2" stroke="#EEEEEE"/>
              <path d="M21.1093 12L12 21.1093M12 12L21.1093 21.1093" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
        </div>
        <form onSubmit={handleSubmit} id={`updateProject${projectIdx}`}>
          <div className='formContent'>
            <div className='mb-2'>
              <label htmlFor="projType">Project Type</label>
              { services.map((service, idx) => (
                service.code === project.projType &&
                  <input className='form-control' type="text" name="projType" id="projType" defaultValue={service.name} key={idx} required disabled={true}/>
              ))
              }
            </div>
            <div className='groupContainer1'>
              <div className='projectNumber'>
                <label htmlFor="projNum">Project Number</label>
                <input className='form-control' type="text" name="projNum" id="projNum" placeholder='Enter Project Number' value={formData.projNum} onChange={handleChange} disabled/>
              </div>
              <div className='submissionDate'>
                <label htmlFor="submissionDate">Submission Date</label>
                <input className='form-control' type="date" name="submissionDate" id="submissionDate" defaultValue={formData.submissionDate} onChange={handleChange} required />
              </div>
            </div>
            <div className='groupContainer2'>
              <div className='projTitle'>
                <label htmlFor="projTitle">Title</label>
                <input className='form-control' type="text" name="projTitle" id="projTitle" defaultValue={formData.projTitle} onChange={handleChange} required/>
              </div>
              <div className='company'>
              <label htmlFor="company">Company</label>
              <input className='form-control' type="text" name="company" id="company" defaultValue={formData.company} onChange={handleChange} required/>
              </div>
            </div>
            <div className='addElementContainer'>
              { formData.features &&
                formData.features.map((feature, idx) => (
                  <div className='mb-2' key={idx}>
                      <label htmlFor={`feature${feature.featureNum}`}>Element {feature.featureNum}</label>
                      <textarea className='form-control me-2' type="text" name={`feature${feature.featureNum}`} id={`feature${feature.featureNum}`} defaultValue={feature.featureText} disabled={true}/>
                  </div>
                ))
              }
              {
                featureNums.map((feature, idx) => (
                  <UpdateProjectFormItem featureNum={feature} key={idx} handleChange={handleChange} updateAddFeatureButton={updateAddFeatureButton} />
                ))
              }
              <div className='counterAdder'>
                <div>{`No of Elements: ${featuresArray.length}`}</div>
                <button type='button' onClick={addProjectFeature} className='btn btn-sm addElementButton' disabled={disableAddFeatureButton}>
                  <svg width="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.00016 7.44157H13.8827M7.44141 13.8828V1.00032" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <span>
                    &nbsp;Add Element
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className='d-flex justify-content-center formButtonContainer'>
          <button data-bs-dismiss="modal" className='cancel'>Cancel</button>
          <button data-bs-dismiss="modal" className="primary submit" form={`updateProject${projectIdx}`} type="submit">Update Project</button>
        </div>
        <div><p id='projectUpdateNotification'className='projectUpdateNotification text-center'></p></div>
      </div>
    </div>
  )
}
