import { Link } from 'react-router-dom'
import './ProjectsItem.css'
import UpdateProject from '../UpdateProject/UpdateProject'


export default function ProjectsItem({user, projectItem, idx, deleteProjectItem, services, updateProjects}) {
  const handleProjectDeletion = (evt) => {
    deleteProjectItem(projectItem)
  }

  return (
    <tr className="ProjectsItem">
      <td className='projectNumber'><Link className='ProjectsItemLink' to={`/projects/${projectItem.projNum}`}>{`${projectItem.projNum}`}</Link></td>
      <td className='projectTitle'>
        { projectItem.projTitle.length > 40 ?
          <span className='projectTitle'>{`${projectItem.projTitle.substring(0, 40)}...`}</span>
          :
          <span className='projectTitle'>{`${projectItem.projTitle}`}</span>
        }
      </td>
      <td className='company'>{`${projectItem.company}`}</td>
      <td className='featureQuantity'><span>{`${projectItem.featureQuantity}`}</span></td>
      <td className='submissionDate'><span>{new Date(projectItem.submissionDate).toISOString().split('T')[0]}</span></td>
      <td className='creationDate'><span>{projectItem.createdAt.split('T')[0]}</span></td>
      <td className={`projectStatus ${projectItem.status}`}>
        <span className={`statusContainer ${projectItem.status}`}>
          <span className={`statusColor ${projectItem.status}`}></span>
          { projectItem.status === 'inProgress' &&
          <span className={`statusText ${projectItem.status}`}>In Progress</span>
          }
          { projectItem.status === 'inReview' &&
          <span className={`statusText ${projectItem.status}`}>Under Review</span>
          }
          { projectItem.status === 'completed' &&
          <span className={`statusText ${projectItem.status}`}>Completed</span>
          }
        </span>
      </td>
      <td className='actions'>
        {(( (user.userType === 'admin'||user.userType === 'manager') && (projectItem.status === 'inProgress' || projectItem.status === 'inReview')) ||
        ( user.userType === 'analyst' && projectItem.status === 'inProgress'))
        &&
        <div className='updateButton'>
          <button className='updateButton' title='edit' data-bs-toggle="modal" data-bs-target={`#updateProjectModal${idx}`}>
            <svg width="26" viewBox="0 0 35 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M25.3924 23.4533C25.8971 23.4533 26.3076 23.8692 26.3076 24.3805C26.3076 24.8927 25.8971 25.3076 25.3924 25.3076H20.2192C19.7144 25.3076 19.3039 24.8927 19.3039 24.3805C19.3039 23.8692 19.7144 23.4533 20.2192 23.4533H25.3924ZM21.8048 9.63334L23.1411 10.6949C23.6891 11.1234 24.0544 11.6883 24.1794 12.2824C24.3236 12.9358 24.1698 13.5776 23.7372 14.1328L15.7769 24.4269C15.4116 24.8944 14.8732 25.1573 14.2963 25.1671L11.1238 25.206C10.9507 25.206 10.8065 25.0892 10.7681 24.9236L10.047 21.7974C9.92204 21.2228 10.047 20.6287 10.4123 20.171L16.0557 12.8667C16.1518 12.7498 16.3249 12.7313 16.4402 12.818L18.8149 14.7074C18.9687 14.834 19.1802 14.9022 19.4013 14.8729C19.8724 14.8145 20.1896 14.386 20.1416 13.9282C20.1127 13.6945 19.9974 13.4997 19.8435 13.3536C19.7955 13.3147 17.5362 11.5032 17.5362 11.5032C17.392 11.3864 17.3632 11.1721 17.4785 11.027L18.3726 9.86707C19.1994 8.80552 20.6415 8.70813 21.8048 9.63334Z" fill="#1A5E9B"/>
              <rect x="1" y="1" width="33" height="33" rx="7" stroke="#1A5E9B" strokeOpacity="0.24" strokeWidth="2"/>
            </svg>
          </button>
          <UpdateProject user={user} projectIdx={idx} project={projectItem} services={services} updateProjects={updateProjects} />
        </div>
        }
        { (user.userType === 'admin'||user.userType === 'manager') &&
        <div className='projectsItemModalContainer'>
          <button className='deleteButton' title='delete' data-bs-toggle="modal" data-bs-target={`#projectDeleteConfirmation${idx}`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" fill="#E21D12" className="bi bi-trash" viewBox="0 0 16 16">
            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
          </svg>
          </button>
          <div className="modal fade" id={`projectDeleteConfirmation${idx}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`projectDeleteConfirmationHeader${idx}`} aria-hidden="true">
            <div className="modal-dialog ">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title errorText" id={`projectDeleteConfirmationHeader${idx}`}>
                    Delete {`${projectItem.projNum}`}: {`${projectItem.projTitle}`}.
                  </h5>
                  <svg className='closeDeleteModal' data-bs-dismiss="modal" width="25" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="16.2666" cy="16.2666" r="15.7666" fill="#EDEFF2" stroke="#EEEEEE"/>
                    <path d="M21.1093 12L12 21.1093M12 12L21.1093 21.1093" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </div>
                <div className="modal-body">
                  <p>Are you sure you want to delete <strong>{`${projectItem.projNum}`}</strong>'?</p>
                  <p className='errorText'>This action cannot be undone!</p>
                </div>
                <div className="modal-footer">
                  <button type="button" className="cancelButton" data-bs-dismiss="modal">Close</button>
                  <button onClick={handleProjectDeletion} type="button" className="deleteButton" data-bs-dismiss="modal">Delete</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        }
        
      </td>
    </tr>
  )
}
