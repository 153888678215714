import ImageThumbnailItem from '../ImageThumbnailItem/ImageThumbnailItem'
import './ImageThumbnails.css'

export default function ImageThumbnails({images, fullImages, currentProject, publicationNumber, renderGallery, updateCurrentImage}) {
  return (
    <div className='text-center'>
        {
            images.map ( (image, idx) =>(
                <ImageThumbnailItem image={image} key={idx} renderGallery={renderGallery} index={idx} updateCurrentImage={updateCurrentImage} fullImages={fullImages} currentProject={currentProject} publicationNumber={publicationNumber} />
            ))
        }
    </div>
  )
}
