import { useState } from 'react';
import './LoginForm.css';
import { login } from '../../utilities/services/users'
// import { useNavigate } from 'react-router-dom';

const defaultState = {
    email: '',
    password: '',
    error: ''
}

export default function LoginForm({user, updateUser}) {
    const [formData, setFormData] = useState(defaultState)
    const [failedAttempts, setFailedAttempts] = useState(0)
    const { email, password, error } = formData;

    // const navigate = useNavigate()

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const {  password, email } = formData;
            const data = {  password, email }

            const user = await login(data)
            // as soon as we get the decoded data from the creat account api call
            // (derived fromt he jwt in local storage), we can update app.js to store
            // user in state
            updateUser(user)
            //// Navigate to home page after successful login
            // navigate('/')
        } catch (err) {
            setFormData({
                ...formData,
                error: err.message
            })
            setFailedAttempts((failedAttempts) => (failedAttempts + 1))
        }
    }

    function handleChange(evt) {
      const newFormData = {
          ...formData,
          [evt.target.name]: evt.target.value,
          error: '' // clear any old errors as soon as the user interacts with the form
      };
      setFormData(newFormData);
    }

  const disabled = !email || !password

  return (
    <div className='LoginForm'>
        <div className="form-container">
            <h1 className='headingText'><strong>Log in</strong></h1>
            <p className='tertiaryText'>Welcome back! Please enter your details.</p>
            <form onSubmit={handleSubmit} className='loginForm '>
                <div className='form-group mb-3'>
                    <label htmlFor="email">User ID&nbsp;</label>
                    <input className='form-control me-2 mt-1' title='email' type="text" name="email" id="email" value={formData.email} onChange={handleChange} required autoComplete='off' placeholder='Enter User ID' />
                </div>
                <div className='form-group mb-3'>
                    <label htmlFor="password">Password&nbsp;</label>
                    <input className='form-control me-2 mt-1' title='password' type="password" name="password" id="password" value={formData.password} onChange={handleChange} required autoComplete='off' placeholder='Enter Password' />
                </div>
                <br />
                <button type="submit" disabled={disabled} className="primary">Log In</button>
            </form>
            {error && <p className="error-message errorText text-center"><strong>&nbsp;{error}</strong></p>}
            {failedAttempts >= 5 &&
                <div><p className='errorText text-center'><strong>Please contact your admin for assistance!</strong></p></div>
            }
        </div>
    </div>
  )
}
