import Mark from 'mark.js';


const handleQuestionWildCard = (word, data, count) => {
    word = word.replaceAll('?','').replaceAll('*','')
    const options = {
        "element": "mark",
        "className": `keyword${count} keywordMark`,
        "accuracy": "complementary",
        "separateWordSearch": false,
        "acrossElements": false,
        "caseSensitive": false,
        "ignoreJoiners": false,
        "ignoreGroups": 0,
        "ignorePunctuation": ":;.,-–—‒_(){}[]!'\"+=".split(""),
        "done": () => {},
    }
    if (word.includes('-')) {
        runMarkJS(word, data, count, options)
        const modifiedWord1 = word.replaceAll('-','')
        runMarkJS(modifiedWord1, data, count, options)
        const modifiedWord2 = word.replaceAll('-',' ')
        runMarkJS(modifiedWord2, data, count, options)
    } else if (word.includes(' ')) {
        runMarkJS(word, data, count, options)
        const modifiedWord1 = word.replaceAll(' ','')
        runMarkJS(modifiedWord1, data, count, options)
        const modifiedWord2 = word.replaceAll(' ','-')
        runMarkJS(modifiedWord2, data, count, options)
    } else {
        runMarkJS(word, data, count, options)
    }
}

const handleStarWildCard = (word, data, count) => {
    const options = {
        "element": "mark",
        "className": `keyword${count} keywordMark`,
        "accuracy": "complementary",
        "acrossElements": false,
        "caseSensitive": false,
        "ignoreJoiners": false,
        "ignoreGroups": 0,
        "ignorePunctuation": ":;.,-–—‒_(){}[]!'\"+=".split(""),
        "done": () => {},
    }
    word = word.replaceAll('*','').replaceAll('?','').replaceAll(' ','')
    runMarkJS(word, data, count, options)
}

const wrapWord = (word, data, wordCount) => {
    // Loop through comma seperated words
    if (word && word.includes(',')) {
        const words = word.replaceAll(/(\s*,\s*)/g, ',').replaceAll(/,\s*$/g, '').split(',')
        words.forEach( (wordsItem) => {
            if (wordsItem.includes('?')) {
                handleQuestionWildCard(wordsItem, data, wordCount)
            } else if (wordsItem.includes('*')) {
                handleStarWildCard(wordsItem, data, wordCount)
            } else {
                wordsItem = wordsItem.replaceAll(' ', '')
                runMarkJS(wordsItem, data, wordCount)
            }
        })
    // Handle single word
    } else {
        if (word.includes('?')) {
            handleQuestionWildCard(word, data, wordCount)
        } else if (word.includes('*')) {
            handleStarWildCard(word, data, wordCount)
        } else {
            word = word.replaceAll(' ', '')
            word = word.replaceAll(/(\s*,\s*)/g, ',').replaceAll(/,\s*$/g, '').split(',')
            runMarkJS(word, data, wordCount)
        }
    }
}

// Use Mark.js to highlight key words
const runMarkJS = (word, string, count, options) => {
    const instance = new Mark(string);
    options = options || {
        "element": "mark",
        "className": `keyword${count} keywordMark`,
        "exclude": [],
        "iframes": false,
        "iframesTimeout": 5000,
        "separateWordSearch": true,
        "accuracy": "partially",
        "diacritics": true,
        "synonyms": {},
        "acrossElements": false,
        "caseSensitive": false,
        "ignoreJoiners": false,
        "ignoreGroups": 0,
        "wildcards": "disabled",
        "ignorePunctuation": ":;.,-–—‒_(){}[]!'\"+=".split(""),
        "each": () => {},
        "noMatch": () => {},
        "filter": () => true,
        "done": () => {},
        "debug": false,
        "log": window.console
    };
    instance.mark(word, options);
}

// New way to handle key word highlighting
export const highlightKeyWords = async (keyWords) => {
    if (document.querySelector('.mainDocument')) {
        const mainDocument = document.querySelector('.mainDocument')
        const instance = new Mark(mainDocument);
        instance.unmark({className: 'keywordMark'});
        const marks = mainDocument.querySelectorAll('.keywordMark')
        marks.forEach(mark => {
            mark.outerHTML = mark.innerHTML
        })
        let keyWordCount = 1
        const keyWordMax = 8
        while (keyWordCount<=keyWordMax) {
            if (keyWords[`keyword${keyWordCount}`]) {
                wrapWord(keyWords[`keyword${keyWordCount}`], mainDocument, keyWordCount)
            }
            keyWordCount++
        }
    }
}

