import { useEffect, useState } from 'react'
import './ManualTextAnnotationForm.css'
import { createManualTextAnnotations } from '../../utilities/api/projects'
import { formNotificationStyle2 } from '../../utilities/services/formNotification'

export default function ManualTextAnnotationForm({user, documentData, currentProject, updateCurrentProject, projectDocuments }) {
    const [currentDocument, setCurrentDocument] = useState(null)
    const [projectElements, setProjectElements] = useState([])
    const [elementAnnotations, setElementAnnotations] = useState([{}])
    const [presentElements, setPresentElements] = useState([])
    const [defaultState] = useState({
        classification: '',
        publicationDate: '',
        documentTitle: '',
        assignees: '',
        annotationText: '',
        projectElement: '',
    })


    const [formData, setFormData] = useState(defaultState)
    
    // Combine existing annotations with new annotations
    const getAnnotationFormData = () => {
        let annotationFormData = []
        if (projectElements.length>0) {
            projectElements.forEach((element, idx) => {
                if (formData[`element${idx+1}annotationText`]) {
                    // New/Updated annotations
                    annotationFormData = [
                        ...annotationFormData,
                        {
                            featureNum: element.featureNum,
                            annotationText: formData[`element${idx+1}annotationText`],
                        }
                    ]
                } else if (elementAnnotations.length>0) {
                    // Existing annotations
                    elementAnnotations.forEach((annotation) => {
                        if (annotation.featureNum === element.featureNum) {
                            annotationFormData = [
                                ...annotationFormData,
                                {
                                    featureNum: element.featureNum,
                                    annotationText: annotation.annotationText,
                                }
                            ]
                        }
                    })
                }
            })
        }
        return annotationFormData
    }

    // console.log(documentData)
    // console.log(projectDocuments)
    // console.log(currentProject)
    // console.log(currentDocument)
    // console.log(projectElements)
    // console.log(elementAnnotations)

    const handleChange = (evt) => {
        const newFormData = {
            ...formData,
            [evt.target.name]: evt.target.value,
        };
        setFormData(newFormData);
        // console.log(newFormData)
    }
    
    const handleSubmit = async (evt) => {
        evt.preventDefault()
        const annotationSubmissionButton = document.querySelector('.manualAnnotationSubmissionButton')
        annotationSubmissionButton.disabled = true
        // console.log(formData)
        try {
            let data = {
                classification: currentDocument.classification? `${currentDocument.classification}` : `${formData.classification}`
            }
            if (documentData.classification==='PD') {
                data = {
                    ...data,
                    user: user._id,
                    projectNumber: currentProject.projNum,
                    rank: currentDocument.rank,
                    paragraphNumber: '0000',
                    claimNumber: '',
                    abstract: `false`,
                    document: documentData.documentNumber,
                    assignees: currentDocument.assignees? `${currentDocument.assignees}` : `${formData.assignees}`,
                    publicationDate: currentDocument.documentPublicationDate? `${currentDocument.documentPublicationDate}` : `${formData.publicationDate}`,
                    title: currentDocument.documentPublicationDate? `${currentDocument.documentTitle}` : `${formData.documentTitle}`,
                    annotations: getAnnotationFormData(),
                }
            } else {
                data = {
                    ...data,
                    user: user._id,
                    projectNumber: currentProject.projNum,
                    rank: currentDocument.rank,
                    paragraphNumber: '',
                    claimNumber: '',
                    abstract: ``,
                    document: documentData.url,
                    publicationDate: currentDocument.documentPublicationDate? `${currentDocument.documentPublicationDate}` : `${formData.publicationDate}`,
                    title: currentDocument.documentPublicationDate? `${currentDocument.documentTitle}` : `${formData.documentTitle}`,
                    annotations: getAnnotationFormData(),
                }
            }
            // console.log(data)
            await createManualTextAnnotations(currentProject, data)
            await updateCurrentProject(currentProject.projNum)
            formNotificationStyle2(`annotationNotification-Feature`, 'Successfully Saved', 'green', '0.7')
            setFormData(defaultState)
        } catch (error) {
            formNotificationStyle2(`annotationNotification-Feature`, 'Error- Unable to add annotation', 'red', '1')
            setFormData({
                ...formData,
            })
        }
        annotationSubmissionButton.disabled = false
    }

    useEffect(() => {
        const getDocumentInfo = async () => {
          let data = ''
          if (currentProject && projectDocuments && (documentData.url || documentData.documentNumber)) {
            projectDocuments.forEach((doc) => {
              if (doc.documentNumber === (documentData.url || documentData.documentNumber)) {
                data = {
                    classification: doc.features[0].classification,
                    documentNumber: doc.documentNumber,
                    documentTitle: doc.features[0].documentTitle,
                    documentPublicationDate: doc.features[0].documentPublicationDate,
                    assignees: doc.features[0].assignees,
                    rank: doc.features[0].rank||0,
                }
              }
            })
          }
          setCurrentDocument(data)
        }

        const getProjectElements = async () => {
            let elements = []
            if (currentProject && currentProject.features) {
                currentProject.features.forEach((feature) => {
                        elements.push(feature)
                })
            }
            setProjectElements(elements)
        }
        getDocumentInfo()
        getProjectElements()
    }, [currentProject, documentData, projectDocuments])

    useEffect(() => {
        const getElementAnnotations = async () => {
            let annotations = []
            let presentElements = []
            if (projectElements.length>0) {
                projectElements.forEach((element, idx) => {
                    if (element.textAnnotations.length>0) {
                        let elementAnnotationCount = 0
                        element.textAnnotations.forEach((annotation) => {
                            // add annotation count to limit to one annotation per element
                            if ((annotation.document === (documentData.url || documentData.documentNumber)) && elementAnnotationCount<1) {
                                annotations.push({
                                    featureNum: parseInt(annotation.feature),
                                    annotationText: annotation.text,
                                    _id: annotation._id,
                                })
                                presentElements.push(parseInt(annotation.feature))
                                elementAnnotationCount++
                            }
                        })
                    }
                })
            }
            setElementAnnotations(annotations)
            setPresentElements(presentElements)
        }
        getElementAnnotations()
    }, [projectElements, documentData])

    return (
    <div className='ManualTextAnnotation'>
        <div className='mainHeader'>
            <div className=''>
                <svg width="32" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" width="28.1816" height="28.1816" rx="7" fill="white" fillOpacity="0.38"/>
                    <rect x="3.11328" y="2.91602" width="23.0446" height="22.351" rx="5" fill="#409963"/>
                    <path d="M8.13281 8.21973V21.7234" stroke="white" strokeOpacity="0.41" strokeWidth="1.5"/>
                    <path d="M12.4769 20H10.3675L14.2081 9.09091H16.6477L20.4936 20H18.3842L15.4705 11.3281H15.3853L12.4769 20ZM12.5462 15.7227H18.299V17.31H12.5462V15.7227Z" fill="white"/>
                </svg>
                &nbsp;&nbsp;
            </div>
            <div className='sectionText'>
                <div className='first'>OverrideDocument</div>
                <div className='second'>Manually Add Text Annotations for Document <small className='errorText'>(This overrides other annotations!)</small></div>
            </div>
        </div>
        { (currentDocument) &&
        <div className='aboutDocument'>
            <div className='section1'>
                <div className='section1a'>
                    <span>
                        <svg width="25" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="42" height="42" rx="10" fill="#1A5E9B"/>
                            <path d="M25.191 11C28.28 11 30 12.78 30 15.83V26.16C30 29.26 28.28 31 25.191 31H16.81C13.77 31 12 29.26 12 26.16V15.83C12 12.78 13.77 11 16.81 11H25.191ZM17.08 24.74C16.78 24.71 16.49 24.85 16.33 25.11C16.17 25.36 16.17 25.69 16.33 25.95C16.49 26.2 16.78 26.35 17.08 26.31H24.92C25.319 26.27 25.62 25.929 25.62 25.53C25.62 25.12 25.319 24.78 24.92 24.74H17.08ZM24.92 20.179H17.08C16.649 20.179 16.3 20.53 16.3 20.96C16.3 21.39 16.649 21.74 17.08 21.74H24.92C25.35 21.74 25.7 21.39 25.7 20.96C25.7 20.53 25.35 20.179 24.92 20.179ZM20.069 15.65H17.08V15.66C16.649 15.66 16.3 16.01 16.3 16.44C16.3 16.87 16.649 17.22 17.08 17.22H20.069C20.5 17.22 20.85 16.87 20.85 16.429C20.85 16 20.5 15.65 20.069 15.65Z" fill="white"/>
                        </svg>
                        &nbsp;&nbsp;
                    </span>
                    <span className='documentNumber'>
                    { currentDocument.documentNumber.length>40?
                        currentDocument.documentNumber.substring(0, 40)+'...'
                        :
                        currentDocument.documentNumber
                    }
                    </span>
                </div>
                <div className='section1b'>
                    <span className='tertiaryText'>
                        Publication Date:&nbsp;&nbsp;
                    </span>
                    <span className='date'>
                        {currentDocument.documentPublicationDate}
                    </span>                    
                </div>
            </div>
            <div className='section2'>
                <span className='section2a'>
                { currentDocument.documentTitle.length>80?
                    currentDocument.documentTitle.substring(0, 80)+'...'
                    :
                    currentDocument.documentTitle
                }
                </span>
            </div>
            <div className='section3'>
                <span className='section3a tertiaryText'>
                    Classification:&nbsp;&nbsp;
                </span>
                <span className='section3b'>
                    {currentDocument.classification || null}
                </span>
            </div>
            <div className='section4'>
                <span className='tertiaryText'>
                    Assignee(s):&nbsp;&nbsp;
                </span>
                <span className='date'>
                    {currentDocument.assignees||'N/A'}
                </span>                    
            </div>
        </div>
        } 
        <form onSubmit={handleSubmit} className='ManualTextAnnotationForm'>
            <div className='formContent'>
                { !(currentDocument && currentDocument.classification) &&
                <>
                <div className='mb-3'>
                    <label htmlFor="classification">Classification</label>
                    <select className='form-select me-2 text-center' title='classification' name="classification" id="classification" value={formData.classification} onChange={handleChange} required >
                        <option value=""  >Select Document Classification</option>
                        <option value="PD">Patent Document</option>
                        <option value="NPD">Non-Patent Literature</option>
                        <option value="PDF">PDF</option>
                    </select>
                </div>
                <div className='mb-3'>
                    <label htmlFor="documentTitle">Document Title</label>
                    <input onChange={handleChange} type="text" name='documentTitle' id={`documentTitle`} className='form-control me-2 ' value={formData.documentTitle} placeholder='Enter document title' required />
                </div>
                {formData.classification==='PD' &&
                <div className='mb-3'>
                    <label htmlFor="assignees">Assignees</label>
                    <input onChange={handleChange} type="text" name='assignees' id={`assignees`} className='form-control me-2 ' value={formData.assignees} />
                </div>
                }
                <div className='mb-3'>
                    <label htmlFor="publicationDate">Publication Date</label>
                    <input onChange={handleChange} type="date" name='publicationDate' id={`publicationDate`} className='form-control me-2 ' value={formData.publicationDate} required />
                </div>
                </>
                }
                { projectElements.length>0 &&
                <>
                {projectElements.map((element, elementIdx) => (
                <div key={elementIdx} className='projectElement'>
                    <div className='projectElementNum'>
                        <div className='num'>{element.featureNum}</div>
                        <div className='text'>&nbsp;&nbsp;Element</div>
                    </div>  
                    <div className='projectElementText'>
                        <div className='elementText'>{element.featureText}</div>
                        {elementAnnotations.length>0 &&
                        elementAnnotations.map((annotation, annotationIdx) => (
                            <div key={annotationIdx} className='elementAnnotation'>
                            {   annotation.featureNum === element.featureNum &&
                                <textarea onChange={handleChange} rows={3} name={`element${elementIdx+1}annotationText`} id={`element${elementIdx+1}annotationText`} className='form-control me-2 ' defaultValue={annotation.annotationText} />
                            }
                            </div>
                        ))
                        }
                        {!presentElements.includes(element.featureNum) &&
                        <div className='elementAnnotation'>
                            <textarea onChange={handleChange} rows={3} name={`element${elementIdx+1}annotationText`} id={`element${elementIdx+1}annotationText`} className='form-control me-2 ' value={formData.annotationText[elementIdx+1]} placeholder={`Enter annotation for element ${elementIdx+1}`} />
                        </div>
                        }
                    </div>
                </div>
                ))
                }
                </>
                }
            </div>
            <div className='text-center mt-3'>
                <button type="submit" className="manualAnnotationSubmissionButton" disabled={false}>Save Annotations</button>
            </div>
            <div><p id={`annotationNotification-Feature`}className='annotationNotification text-center'><small></small></p></div>
        </form>
    </div>
  )
}
