import './NonPatentData.css'

export default function NonPatentData({documentData, currentProject, projectDocuments}) {

  return (
    <div className='NonPatentData'>
        <div dangerouslySetInnerHTML={{__html:documentData.parsedPageData}}></div>
        {/* <div>{highlightedNonPatentData}</div> */}
    </div>
  )
}
