import './ProjectAnalysisSummary.css'

export default function ProjectAnalysisSummary({projectAnalysisSummary, elements}) {
  return (
    <div className='ProjectAnalysisSummary'>
        <div className='summary'>
            <div className='title'>Summary</div>
            <div className='summaryText'>
                <div className='summaryText1'>
                    { projectAnalysisSummary.singleRefAddressingAllElementsList.length === 0 ?
                    <div className='mainMessage'>
                        <div className='listMark1'></div>
                        <div>
                            No single reference addresses all project elements on its own, hence, the invention passes the novelty test.
                        </div>
                    </div>
                    :
                    <>
                    <div className='mainMessage'>
                        <div className='listMark1'></div>
                        <div>The single references that address all project elements are:</div>
                    </div>
                    <div className='summaryRefContainer'>
                        { projectAnalysisSummary.singleRefAddressingAllElementsList.map((ref, idx) => (
                        <div className='summaryRef' key={idx}>
                            <span className='singleRef'>{ref}</span>
                            <span>Reference</span>
                        </div>
                        ))}
                    </div>
                    </>
                    }
                </div>
                { (projectAnalysisSummary.sortedCombinationsAddressingAllFeatures.length === 0 && projectAnalysisSummary.singleRefAddressingAllElementsList.length === 0) &&
                <div className='summaryText1'>
                    <div className='mainMessage'>
                        <div className='listMark2'></div>
                        <div>No combination of references addresses all project elements.</div>
                    </div>
                </div>
                }
                { (projectAnalysisSummary.sortedCombinationsAddressingAllFeatures.length === 0 && projectAnalysisSummary.singleRefAddressingAllElementsList.length !== 0) &&
                <div className='summaryText1'>
                    <div className='mainMessage'>
                        <div className='listMark2'></div>
                        <div>Besides the single references that address all the project elements, no relevant combination of references addresses all project elements.</div>
                    </div>
                </div>
                }
                { projectAnalysisSummary.sortedCombinationsAddressingAllFeatures.length !== 0 &&
                <div className='summaryText1'>
                    <div className='mainMessage'>
                        <div className='listMark2'></div>
                        <div>The combinations of references that address all project elements are:</div>
                    </div>
                    <div className='summaryRefContainer'>
                    { projectAnalysisSummary.sortedCombinationsAddressingAllFeatures.map((item, idx) => (
                        item.thirdRef ?
                        <div className='summaryRef' key={idx}>
                            <span className='multiRef'>{item.firstRef}</span><span>Reference</span>
                            <span className='joiner'>+</span>
                            <span className='multiRef'>{item.secondRef}</span><span>Reference</span>
                            <span className='joiner'>+</span>
                            <span className='multiRef'>{item.thirdRef}</span><span>Reference</span>
                        </div>
                        :
                        <div className='summaryRef' key={idx}>
                            <span className='multiRef'>{item.firstRef}</span><span>Reference</span>
                            <span className='joiner'>+</span>
                            <span className='multiRef'>{item.secondRef}</span><span>Reference</span>
                        </div>
                    ))}
                    </div>
                </div>
                }
                { projectAnalysisSummary.sortedCombinationsWithOmittedFeatures.length !== 0 &&
                <div className='mainMessage'>
                    <div className='listMark3'></div>
                    <div>
                        Some combinations of references that best address the the project elements can be found in the table below:
                    </div>
                </div>
                }
            </div>
        </div>
        { projectAnalysisSummary.sortedCombinationsWithOmittedFeatures.length > 0 &&
        <div className='tableContainer'>
            <table className='table'>
                <thead>
                    <tr>
                        <th scope='col' className='reference'>Reference</th>
                        {elements.map((element, idx) =>(
                        <th scope='col' className='element' key={idx}>{`EL ${element}`}</th>
                        ))}
                        <th scope='col' className='comment'>COMMENT</th>
                    </tr>
                </thead>
                <tbody className='tableBody'>
                    { projectAnalysisSummary.sortedCombinationsWithOmittedFeatures &&
                    projectAnalysisSummary.sortedCombinationsWithOmittedFeatures.map((item, idx) => (
                        <tr key={idx}>
                            { item.thirdRef ?
                            <td className='reference' key={idx}>
                                <div className='referenceContainer'>
                                    <span className='multiRef'>{item.firstRef}</span><span>Ref</span>
                                    <span className='joiner'>+</span>
                                    <span className='multiRef'>{item.secondRef}</span><span>Ref</span>
                                    <span className='joiner'>+</span>
                                    <span className='multiRef'>{item.thirdRef}</span><span>Ref</span>
                                </div>
                            </td>
                            :
                            <td className='reference' key={idx}>
                                <div className='referenceContainer'>
                                    <span className='multiRef'>{item.firstRef}</span><span>Ref</span>
                                    <span className='joiner'>+</span>
                                    <span className='multiRef'>{item.secondRef}</span><span>Ref</span>
                                </div>
                            </td>
                            }
                            {elements.map((element, idx) => (
                            <td className='element' key={idx}>
                                { item.featuresNotPresent.includes(element) ?
                                <div className='projectAnalysisElementAbsent'>
                                    <span></span>
                                </div>
                                :
                                <div className='projectAnalysisElementPresent'>
                                    <span></span>
                                </div>
                                }
                            </td>
                            ))}
                            <td className='commentText'>
                                { item.featuresNotPresent.length > 0 ?
                                <>
                                    <span>This reference combination ommits elements </span>
                                        <span>
                                        {item.featuresNotPresent.map((feature, idx) => (
                                            idx === item.featuresNotPresent.length-1 ?
                                            <span key={idx}>{feature}.</span>
                                            :
                                            <span key={idx}>{feature}, </span>
                                        ))}
                                    </span>
                                </>
                                :
                                <span>All features are addressed.</span>
                                }
                                
                            </td>
                        </tr>
                    ))
                    }
                </tbody>
            </table>
        </div>
        }
    </div>
  )
}
