import Mark from "mark.js";

// Use Mark.js to highlight annotations
const runMarkJS = (annotation, text, element, options) => {
    const instance = new Mark(text)
    options = options || {
        "element": "mark",
        "className": `annotationHighlight element${element}`,
        "exclude": [],
        "iframes": false,
        "iframesTimeout": 5000,
        "separateWordSearch": false,
        "accuracy": "partially",
        "diacritics": true,
        "acrossElements": true,
        "caseSensitive": false,
        "ignoreJoiners": false,
        "wildcards": "disabled",
    };
    instance.mark(annotation, options);
}

const createTooltips = () => {
    const maxElements = 20
    for (let count = 1; count < maxElements; count++) {
        const elements = document.querySelectorAll(`.annotationHighlight.element${count}`)
        elements.forEach(element => {
            element.setAttribute('data-bs-toggle', 'tooltip')
            element.setAttribute('data-bs-placement', 'top')
            element.setAttribute('data-title', `Element ${count}`)
            element.setAttribute('data-bs-html', 'true')
            element.setAttribute('data-bs-trigger', 'hover')
            })
    }
}

export const highlightAnnotations = (annotations) => {
    let data = null
    if (document.querySelector('.PDFData')) {
        data = document.querySelector('.PDFData')
        
    } else if (document.querySelector('.NonPatentData')) {
        data = document.querySelector('.NonPatentData')
    } else if (document.querySelector('.PatentData')) {
        data = document.querySelector('.PatentData')
    } else {
        return
    }
    const instance = new Mark(data)

    // Reset marks before performing operations
    instance.unmark({className: 'annotationHighlight'});
    const marks = data.querySelectorAll('.annotationHighlight')
    marks.forEach(mark => {
        mark.outerHTML = mark.innerHTML
    })
    // Perform annotation highlighting
    for (let i = 0; i < annotations.length; i++) {
        if (!annotations[i].text) {
            continue
        }
        const annotation = annotations[i]
        const text = annotation.text
        const element = annotation.feature
        runMarkJS(text, data, element)
    }
    // Create tooltips for annotations
    createTooltips()
}

