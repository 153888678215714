import NewProjectForm from '../../components/NewProjectForm/NewProjectForm'
import './NewProject.css'

export default function NewProject({user, services, updateProjects}) {
  return (
    <div className='NewProject modal fade' id='newProjectModal' data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`newProjectHeader`} aria-hidden="true">
      <NewProjectForm user={user} services={services} updateProjects={updateProjects} />
    </div>
  )
}
