import './ProjectSearchDocumentFeature.css'

export default function ProjectSearchDocumentFeature({feature, highlightDocumentNumber}) {
  return (
    <span className={`ProjectSearchDocumentFeature ${highlightDocumentNumber ? ' highlightDocumentNumber' : ''}`}>
      <small>
        {`${feature}`}
      </small>
    </span>
  )
}
