import {
    Document, TableRow, TableCell, Table, Paragraph, VerticalAlign, AlignmentType, WidthType, ShadingType,
    Header, TextRun, PageNumber, Footer, HeadingLevel, ExternalHyperlink, Packer, SectionType,
    TableOfContents, StyleLevel, convertInchesToTwip, ImageRun
} from 'docx'

import {saveAs} from 'file-saver'




const projectElementsTable = async (data) => {
    const rows = []
    data.project.features.forEach(feature => {
        const featureRow = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 700,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            text: `${feature.featureNum}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                    width: {
                        size: 8505,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            text: `${feature.featureText}`,
                            alignment: AlignmentType.JUSTIFIED,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                    margins: {
                        left: convertInchesToTwip(0.2),
                        right: convertInchesToTwip(0.2),
                    }
                }),
            ],
        })
        rows.push(featureRow)
    });
    const table = new Table({
        // columnWidths: [700, 8505],
        alignment: AlignmentType.CENTER,
        rows: rows
    })
    return table
}

const freedomToOperateAnalysisTable = (data) => {
    const rows = []
    let headingRow = ['S/N', 'REFERENCE', 'PUBLICATION DATE']
    data.project.features.forEach(feature => {
        headingRow = [...headingRow, `EL ${feature.featureNum}`]
    });
    const elementsHeader = () => {
        const header = []
        headingRow.forEach((element, idx) => {
            if (idx===0) {
                const cell = new TableCell({
                    width: {
                        size: 700,
                        type: WidthType.DXA,
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            text: `${element}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                header.push(cell)
            } else if (idx===1) {
                const cell = new TableCell({
                    width: {
                        size: 4500,
                        type: WidthType.DXA,
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            text: `${element}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                header.push(cell)
            } else if (idx===2) {
                const cell = new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            text: `${element}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                header.push(cell)
            } else {
                const cell = new TableCell({
                    width: {
                        size: 500,
                        type: WidthType.DXA,
                    },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            text: `${element}`,
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                header.push(cell)
            }
        });
        const tableHeaderRow = new TableRow({
            children: header
        })
        return tableHeaderRow
    }
    rows.push(elementsHeader())

    const documentRows = () => {
        data.documents.forEach((document, idx) => {
            const documentCells = []

            // Number
            let cell = new TableCell({
                // width: {
                //     size: 700,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0),
                    bottom: convertInchesToTwip(0),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        text: `${idx+1}`,
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            })
            documentCells.push(cell)

            // Reference
            if (document.features[0].classification === 'PD') {
                cell = new TableCell({
                    // width: {
                    //     size: 4500,
                    //     type: WidthType.DXA,
                    // },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            // text: `${document.documentNumber}`,
                            alignment: AlignmentType.CENTER,
                            children: [
                                new ExternalHyperlink({
                                    children: [
                                        new TextRun({
                                            text: `${document.documentNumber}`,
                                            style: "Hyperlink",
                                        }),
                                    ],
                                    link: `https://patents.google.com/patent/${document.documentNumber}/en?oq=${document.documentNumber}`,
                                }),
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                documentCells.push(cell)                    
            } else {
                cell = new TableCell({
                    // width: {
                    //     size: 4500,
                    //     type: WidthType.DXA,
                    // },
                    margins: {
                        left: convertInchesToTwip(0),
                        right: convertInchesToTwip(0),
                        top: convertInchesToTwip(0),
                        bottom: convertInchesToTwip(0),
                    },
                    children: [
                        new Paragraph({
                            style: 'MyTableStyle',
                            // text: `${document.features[0].documentTitle}`,
                            alignment: AlignmentType.CENTER,
                            children: [
                                new ExternalHyperlink({
                                    children: [
                                        new TextRun({
                                            text: `${document.features[0].documentTitle}`,
                                            style: "Hyperlink",
                                        }),
                                    ],
                                    link: `${document.documentNumber}`,
                                }),
                            ],
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                })
                documentCells.push(cell)
            }
            
            // Publication Date
            // const documentData = postPatentId({patentId: document.documentNumber})
            cell = new TableCell({
                // width: {
                //     size: 3500,
                //     type: WidthType.DXA,
                // },
                margins: {
                    left: convertInchesToTwip(0),
                    right: convertInchesToTwip(0),
                    top: convertInchesToTwip(0),
                    bottom: convertInchesToTwip(0),
                },
                children: [
                    new Paragraph({
                        style: 'MyTableStyle',
                        // text: `${documentData.publicationDate}`,
                        text: `${document.features[0].documentPublicationDate}`,
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            })
            documentCells.push(cell)

            // ELEMENTS
            const projectFeaturesArray = []
            for (let index = 0; index < data.project.features.length; index++) {
                projectFeaturesArray.push(`${index+1}`)
            }
            let documentFeaturesArray = []
            for (let index = 0; index < document.features.length; index++) {
                documentFeaturesArray.push(document.features[index].feature)
            }
            const documentFeaturesSet = new Set(documentFeaturesArray)
            documentFeaturesArray = Array.from(documentFeaturesSet)
            projectFeaturesArray.forEach((projectFeature, idx) => {
                if (documentFeaturesArray.includes(projectFeature)) {
                    cell = new TableCell({
                        // width: {
                        //     size: 500,
                        //     type: WidthType.DXA,
                        // },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "FF3131",
                            // fill: "FF3131",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `X`,
                                alignment: AlignmentType.CENTER,
                                // style: "MyPresentTableStyle",
                            }),
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                    documentCells.push(cell)
                } else {
                    cell = new TableCell({
                        // width: {
                        //     size: 500,
                        //     type: WidthType.DXA,
                        // },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "50C878",
                            // fill: "50C878",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: ``,
                                alignment: AlignmentType.CENTER,
                                // style: "MyAbsentTableStyle",
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                    documentCells.push(cell)
                }
            })

            const documentTableRow = new TableRow({
                children: documentCells
            })
            rows.push(documentTableRow)
        });
    }
    documentRows()
    const table = new Table({
        // columnWidths: [700, 8505],
        alignment: AlignmentType.CENTER,
        rows: rows
    })
    return table
}

const citationReferences = async (data) => {
    let paragraphs = [
        new Paragraph({
            pageBreakBefore: true,
            heading: HeadingLevel.HEADING_1,
            children: [
                new TextRun({
                    text: "APPENDIX I - CITATIONS",
                    allCaps: true,
                })
            ]
        })
    ]
    data.documents.forEach((document, idx) => {
        const paraHead = new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({
                    text: `Reference ${idx+1}`,
                })
            ]
        })
        paragraphs.push(paraHead)

        if (document.features[0].classification === 'PD') {
            const docNumber = new Paragraph({
                style: 'MyNormalStyle',
                shading: {
                    type: ShadingType.SOLID,
                    color: "ECECEC",
                    // fill: "ECECEC",
                },
                children: [
                    new TextRun({
                        text: `Original Document: `,
                        bold: true,
                    }),
                    // new TextRun({
                    //     text: `${document.documentNumber}`,
                    // }),
                    new ExternalHyperlink({
                        children: [
                            new TextRun({
                                text: `${document.documentNumber}`,
                                style: "Hyperlink",
                            }),
                        ],
                        link: `https://patents.google.com/patent/${document.documentNumber}/en?oq=${document.documentNumber}`,
                    }),
                ]
            })
            paragraphs.push(docNumber)
        } else {
            const docNumber = new Paragraph({
                style: 'MyNormalStyle',
                shading: {
                    type: ShadingType.SOLID,
                    color: "ECECEC",
                    // fill: "ECECEC",
                },
                children: [
                    new TextRun({
                        text: `Original Document: `,
                        bold: true,
                    }),
                    // new TextRun({
                    //     text: `${document.documentNumber}`,
                    // }),
                    new ExternalHyperlink({
                        children: [
                            new TextRun({
                                text: `${document.documentNumber.substring(0, 100)}`,
                                style: "Hyperlink",
                            }),
                        ],
                        link: `${document.documentNumber}`,
                        // link: `https://nulll.com`,
                    }),
                ]
            })
            paragraphs.push(docNumber)
        }
        

        const docTitle = new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            shading: {
                type: ShadingType.SOLID,
                color: "ECECEC",
                // fill: "ECECEC",
            },
            children: [
                new TextRun({
                    text: `Title: `,
                    bold: true,
                }),
                new TextRun({
                    text: `${document.features[0].documentTitle}`,
                    allCaps: true,
                }),
            ]
        })
        paragraphs.push(docTitle)

        if (document.features[0].classification === 'PD') {
            const assignees = new Paragraph({
                style: 'MyNormalStyle',
                shading: {
                    type: ShadingType.SOLID,
                    color: "ECECEC",
                    // fill: "ECECEC",
                },
                children: [
                    new TextRun({
                        text: `Assignee(s): `,
                        bold: true,
                    }),
                    new TextRun({
                        text: `${document.features[0].assignees||'N/A'}`,
                    }),
                ]
            })
            paragraphs.push(assignees)
        }

        const docPub = new Paragraph({
            style: 'MyNormalStyle',
            shading: {
                type: ShadingType.SOLID,
                color: "ECECEC",
                // fill: "ECECEC",
            },
            children: [
                new TextRun({
                    text: `Publication Date: `,
                    bold: true,
                }),
                new TextRun({
                    text: `${document.features[0].documentPublicationDate}`,
                }),
            ]
        })
        paragraphs.push(docPub)

        for (let index = 0; index < document.features.length; index++) {
            let prepend = ''
            if (document.features[index].paragraphNumber && document.features[index].paragraphNumber !== '0000') {
                prepend = `[Para ${document.features[index].paragraphNumber}] `
            } else if (document.features[index].claimNumber) {
                prepend = `Claim ${document.features[index].claimNumber}: `
            } else if (document.features[index].abstract === 'true') {
                prepend = `Abstract: `
            } else if (document.features[index].imageURL) {
                prepend = `Image: `
            }
            let item
            if (index===0 || ((document.features[index].feature !== document.features[index-1].feature))) {
                item = new Paragraph({
                        style: 'MyItalicsStyle',
                        spacing: { before: 200, after: 50 },
                        text: `Element ${document.features[index].feature}`,
                        bullet: {
                            level: 0
                        },
                    })
                paragraphs.push(item)
                if (document.features[index].textAnnotation) {
                    item = new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: { after: 150 },
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                italics: false,
                                text: prepend,
                            }),
                            new TextRun({
                                text: `${document.features[index].textAnnotation}`,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                } else if (document.features[index].imageURL) {
                    item = new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: { after: 150 },
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                italics: false,
                                text: prepend,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                    let imageFile = ''
                    data.documentImages.forEach((documentImage, idx) => {
                        if (documentImage.url === document.features[index].imageURL) {
                            imageFile = documentImage.image
                        }
                    })
                    item = new Paragraph({
                        // style: 'MyNormalStyle',
                        border: {
                            top: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            bottom: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            left: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            right: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                        },
                        spacing: { before: 150, after: 150 },
                        alignment: AlignmentType.CENTER,
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new ImageRun({
                                        data: imageFile,
                                        transformation: {
                                            width: 600,
                                            height: 600,
                                        },
                                    }),
                                ],
                                link: `${document.features[index].imageURL}`,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                }
            } else {
                if (document.features[index].textAnnotation) {
                    item = new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: { after: 150 },
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                italics: false,
                                text: prepend,
                            }),
                            new TextRun({
                                text: `${document.features[index].textAnnotation}`,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                } else if (document.features[index].imageURL) {
                    item = new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: { after: 150 },
                        alignment: AlignmentType.JUSTIFIED,
                        children: [
                            new TextRun({
                                italics: false,
                                text: prepend,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                    let imageFile = ''
                    data.documentImages.forEach( (documentImage, idx) => {
                        if (documentImage.url === document.features[index].imageURL) {
                            imageFile = documentImage.image
                        }
                    })
                    item = new Paragraph({
                        // style: 'MyNormalStyle',
                        border: {
                            top: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            bottom: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            left: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                            right: {
                                color: "auto",
                                space: 1,
                                style: "single",
                                size: 6,
                            },
                        },
                        spacing: { before: 150, after: 150 },
                        alignment: AlignmentType.CENTER,
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new ImageRun({
                                        data: imageFile,
                                        transformation: {
                                            width: 300,
                                            height: 300,
                                        },
                                    }),
                                ],
                                link: `${document.features[index].imageURL}`,
                            }),
                        ]
                    })
                    paragraphs.push(item)
                }
            }
            
        }
    })
    return paragraphs
}

const analysis = async (data) => {
    let elements = []

    const mainHeading = new Paragraph({
        // pageBreakBefore: false,
        heading: HeadingLevel.HEADING_1,
        children: [
            new TextRun({
                text: "3 general notes",
                allCaps: true,
            })
        ]
    })
    elements.push(mainHeading)

    const firstParagraph3p1 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "3.1 How to Read Report",
                // allCaps: true,
            })
        ]
    })
    elements.push(firstParagraph3p1)

    const secondParagraph3p1 = new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        style: 'MyNormalStyle',
        spacing: { before: 250, after: 50 },
        text: "The purpose of this technical Freedom to Operate (FTO) analysis is to find out which feature(s) (out of four top presented features) are infringing on claims of a certain patent in the country that your product is going to be launched.",
        bullet: {
            level: 0
        }
    })
    elements.push(secondParagraph3p1)

    const thirdParagraph3p1 = new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        style: 'MyNormalStyle',
        spacing: { before: 250, after: 50 },
        text: "Your features will be analyzed in section 3.2 and the percentage of the references in the reference table that address your features will be presented.",
        bullet: {
            level: 0
        }
    })
    elements.push(thirdParagraph3p1)

    const fourthParagraph3p1 = new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        style: 'MyNormalStyle',
        spacing: { before: 250, after: 50 },
        text: "If any of the features are being addressed by over 50% of the references then the feature will be colour coded in red. This means that the feature(s) will be infringing on a patent.",
        bullet: {
            level: 0
        }
    })
    elements.push(fourthParagraph3p1)

    const fifthParagraph3p1 = new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        style: 'MyNormalStyle',
        spacing: { before: 250, after: 50 },
        text: "If any of the features are being addressed by over 30% of the references and less than 50% of the references then the feature will be colour coded in yellow. This means that the feature(s) may infringe on a patent.",
        bullet: {
            level: 0
        }
    })
    elements.push(fifthParagraph3p1)

    const sixthParagraph3p1 = new Paragraph({
        alignment: AlignmentType.JUSTIFIED,
        style: 'MyNormalStyle',
        spacing: { before: 250, after: 50 },
        text: "If any of the features are being addressed by less than 30% of the references then the feature will be colour coded in green. This means that the feature(s) is not infringing on a patent.",
        bullet: {
            level: 0
        }
    })
    elements.push(sixthParagraph3p1)

    const firstParagraph3p2 = new Paragraph({
        pageBreakBefore: true,
        heading: HeadingLevel.HEADING_2,
        children: [
            new TextRun({
                text: "3.2 Analysis",
                // allCaps: true,
            })
        ]
    })
    elements.push(firstParagraph3p2)

    // Analysis Table
    const section3p2Rows = []

    const section3p2TableHeader = () => {
        const header = []
        const cell = new TableCell({
            width: {
                size: 500,
                type: WidthType.DXA,
            },
            margins: {
                left: convertInchesToTwip(0.2),
                right: convertInchesToTwip(0.2),
                top: convertInchesToTwip(0),
                bottom: convertInchesToTwip(0),
            },
            children: [
                new Paragraph({
                    style: 'MyTableStyle',
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `Feature`,
                            bold: true,
                        }),
                    ],
                })
            ],
            verticalAlign: VerticalAlign.CENTER,
        })
        header.push(cell)
        const cell2 = new TableCell({
            width: {
                size: 500,
                type: WidthType.DXA,
            },
            margins: {
                left: convertInchesToTwip(0.2),
                right: convertInchesToTwip(0.2),
                top: convertInchesToTwip(0),
                bottom: convertInchesToTwip(0),
            },
            children: [
                new Paragraph({
                    style: 'MyTableStyle',
                    alignment: AlignmentType.CENTER,
                    children: [
                        new TextRun({
                            text: `% found by the prior art in the reference table`,
                            bold: true,
                        }),
                    ],
                })
            ],
            verticalAlign: VerticalAlign.CENTER,
        })
        header.push(cell2)
        const tableHeaderRow = new TableRow({
            // give the header row a background color
            children: header
        })
        return tableHeaderRow
    }
    section3p2Rows.push(section3p2TableHeader())

    const projectFeaturesArray = []
    for (let index = 0; index < data.project.features.length; index++) {
        projectFeaturesArray.push(`${index+1}`)
    }

    let featurePercentagesList = []
    projectFeaturesArray.forEach(projectFeature => {
        const documentsContainingFeature = []
        data.documents.forEach((document) => {
            document.features.forEach((documentFeature) => {
                if (documentFeature.feature === projectFeature) {
                    if (!documentsContainingFeature.includes(document.documentNumber)) {
                        documentsContainingFeature.push(document.documentNumber)
                    }
                }
            });
        })
        // console.log(documentsContainingFeature)
        const percentage = Math.round((documentsContainingFeature.length / data.documents.length) *100)
        // console.log(percentage)
        const featurePercent = {
            feature: projectFeature,
            percentage: percentage
        }
        featurePercentagesList= [...featurePercentagesList, featurePercent]
        if (percentage >= 50) {
            const row = new TableRow({
                children: [
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "FF3131",
                        //     // fill: "FF3131",
                        // },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `${projectFeature}`,
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "FF3131",
                            // fill: "FF3131",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `${percentage}`,
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                ],
                
            })
            section3p2Rows.push(row)
        } else if (percentage >= 30 && percentage < 50) {
            const row = new TableRow({
                children: [
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "FFFF00",
                        //     // fill: "FFFF00",
                        // },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `${projectFeature}`,
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "FFFF00",
                            // fill: "FFFF00",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `${percentage}`,
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                ]
            })
            section3p2Rows.push(row)
        } else {
            const row = new TableRow({
                children: [
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        // shading: {
                        //     type: ShadingType.SOLID,
                        //     color: "50C878",
                        //     // fill: "50C878",
                        // },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `${projectFeature}`,
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    }),
                    new TableCell({
                        width: {
                            size: 2500,
                            type: WidthType.DXA,
                        },
                        margins: {
                            left: convertInchesToTwip(0),
                            right: convertInchesToTwip(0),
                            top: convertInchesToTwip(0),
                            bottom: convertInchesToTwip(0),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "50C878",
                            // fill: "50C878",
                        },
                        children: [
                            new Paragraph({
                                style: 'MyNormalStyle',
                                text: `${percentage}`,
                                alignment: AlignmentType.CENTER,
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER,
                    })
                ]
            })
            section3p2Rows.push(row)
        }
    });
    
    const section4p2Table = new Table({
        columnWidths: [2500, 2500],
        alignment: AlignmentType.CENTER,
        rows: section3p2Rows
    })
    elements.push(section4p2Table)
    

    const firstParagraph3p3 = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 400, after: 250 },
        children: [
            new TextRun({
                text: "3.3 Interpreting the Results",
                // allCaps: true,
            })
        ]
    })
    elements.push(firstParagraph3p3)

    const secondParagraph3p3 = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: `The presented values (percentage) in section 3.2 shows: `,
                break: 0,
            }),
        ]
    })
    elements.push(secondParagraph3p3)

    const fillManually = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: "....................type here........................",
                italics: true,
                break: 0,
            }),
        ]
    })
    elements.push(fillManually)
    
    return elements
}

const recommendations = async (data) => {
    let elements = []

    const mainHeading = new Paragraph({
        pageBreakBefore: true,
        heading: HeadingLevel.HEADING_1,
        children: [
            new TextRun({
                text: "4 RECOMMENDATIONS BASED ON THE FTO ANALYSIS",
                allCaps: true,
            })
        ]
    })
    elements.push(mainHeading)

    

    const section4p1ToFill = new Paragraph({
        style: 'MyNormalStyle',
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: "....................type here........................",
                italics: true,
            }),
        ]
    })
    elements.push(section4p1ToFill)

    return elements
}

const searchHistory = (data) => {
    let elements = [
        new Paragraph({
            heading: HeadingLevel.HEADING_1,
            children: [
                new TextRun({
                    text: "5 Search Tools and Search Histories",
                    allCaps: true,
                })
            ]
        }),
        new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({
                    text: "5.1 Patent Search Tools and Patent Search History",
                })
            ]
        }),
        new Paragraph({
            style: 'MyNormalStyle',
            alignment: AlignmentType.JUSTIFIED,
            children: [
                new TextRun({
                    text: "The search and analysis of patent literature were carried out using Questel’s Orbit software suite and Google Patents. ",
                }),
                new TextRun({
                    text: "A non-patent literature search was carried out using Google Web/Scholar. You can access the Orbit search history for patent literature ",
                }),
                new ExternalHyperlink({
                    children: [
                        new TextRun({
                            text: "here",
                            style: "Hyperlink",
                            italics: false,
                        }),
                    ],
                    link: `${data.project.orbitSearchHistoryURL}`,
                }),
            ]
        }),
    ]

    // CLASSIFICATION SEARCH TABLE
    const classificationSearchTitle = new Paragraph({
        heading: HeadingLevel.HEADING_2,
        spacing: {before: 350},
        children: [
            new TextRun({
                text: "5.2 Classification Search",
                // allCaps: true,
            })
        ]
    })

    elements.push(classificationSearchTitle)
    const classificationSearchText = new Paragraph({
        style: 'MyNormalStyle',
        spacing: {before: 250, after: 300},
        alignment: AlignmentType.JUSTIFIED,
        children: [
            new TextRun({
                text: "IPC (International Patent Classification) and CPC (Cooperative Patent Classification) are hierarchical classification systems used primarily to classify and search patent documents (patent applications, specifications of granted patents, utility models, etc.)",
            }),
            new TextRun({
                text: " according to the technical fields they pertain, serving as instruments for the orderly arrangement of the patent documents and a basis for investigating the state of the art in given fields of technology.",
            }),
        ]
    })
    elements.push(classificationSearchText)

    const classificationSearchRows = []

    const classificationSearchHeader = new TableRow({
        children: [
            new TableCell({
                width: {
                    size: 5000,
                    type: WidthType.DXA,
                },
                shading: {
                    type: ShadingType.SOLID,
                    color: "d8d8d8",
                    // fill: "50C878",
                },
                children: [
                    new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: {before: 150, after: 150},
                        
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new TextRun({
                                        text: "IPC Classification (text limit)",
                                        style: "Hyperlink",
                                        italics: false,
                                        bold: true,
                                    }),
                                ],
                                link: "https://ipcpub.wipo.int",
                            }),
                            // new TextRun({
                            //     text: `IPC Classification (text limit)`,
                            //     bold: true,
                            // })
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
            new TableCell({
                width: {
                    size: 5000,
                    type: WidthType.DXA,
                },
                shading: {
                    type: ShadingType.SOLID,
                    color: "d8d8d8",
                    // fill: "50C878",
                },
                children: [
                    new Paragraph({
                        style: 'MyNormalStyle',
                        spacing: {before: 150, after: 150},
                        
                        children: [
                            new ExternalHyperlink({
                                children: [
                                    new TextRun({
                                        text: "CPC Classification (text limit)",
                                        style: "Hyperlink",
                                        italics: false,
                                        bold: true,
                                    }),
                                ],
                                link: "https://worldwide.espacenet.com/patent/cpc-browser",
                            }),
                            // new TextRun({
                            //     text: `CPC Classification (text limit)`,
                            //     bold: true,
                            // })
                        ],
                        alignment: AlignmentType.CENTER,
                    })
                ],
                verticalAlign: VerticalAlign.CENTER,
            }),
        ]
    })
    classificationSearchRows.push(classificationSearchHeader)

    let classificationSearchBodyCount = 1
    while (data.project[`CPC${classificationSearchBodyCount}`] || data.project[`IPC${classificationSearchBodyCount}`]) {
        const classificationSearchBody = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: {before: 100, after: 150},
                            children: [
                                new TextRun({
                                    text: `${data.project[`IPC${classificationSearchBodyCount}`]}`,
                                })
                            ],
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
                new TableCell({
                    width: {
                        size: 5000,
                        type: WidthType.DXA,
                    },
                    children: [
                        new Paragraph({
                            style: 'MyNormalStyle',
                            spacing: {before: 100, after: 100},
                            children: [
                                new TextRun({
                                    text: `${data.project[`CPC${classificationSearchBodyCount}`]}`,
                                })
                            ],
                            alignment: AlignmentType.CENTER,
                        })
                    ],
                    verticalAlign: VerticalAlign.CENTER,
                }),
            ]
        })
        classificationSearchRows.push(classificationSearchBody)
        classificationSearchBodyCount++
    }
    

    const classificationSearchTable = new Table({
        alignment: AlignmentType.CENTER,
        rows: classificationSearchRows
    })
    elements.push(classificationSearchTable)

    return elements
}

const disclaimer= () => {
    const elements = [
        new Paragraph({
            style: 'MyNormalStyle',
            pageBreakBefore: true,
            spacing: {before: 12000},
            alignment: AlignmentType.JUSTIFIED,
            children: [
                new TextRun({
                    text: "Disclaimer: ",
                    italics: true,
                }),
                new TextRun({
                    text: "This FTO analysis report, generated by technical experts at Riahi Patents, is not a legal opinion. It is prepared by comparing technical features of products with patent claims. Riahi Patents disclaims any liability for loss or damage resulting from reliance on this report.",
                    italics: true,
                }),
            ]
        }),
    ]
    return elements
}

async function saveDocumentToFile(doc, fileName, mimeType) {
    // Create a mime type that will associate the new file with Microsoft Word
    // const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    // Create a Blob containing the Document instance and the mimeType
    Packer.toBlob(doc).then(blob => {
      const docblob = blob.slice(0, blob.size, mimeType)
      // Save the file using saveAs from the file-saver package
      saveAs(docblob, fileName)
    })
}

export const generateReportTypeB = async (data) => {
    // console.log(data)
    const doc = new Document({
        creator: "Riahi-IP",
        description: `Report generated for ${data.project.projNum}`,
        title: `${data.project.projNum}`,
        styles: {
            default: {
                heading1: {
                    run: {
                        size: 28,
                        bold: true,
                        italics: false,
                        color: "000000",
                        font: "arial",
                    },
                    paragraph: {
                        spacing: {
                            after: 120,
                        },
                    },
                },
                heading2: {
                    run: {
                        size: 26,
                        bold: true,
                        font: "arial",
                        // underline: {
                        //     type: UnderlineType.DOUBLE,
                        //     color: "000000",
                        // },
                    },
                    paragraph: {
                        spacing: {
                            before: 240,
                            after: 120,
                        },
                        font: "arial",
                    },
                },
                listParagraph: {
                    run: {
                        color: "000000",
                        font: "arial",
                    },
                },
            },
            paragraphStyles: [
                {
                    id: "MySpectacularStyle",
                    name: "My Spectacular Style",
                    basedOn: "Heading1",
                    next: "Heading1",
                    quickFormat: true,
                    run: {
                        italics: true,
                        color: "990000",
                        font: "arial",
                        size: 24,
                    },
                },
                {
                    id: "MyNormalStyle",
                    name: "My Normal Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: false,
                        color: "000000",
                        font: "arial",
                        size: 24,
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: {
                            line: 276,
                        },
                    },
                },
                {
                    id: "MyTableStyle",
                    name: "My Table Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: false,
                        color: "000000",
                        font: "arial",
                        size: 20,
                    },
                    paragraph: {
                        // indent: {
                        //     left: convertInchesToTwip(0.5),
                        // },
                        spacing: {
                            line: 276,
                        },
                    },
                },
                {
                    id: "MyItalicsStyle",
                    name: "My Italics Style",
                    basedOn: "Normal",
                    next: "Normal",
                    quickFormat: true,
                    run: {
                        italics: true,
                        color: "000000",
                        font: "arial",
                        size: 24,
                    },
                    paragraph: {
                        spacing: {
                            line: 276,
                        },
                    },
                },
            ],
        },
        sections: [
            {
                // COVER PAGE
                properties: {
                    type: SectionType.CONTINUOUS,
                    // add background color (not working)
                    background: {
                        color: "000000",
                    },
                    page: {
                        margin: {
                            top: convertInchesToTwip(3.2),
                            bottom: convertInchesToTwip(0.5),
                            left: convertInchesToTwip(1),
                            right: convertInchesToTwip(0.5),
                        },
                        shading: {
                            type: ShadingType.SOLID,
                            color: "FF3131",
                            // fill: "FF3131",
                        },
                    },
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        children: [
                            new TextRun({
                                text: "Riahi Patents, Inc.",
                                size: 40,
                                bold: true,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 600 },
                        children: [
                            new TextRun({
                                text: "Prior Art Search (Freedom to Operate)",
                                size: 56,
                                bold: true,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 1000 },
                        children: [
                            new TextRun({
                                text: `${data.project.projNum}`,
                                size: 36,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 300 },
                        children: [
                            new TextRun({
                                text: `Title: `,
                                size: 36,
                                bold: false,
                                font: "arial",
                            }),
                            new TextRun({
                                text: `${data.project.projTitle}`,
                                size: 36,
                                bold: false,
                                font: "arial",
                                allCaps: true,
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 1800 },
                        children: [
                            new TextRun({
                                text: "Prepared for ",
                                size: 36,
                                font: "arial",
                            }),
                            new TextRun({
                                text: `${data.project.company}`,
                                size: 36,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 400 },
                        children: [
                            new TextRun({
                                text: `${data.project.submissionDate}`,
                                size: 36,
                                font: "arial",
                            }),
                        ],
                    }),
                ],
            },
            {
                // TABLE OF CONTENT
                children: [
                    new TableOfContents("Table of Content", {
                        run: {
                            size: 28,
                            bold: true,
                            italics: false,
                            color: "000000",
                            font: "arial",
                        },
                        hyperlink: true,
                        headingStyleRange: "1-5",
                        stylesWithLevels: [
                            new StyleLevel('MyNormalStyle', 1),
                            // new StyleLevel("MyNormalStyle", 2),
                            // new StyleLevel("MyNormalStyle", 3),
                        ],
                        // entryAndPageNumberSeparator: '',
                    })
                ]
            },
            {
                // 1 PROJECT ELEMENTS TABLE
                headers: {
                    default: new Header({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        children: [PageNumber.CURRENT],
                                        font: 'arial',
                                    })
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.LEFT,
                                spacing: {after: 400 },
                                children: [
                                    // new ImageRun({
                                    //     // data: "https://images.unsplash.com/photo-1530595467537-0b5996c41f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MXx8fGVufDB8fHx8fA%3D%3D&w=1000&q=80",
                                    //     transformation: {
                                    //         width: 50,
                                    //         height: 50,
                                    //     },
                                    // }),
                                    new TextRun({
                                        text: `${data.project.projNum}`,
                                        allCaps: true,
                                        font: "arial",
                                        size: 24,
                                        bold: false,
                                    }),
                                    // new TextRun({
                                    //     children: [PageNumber.CURRENT],
                                    // })
                                ]
                            }),
                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                border: {
                                    top: {
                                        color: 'auto',
                                        space: 1,
                                        style: "single",
                                        size: 10,
                                    }
                                },
                                children: [
                                    // new SymbolRun({
                                    //     char: "+1F310",
                                    //     size: 30,
                                    //     font: 'arial',
                                    //     // bold: true,
                                    //     // italics: true,
                                    // }),
                                    new TextRun({
                                        text: "Riahi Patents, Inc.",
                                        size: 24,
                                        font: 'arial',
                                    }),
                                    new TextRun({
                                        text: "                                                                    ",
                                        size: 24,
                                        font: 'arial',
                                    }),
                                    new ExternalHyperlink({
                                        children: [
                                            new TextRun({
                                                text: "https://riahipatents.com",
                                                style: "Hyperlink",
                                                size: 24,
                                                font: 'arial',
                                            }),
                                        ],
                                        link: "https://riahipatents.com",
                                    }),
                                    // new TextRun({
                                    //     children: [PageNumber.CURRENT],
                                    // }),
                                    
                                ]
                            }),
                            new Paragraph({
                                alignment: AlignmentType.CENTER,
                                children: [
                                    new TextRun({
                                        text: "Confidential Document",
                                        size: 24,
                                        font: 'arial',
                                        italics: true,
                                    }),
                                    // new TextRun({
                                    //     children: [PageNumber.CURRENT],
                                    // }),
                                    
                                ]
                            }),
                           
                        ],
                    }),
                },
                children: [
                    new Paragraph({
                        pageBreakBefore: true,
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: "1 introduction",
                                allCaps: true,
                            })
                        ]
                    }),
                    new Paragraph({
                        spacing: { before: 250, after: 200 },
                        alignment: AlignmentType.JUSTIFIED,
                        style: 'MyNormalStyle',
                        children: [
                            new TextRun({
                                text: `Riahi Patents, Inc. has received the Freedom to Operate (FTO) search request from ${data.project.company}.`,
                                italics: false,
                            }),
                        ]
                        
                        
                    }),
                    await projectElementsTable(data)
                ]
            },
            {
                // 2 FREEDOM TO OPERATE ANALYSIS TABLE
                // properties: {
                //     type: SectionType.CONTINUOUS,
                // },
                children: [
                    new Paragraph({
                        pageBreakBefore: true,
                        heading: HeadingLevel.HEADING_1,
                        children: [
                            new TextRun({
                                text: "2 freedom to operate analysis table",
                                allCaps: true,
                            })
                        ]
                    }),
                    freedomToOperateAnalysisTable(data)
                ]
            },
            {
                // 3 GENERAL NOTES
                properties: {
                    type: SectionType.CONTINUOUS,
                },
                children: await analysis(data)
            },
            {
                // 4 RECOMMENDATIONS
                properties: {
                    type: SectionType.NEXT_PAGE,
                },
                children: await recommendations(data)
            },
            {
                // 5 SEARCH TOOLS AND SEARCH HISTORIES 
                properties: {
                    type: SectionType.NEXT_PAGE,
                },
                children: searchHistory(data)
            },
            {
                // APPENDIX - CITATIONS
                // type: SectionType.CONTINUOUS,
                style: 'MyNormalStyle',
                children: await citationReferences(data)
            },
            {
                // DISCLAIMER
                properties: {
                    type: SectionType.NEXT_PAGE,
                },
                children: disclaimer()
            },
            {
                // BACK COVER PAGE
                properties: {
                    type: SectionType.NEXT_PAGE,
                    page: {
                        margin: {
                            top: convertInchesToTwip(1.5),
                            left: convertInchesToTwip(1),
                        },
                    },
                },
                children: [
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { after: 200 },
                        children: [
                            new TextRun({
                                text: "https://riahipatents.com",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { after: 200 },
                        children: [
                            new TextRun({
                                text: "bit.ly/dr_riahi",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { after: 200 },
                        children: [
                            new TextRun({
                                text: "projects@riahi-ip.com",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 0, after: 100 },
                        children: [
                            new TextRun({
                                text: "5307 Victoria Drive #267 Vancouver, BC V5P 3V6",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { before: 0, after: 100 },
                        children: [
                            new TextRun({
                                text: "1300-1969 Upper Water St., Mcinnes Cooper Tower, Purdy's Wharf, Halifax, NS B3J 3R7",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { after: 200 },
                        children: [
                            new TextRun({
                                text: "295 Hagey Blvd., Waterloo, ON, N2L 6R5",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                    new Paragraph({
                        alignment: AlignmentType.LEFT,
                        spacing: { after: 20 },
                        children: [
                            new TextRun({
                                text: "+1(226)458-8423",
                                size: 30,
                                bold: false,
                                font: "arial",
                            }),
                        ],
                    }),
                ],
            }
        ],
    });
    const projectTitle = (`Freedom to Operate Analysis Report - ${data.project.projNum.replaceAll('-','.')}.docx`)
    await saveDocumentToFile(doc, projectTitle, "application/vnd.openxmlformats-officedocument.wordprocessingml.document")

    // return Packer.toBuffer(doc).then((buffer) => {
    //     fs.writeFileSync(`${data.project.projNum}.docx`, buffer);
    // });
}