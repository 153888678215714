import './Projects.css'
import { getProjects } from '../../utilities/api/projects'
import { deleteProject } from '../../utilities/api/projects'
import ProjectsItem from '../../components/ProjectsItem/ProjectsItem'
import NewProject from '../../components/NewProject/NewProject'

export default function Projects({user, projects, projectsLoading, updateProjects, services}) {
  const deleteProjectItem = async (projectItem) => {
    await deleteProject({project:projectItem, user:user})
    const allProjects = await getProjects(user._id)
    updateProjects(allProjects)
  }

  return (
    <div className='Projects'>
      {projectsLoading ?
      <div className='loading'>
        <div className="spinner-border" role="status"></div>
      </div>
      :
      <>
      <div className='section1'>
      <h4 className='section1p1'>
        Projects
      </h4>
      { (user.userType === 'admin' || user.userType === 'manager' || user.userType === 'analyst') &&
      <div className='section1p2'>
        <button className='createProject' data-bs-toggle="modal" data-bs-target={`#newProjectModal`}>
          <svg width="20" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="38" height="38" rx="10" fill="#FFFFFF" opacity='0.2'/>
            <path d="M19 11V27V11ZM27 19H11H27Z" fill="#2173F0"/>
            <path d="M19 11V27M27 19H11" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          <span>
          &nbsp;&nbsp;Create New
          </span>
        </button>
        <NewProject user={user} services={services} updateProjects={updateProjects} />
      </div>
      }
      </div>
      <div className='section2'>
        <div className='projectsTable'>
          { projects && projects.length>0 ?
          <table>
            <thead>
              <tr>
                <th scope='col' className='projectNumber'>Project number</th>
                <th scope='col'>Title</th>
                <th scope='col'>Company</th>
                <th scope='col' className='text-center'>Element quantity</th>
                <th scope='col'>Submission date</th>
                <th scope='col'>Creation date</th>
                <th scope='col'>Status</th>
                <th scope='col' className='action text-center'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
              projects.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1).map((project, idx) => (
                <ProjectsItem user={user} projectItem={project} key={idx} idx={idx} deleteProjectItem={deleteProjectItem} services={services} updateProjects={updateProjects} />
              ))
              }
            </tbody>
          </table>
          : 
          <h5 className='text-center'><strong>No Projects</strong></h5>
          }
        </div>
        {/* <div className='navigation'>
          page count
        </div> */}
      </div>
      </>
      }
    </div>
  )
}
