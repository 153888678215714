import { useState } from 'react'
import './NewProjectForm.css'

import { createProject, findProject } from '../../utilities/api/projects'
// import { useNavigate } from 'react-router-dom'
import NewProjectFeaturesFormItem from '../NewProjectFeaturesFormItem/NewProjectFeaturesFormItem'
import { formNotificationStyle1 } from '../../utilities/services/formNotification'

const defaultState = {
  projType: '',
  projNum: '',
  projTitle:'',
  submissionDate:'',
  company: '',
  // featureQuantity: 0,
  error: ''
}

export default function NewProjectForm({user, services, updateProjects}) {
  // const navigate = useNavigate()
  const projNumReg = /^([A-Z]{2}-[0-9]{4}\.[0-9]{2}\.[0-9]{3})$/g
  const [formData, setFormData] = useState(defaultState)
  const [featureNums, setFeatureNums] = useState([])
  const [featuresArray, setFeaturesArray] = useState([])
  const [disableAddFeatureButton, setDisableAddFeatureButton] = useState(false)
  const [isValidProjNum, setIsValidProjNum] = useState(false)
  const [isAvailableProjNum, setIsAvailableProjNum] = useState(false)

  const updateAddFeatureButton = (action) => {
    if (action === 'disable') {
      setDisableAddFeatureButton(true)
    } else if (action === 'enable') {
      setDisableAddFeatureButton(false)
    }
  }

  const fillFeaturesArray = (data) => {
    let count =1
    let tempFeaturesArray = []
    while (data[`feature${count}`]) {
      const featureObject = {featureNum: count, featureText: data[`feature${count}`]}
      tempFeaturesArray = [...tempFeaturesArray, featureObject]
      count++
    }
    setFeaturesArray(tempFeaturesArray)
  }
  // console.log(featuresArray)

  function handleChange(evt) {
    const newFormData = {
        ...formData, // use the existing formData
        [evt.target.name]: evt.target.value, // override whatever key with the current field's value
        error: '' // clear any old errors as soon as the user interacts with the form
    };
    if (evt.target.name === 'projNum') {
      checkProjNum(evt)
    }
    fillFeaturesArray(newFormData)
    setFormData(newFormData)
  }

  const checkProjNum = async (evt) => {
    const projNum = evt.target.value
    // console.log(projNum)
    if (projNumReg.test(projNum)) {
      setIsValidProjNum(true)
      try {
        const project = await findProject(projNum)
        if (project) {
          setIsAvailableProjNum(false)
        } else {
          setIsAvailableProjNum(true)
        }
      } catch (err) {
        // console.log(err)
      }
    } else {
      setIsValidProjNum(false)
    }
  }

  const handleSubmit = async (evt) =>{
      evt.preventDefault();
      fillFeaturesArray(formData)
      try{
          // const { firstName, lastName, userName, userType, password, email } = formData;
          if (!isAvailableProjNum) {
            formNotificationStyle1(`projectCreationNotification`, `'${formData.projNum}' already exists!`, 'red', 'white', '0.9')
            return
          }else {
            const data = {
              user: user._id,
              projType: formData.projType,
              projNum: formData.projNum,
              projTitle: formData.projTitle,
              submissionDate: formData.submissionDate,
              company: formData.company,
              // featureQuantity: formData.featureQuantity,
              featureQuantity: featuresArray.length,
              features:featuresArray,
              analystName: `${user.firstName} ${user.lastName}`,
              analystId: user._id,
            }
            // console.log(data)
            const projects = await createProject(data)
            formNotificationStyle1(`projectCreationNotification`, `Successfully Created '${data.projTitle}'!`, 'white', 'green', '0.9')
            setFormData(defaultState)
            updateProjects(projects)
          }
      }catch (err) {
        formNotificationStyle1(`projectCreationNotification`, `Project Creation Failed - Try again!`, 'white', 'red', '0.9')
          setFormData({
              ...formData,
              error: 'Project Creation Failed - Try again!'
          })
      }
  }
  // console.log(formData)

  const addProjectFeature = (evt) => {
    const newFeaturesArray = [...featureNums, featureNums.length+1]
    setFeatureNums(newFeaturesArray)
    setDisableAddFeatureButton(true)
  }
  // console.log(featureNums)
  // console.log(featuresArray.length)

  const disabled = !projNumReg.test(formData.projNum) || !formData.projType || !formData.projTitle || !formData.company || !formData.submissionDate

  return (
    <div className="NewProjectForm modal-dialog">
      <div className="modal-content formContainer">
        <div className='header'>
          <div className='left'>
            <div className='first'>
              <svg width="35" viewBox="0 0 52 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_207_4032)">
                <rect x="2" y="1.86328" width="48" height="48" rx="10" fill="white"/>
                <rect x="2.5" y="2.36328" width="47" height="47" rx="9.5" stroke="#1A3860" strokeOpacity="0.08"/>
                </g>
                <path d="M19.808 27.9404C21.0091 27.9404 21.979 28.9106 21.979 30.1355V34.056C21.979 35.271 21.0076 36.25 19.808 36.25H15.921C14.731 36.25 13.75 35.269 13.75 34.056V30.1355C13.75 28.9125 14.7294 27.9404 15.921 27.9404H19.808ZM33.079 27.9404C34.2706 27.9404 35.25 28.9125 35.25 30.1355V34.056C35.25 35.269 34.269 36.25 33.079 36.25H29.192C27.9924 36.25 27.021 35.271 27.021 34.056V30.1355C27.021 28.9106 27.9909 27.9404 29.192 27.9404H33.079ZM19.808 14.75C21.0074 14.75 21.979 15.7288 21.979 16.9452V20.8656C21.979 22.0902 21.0095 23.0596 19.808 23.0596H15.921C14.729 23.0596 13.75 22.0883 13.75 20.8656V16.9452C13.75 15.7308 14.7311 14.75 15.921 14.75H19.808ZM33.079 14.75C34.2689 14.75 35.25 15.7308 35.25 16.9452V20.8656C35.25 22.0883 34.271 23.0596 33.079 23.0596H29.192C27.9905 23.0596 27.021 22.0902 27.021 20.8656V16.9452C27.021 15.7288 27.9926 14.75 29.192 14.75H33.079Z" fill="#344054" stroke="#344054" strokeWidth="1.5"/>
              </svg>
              &nbsp;&nbsp;
            </div>
            <div className='second'>
              <div className='sec1'>
                Create New Project
              </div>
              <div className='sec2 tertiaryText'>
                Let's create a project
              </div>
            </div>
          </div>
          <div className='right'>
            <svg data-bs-dismiss="modal" width="20" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="16.2666" cy="16.2666" r="15.7666" fill="#EDEFF2" stroke="#EEEEEE"/>
              <path d="M21.1093 12L12 21.1093M12 12L21.1093 21.1093" stroke="#1B2B41" strokeOpacity="0.8" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          
        </div>
        <form onSubmit={handleSubmit} id='newProjectForm'>
          <div className='formContent'>
            <div className='mb-2'>
              <label htmlFor="projType">Project Type</label>
              <select className='form-select' name="projType" id="projType" value={formData.projType} onChange={handleChange} required>
                <option value="" disabled>Select Project Type</option>
                { services&& services.sort((a, b) => a.name > b.name ? 1 : -1).map((service, idx) => (
                  <option key={idx} value={service.code}>{service.name}</option>
                ))
                }
              </select>
            </div>
            <div className='groupContainer1'>
              <div className='projectNumber'>
                <label htmlFor="projNum">Project Number</label>
                <input className='form-control' type="text" name="projNum" id="projNum" placeholder='Enter Project Number' value={formData.projNum} onChange={handleChange} required autoComplete='off'/>
                <div className='projectNumberStatus'>
                  <small className="form-text checkIsValidProjectNum" style={{backgroundColor:`${isValidProjNum? 'green' : 'red'}`, color:'white'}}>Format: XX-0000.00.000</small>
                  {isValidProjNum &&
                    <small className="form-text checkProjNum" style={{color:`${isAvailableProjNum? 'green' : 'red'}`}}>
                      <strong>
                        {isAvailableProjNum ? <span>{[<span key={0}>&#x2714;</span>]}</span> : 'Project number already exists!'}
                      </strong>
                    </small>
                  }
                </div>
              </div>
              <div className='submissionDate'>
                <label htmlFor="submissionDate">Submission Date</label>
                <input className='form-control' type="date" name="submissionDate" id="submissionDate" value={formData.submissionDate} onChange={handleChange} required autoComplete='off' />
              </div>
            </div>
            <div className='groupContainer2'>
              <div className='projTitle'>
                <label htmlFor="projTitle">Title</label>
                <input className='form-control' type="text" name="projTitle" id="projTitle" value={formData.projTitle} onChange={handleChange} required autoComplete='off'/>
              </div>
              <div className='company'>
                <label htmlFor="company">Company</label>
                <input className='form-control' type="text" name="company" id="company" value={formData.company} onChange={handleChange} required autoComplete='on'/>
              </div>
            </div>
            
            <div className='addElementContainer'>
              {
              featureNums.map((feature, idx) => (
                <NewProjectFeaturesFormItem featureNum={feature} key={idx} handleChange={handleChange} updateAddFeatureButton={updateAddFeatureButton} />
              ))
              }
              <div className='counterAdder'>
                <div>{`No of Elements: ${featuresArray.length}`}</div>
                <button type='button' onClick={addProjectFeature} className='btn btn-sm addElementButton' disabled={disableAddFeatureButton}>
                  <svg width="14" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.00016 7.44157H13.8827M7.44141 13.8828V1.00032" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                  <span>
                    &nbsp;Add Element
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className='d-flex justify-content-center formButtonContainer'>
          <button data-bs-dismiss="modal" className='cancel'>Cancel</button>
          <button data-bs-dismiss="modal" className="primary submit" form='newProjectForm' type="submit" disabled={disabled}>Create Project</button>
        </div>
        <p id='projectCreationNotification'className='projectCreationNotification text-center'></p>
      </div>
    </div>
  )
}
