import { useRef, useState } from 'react'
import PriorArtsReferenceDocument from '../PriorArtsReferenceDocument/PriorArtsReferenceDocument'
import './PriorArtsReference.css'
import { rankProjectReferences } from '../../utilities/api/projects'

export default function PriorArtsReference({documents, project, elements, updateProject}) {
  const [loadingNewRanking, setLoadingNewRanking] = useState(false)

  const draggedPerson = useRef(null)

  const draggedOverPerson = useRef(null)
  
  const handleSort = async () => {
    const draggedPersonIndex = draggedPerson.current
    const draggedOverPersonIndex = draggedOverPerson.current
    const updatedDocuments = [...documents]
    const temp = updatedDocuments[draggedPersonIndex]
    updatedDocuments[draggedPersonIndex] = updatedDocuments[draggedOverPersonIndex]
    updatedDocuments[draggedOverPersonIndex] = temp
    setLoadingNewRanking(true)
    try {
      const updatedProject = await rankProjectReferences(project._id, updatedDocuments)
      updateProject(updatedProject.projNum)
    } catch (error) {
      console.log(error)
    }
    setLoadingNewRanking(false)
  }

  // console.log(documents)
  return (
    <div className='PriorArtsReference'>
      {project &&
      <>
      <div className='header'>
      { project.projType === 'FTOA' &&
        <span>Freedom to Operate Analysis Table</span>
      }
      { project.projType !== 'FTOA' &&
        <span>Prior Arts Reference Table</span>
      }
      </div>
      <div className='tableContainer'>
        <table className='table'>
          <thead>
            <tr>
              <th scope='col' className='serialNumber'>Ref no</th>
              <th scope='col' className='documentNumber'>Reference</th>
              <th scope='col' className='publicationDate'>Publication date</th>
              {elements.map((element, idx) =>(
                <th scope='col' className='element' key={idx}>{`EL ${element}`}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            { documents &&
              documents.map((document, idx) => (
                <tr className='priorArtsReferenceDocument'
                key={idx}
                draggable={!loadingNewRanking && project.status!=='completed'}
                onDragStart={() => draggedPerson.current = idx}
                onDragEnter={() => draggedOverPerson.current = idx}
                onDragEnd={handleSort}
                onDragOver={(evt) => evt.preventDefault()}>
                  <PriorArtsReferenceDocument documents={documents} document={document} number={idx+1} elements={elements}/>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
      </>
      }
    </div>
  )
}