import './ClaimItem.css'

export default function ClaimItem({claim, currentProject, index, publicationNumber}) {

  return (
    <>
    <p className='' id={`claimItem${index+1}`}>{claim}</p>
    </>
  )
}
