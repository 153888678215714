import './ProjectAnalysis.css'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getProject, getProjectDocuments } from '../../utilities/api/projects'
import { analyzeProjectUnfiltered, analyzeProjectSummary } from '../../utilities/services/projectAnalysis'
import ProjectAnalysisSummary from '../../components/ProjectAnalysisSummary/ProjectAnalysisSummary'
import ProjectAnalysisUnfiltered from '../../components/ProjectAnalysisUnfiltered/ProjectAnalysisUnfiltered'

export default function ProjectAnalysis({user}) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const projectParams = useParams()
    const [project, setProject] = useState('')
    const [documents, setDocuments] = useState()
    const [elements, setElements]= useState([])
    const [projectAnalysisSummary, setProjectAnalysisSummary] = useState({})
    const [projectAnalysisUnfiltered, setProjectAnalysisUnfiltered] = useState({})
    const [showUnfilteredAnalysis, setShowUnfilteredAnalysis] = useState(false)
    const [analysisStatus, setAnalysisStatus] = useState('not initiated')

    const updateProject = async () => {
        try {
          const projectData = await getProject(projectParams.projectNumber)
          const documentFeatures = await getProjectDocuments(projectParams.projectNumber)
          // console.log(projectData)
          // console.log(documentFeatures)
          if (projectData) {
            if (user.userType === 'admin' || user.userType === 'manager') {
              setProject(projectData)
              setDocuments(documentFeatures)
            } else if (user.userType === 'analyst') {
              if (user._id === projectData.analystId) {
                setProject(projectData)
                setDocuments(documentFeatures)
              }else{
                navigate('/projects')
                window.location.reload()
              }
            } else if (user.userType === 'client') {
              if (user.company === projectData.company) {
                setProject(projectData)
                setDocuments(documentFeatures)
              } else {
                navigate('/projects')
                window.location.reload()
              }
            }
            setLoading(false)
          } else {
            navigate('/projects')
            window.location.reload()
          }
        } catch (error) {
          navigate('/projects')
        }
    }

    const countProjectElements = (project) => {
        setElements([])
        const elementsList = []
        for (let index = 0; index < project.featureQuantity; index++) {
          // console.log(index+1)
          elementsList.push((index+1).toString())
        }
        setElements(elementsList)
    }

    const toggleUnfilteredAnalysis = () => {
        setShowUnfilteredAnalysis(!showUnfilteredAnalysis)
    }
  
    const analyzeProject = async () => {
        if (documents.length === 0 || !project) {
            return alert('Please add documents to the project before analyzing.')
        }
        setAnalysisStatus('initiated')
        const analysisSummary = await analyzeProjectSummary(documents, project)
        setProjectAnalysisSummary(analysisSummary)
        const analysisUnfiltered = await analyzeProjectUnfiltered(documents, project)
        setProjectAnalysisUnfiltered(analysisUnfiltered)
        setAnalysisStatus('complete')
    }

    useEffect(() => {
        updateProject()
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        if (documents && project) {
            countProjectElements(project)
            analyzeProject()
        }
        // eslint-disable-next-line
    },[documents, project])

    return (
        <div className='ProjectAnalysis'>
          {loading &&
            <div className='loading'>
              <div className="spinner-border" role="status"></div>
            </div>
          }
          { (!loading && user && project && project.projType==='IPGAPS') &&
          <>
            <div className='projectHeader'>
              <span className='projectHeaderImage'>
                <img src={require('./Analysis.png')} alt="not found" width={40} />
              </span>
              <span>
                Analysis of "{project.projNum}"
              </span>
            </div>
            <div className='analysisContainer'>
              { analysisStatus === 'initiated' &&
              <div className='text-center'>
                <div className="spinner-border text-primary" role="status"></div>
              </div>
              }
              { analysisStatus === 'complete' &&
              <div>
                <ProjectAnalysisSummary projectAnalysisSummary={projectAnalysisSummary} elements={elements} />
                <div className='toggleRefCombinationsContainer'>
                  <button className='toggleRefCombinations' onClick={toggleUnfilteredAnalysis}>{showUnfilteredAnalysis? `Hide Combinations`: `Click to View More`}</button>
                </div>
                { showUnfilteredAnalysis &&
                <ProjectAnalysisUnfiltered projectAnalysisUnfiltered={projectAnalysisUnfiltered} elements={elements} />
                }
              </div>
              }
            </div>
          </>
          }
        </div>
    )
}
