import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../utilities/services/capitalizeFirstLetter';
import './ProjectCommentsItem.css'

export default function ProjectCommentsItem({comment}) {
    const [commentDate, setCommentDate] = useState('')

    function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }

    useEffect(() => {
        setCommentDate(convertTZ(comment.createdAt, 'America/Toronto').toLocaleString())
    }, [comment.createdAt])

  return (
    <div className='ProjectCommentsItem'>
        <div className='avatar'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                <path fillRule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
            </svg>
        </div>
        <div className='commentDetails'>
            <div className='person bodyText'>{capitalizeFirstLetter(comment.firstName)} {capitalizeFirstLetter(comment.lastName)}</div>
            <div className='commentText secondaryText'>{comment.commentText}</div>
            <div className='commentDate tertiaryText'><small>{commentDate}</small></div>
        </div>
    </div>
  )
}
