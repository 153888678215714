import './HelpList.css'

export default function HelpList() {
    const toggleSection = (evt) => {
        // grab id of parent of clicked element
        const section = evt.target.parentElement.id
        // console.log(section)
        document.getElementById(section).classList.toggle('show')
        document.getElementById(section).firstChild.classList.toggle('show')
        document.getElementById(section).lastChild.classList.toggle('show')
    }

  return (
    <div className='HelpList'>
        <div className='helpHeader1'>
            Frequently asked questions (FAQs)
        </div>
        <div className='helpHeader2'>
            (Click question to show/hide answer)
        </div>
        <div className='contentContainer'>
            <div className='section' id='helpSection1'>
                <div className='sectionHeading' onClick={toggleSection}>1. How can I load Patent Literature on PatAnalysis?</div>
                <div className='sectionContent'>
                    <div>To search and load Patent Literature on PatAnalysis;
                        <ul>
                            <li>Navigate to the Home page</li>
                            <li>Locate the Document Search Bar at the center of the home menu</li>
                            <li>Enter the document number into the search bar</li>
                            <li>Click on the 'Submit' button</li>
                        </ul>
                    </div>
                    <div>
                        Things to note:
                        <ul>
                            <li>The Document Search form is optimized to deal with empty spaces before and after the publication number in the input field</li>
                            <li>The Document Search form is <strong>not</strong> case sensitive</li>
                            <li>The Document Search form has validations to ensure an acceptable pattern of patent publication number is entered into the field.
                                <ul>
                                    <li>The form cannot be submitted unless a valid pattern is entered.</li>
                                </ul>
                            </li>
                            <li>In situations where the document number submitted cannot be found;
                                <ul>
                                    <li>If there is no active project selected, an error message is displayed.</li>
                                    <li>If there is an active project selected, the "Manual Annotation Form" is rendered.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='section' id='helpSection2'>
                <div className='sectionHeading' onClick={toggleSection}>2. How can I load Non-Patent Literature on PatAnalysis?</div>
                <div className='sectionContent'>
                    <div>To search and load Non-Patent Literature on PatAnalysis;
                        <ul>
                            <li>Navigate to the Home page</li>
                            <li>Locate the Document Search Bar at the center of the home menu</li>
                            <li>Enter the url of the webpage or pdf into the search bar</li>
                            <li>Click on the 'Submit' button</li>
                        </ul>
                    </div>
                    <div>
                        Things to note:
                        <ul>
                            <li>The data rendered after a successful search is without most of the original styling from the source URL.</li>
                            <li>From a successful search, there could be a lot of noise (irrelevant information) rendered onto the page.
                                <ul>
                                    <li>It is important to weed through this noise and find the relevant information.</li>
                                </ul>
                            </li>
                            <li>Click events on links on the page are disabled, to prevent accidental redirects and errors.</li>
                            <li>In situations where the Non-Patent Literature cannot be loaded;
                                <ul>
                                    <li>If there is no active project selected, an error message is displayed.</li>
                                    <li>If there is an active project selected, the "Manual Annotation Form" is rendered.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='section' id='helpSection3'>
                <div className='sectionHeading' onClick={toggleSection}>3. How can I load data via the PatAnalysis Chrome Extension?</div>
                <div className='sectionContent'>
                    <div><strong>To install the PatAnalysis Chrome Extension, please contact an administrator.</strong></div>
                    <div>After installation, to use the extension for <strong>Patent Literature</strong>;</div>
                    <ul>
                        <li>Highlight a valid publication number on any webpage</li>
                        <li>Right-click on the highlighted text</li>
                        <li>Click on the <strong>"Load &lt;<i>highlighted publication Number</i>&gt; on PatAnalysis"</strong> option from the menu</li>
                    </ul>
                    <div>For <strong>Non-Patent Literature</strong> including PDFs;</div>
                    <ul>
                        <li>Highlight the URL of the webpage or pdf</li>
                        <li>Right-click on the highlighted text</li>
                        <li>Click on the <strong>"Load &lt;<i>highlighted URL</i>&gt; on PatAnalysis"</strong> option from the menu</li>
                    </ul>
                    <div>
                    Once the above steps have been completed, the data is loaded on PatAnalysis, in a new tab. 
                    </div>
                    <br />
                    <div>Things to note: </div>
                    <ul>
                        <li>A successful search renders the same data that would be expected if the Document Search Bar was used.</li>
                        <li>There is no form validation on the chrome extension.
                            <ul>
                                <li>It is important to ensure that the highlighted text is a valid publication number or URL.</li>
                                <li>To prevent avoidable errors, ensure the publication number or URL is highlighted without any extra spaces or punctuation.</li>
                            </ul>
                        </li>
                        <li>In situations where the document cannot be loaded;
                            <ul>
                                <li>If there is no active project selected, an error message is displayed.</li>
                                <li>If there is an active project selected, the "Manual Annotation Form" is rendered.</li>
                            </ul>
                        </li>
                        <li><strong>If an error occurs while using the Chrome Extension for Non-Patent Literature, please attempt a manual copy and paste of the link address into the PatAnalysis Document Search Bar, before resorting to the Manual Annotation form(to override the document).</strong></li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection4'>
                <div className='sectionHeading' onClick={toggleSection}>4. How Can I open the Image Gallery for Patent Literature?</div>
                <div className='sectionContent'>
                    <div>To open the Image Gallery once a Patent Literature is rendered, click on any of the image thumbnails on the document.</div>
                </div>
            </div>
            <div className='section' id='helpSection5'>
                <div className='sectionHeading' onClick={toggleSection}>5. How Can I open the original document of a Patent Literature?</div>
                <div className='sectionContent'>
                    <div>To view the original document of a Patent Literature, click on the "Published Document" button located on the top left corner of the rendered document.</div>
                </div>
            </div>
            <div className='section' id='helpSection6'>
                <div className='sectionHeading' onClick={toggleSection}>6. How can I create a new project?</div>
                <div className='sectionContent'>
                    <div>To create a new project on PatAnalysis;</div>
                    <ul>
                        <li>Navigate to the Home or Project page</li>
                        <li>Click on the project creation button
                            <ul>
                                <li>On the Home page, the "Create New Project" button is located on the upper left corner</li>
                                <li>On the Project page, the "Create New" button is located on the upper right corner</li>
                            </ul>
                        </li>
                        <li>Fill in the required fields on the form that pops up on the page</li>
                        <li>To add elements/features to the project, click on the ‘Add Element’ button on the form.
                            <ul>
                                <li>Another element can be added once the field that pops up has been filled</li>
                            </ul>
                        </li>
                        <li>Click on the "Create Project" button at the bottom of the form</li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>The Project Number has to follow the format “RP-0000.00.000”.
                            <ul>
                                <li>If this format is not satisfied, the ‘Create Project’ button would remain disabled.</li>
                            </ul>
                        </li>
                        <li>The Project Number has to be unique.
                            <ul>
                                <li>If the Project Number is not unique, the ‘Create Project’ button would remain disabled and an error would be displayed.</li>
                            </ul>
                        </li>
                        <li>After Adding an element/feature to the project by clicking on the ‘Add Element’ Button, the button becomes disabled until some input is added to the field generated.
                            <ul>
                                <li>Once the field is filled, the button becomes enabled again.</li>
                            </ul>
                        </li>
                        <li>To remove an element before submitting the form, simply clear out that field.
                            <ul>
                                <li>The input field would not be removed, but the element would be invalid, so it would not be stored.</li>
                            </ul>
                        </li>
                        <li>When adding or modifying elements before submitting the form, never leave an empty field above an element, as this would lead to errors.
                            <ul>
                                <li>Elements after the empty field would not be stored.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection7'>
                <div className='sectionHeading' onClick={toggleSection}>7. How can I update a project?</div>
                <div className='sectionContent'>
                    <div>To update a project on PatAnalysis;</div>
                    <ul>
                        <li>Navigate to the Project page</li>
                        <li>Locate the table row of the project you wish to update</li>
                        <li>Click on the Edit button located in the 'Actions' section of the project row</li>
                        <li>In the pop up form, make the necessary changes to the project</li>
                        <li>Click on the "Update Project" button at the bottom of the form to save changes</li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>The project number cannot be modified
                            <ul>
                                <li>You can only modify the Title, Company and Submission Date</li>
                            </ul>
                        </li>
                        <li>Elements can only be added (removal is not permitted)
                            <ul>
                                <li>The same rules that apply when adding elements on project creation must be followed when updating the project</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection8'>
                <div className='sectionHeading' onClick={toggleSection}>8. How can I load a project on the home page?</div>
                <div className='sectionContent'>
                    <div>To load a project on the home page;</div>
                    <ul>
                        <li>Navigate to the Home page</li>
                        <li>Locate the Project Search Dropdown button below the menu bar</li>
                        <li>Once the button is clicked, a dropdown containing a list of all authorized projects is loaded</li>
                        <li>Click on the desired project</li>
                        <li>The project is then loaded onto the home page</li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>Only authorized projects are loaded onto the dropdown</li>
                        <li>A project can also be loaded by clicking on a project tab on the Home page</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection9'>
                <div className='sectionHeading' onClick={toggleSection}>9. How can I create text annotations?</div>
                <div className='sectionContent'>
                    <div>To create a text annotation, two things are required;</div>
                    <ul>
                        <li>An active project that has not been marked as "completed"</li>
                        <li>A Patent or Non-Patent Literature loaded on the Home page</li>
                    </ul>
                    <div>Once the above requirements are met;</div>
                    <ul>
                        <li>Highlight the text that needs to be annotated on the rendered document</li>
                        <li>From the tab containing the project element buttons located right above the rendered document, select the element/feature that the annotation is to be created for</li>
                        <li>From the dropdown that appears on the page, you can preview the element/feature and also the highlighted text</li>
                        <li>After confirming the correct element/feature and text highlight;
                            <ul>
                                <li>For Patent Literature:
                                    <ul>
                                        <li>From the options, select if the highlighted text is from the Abstract, Description or Claims section of the document</li>
                                        <li>Enter the description paragraph or claim number into the required field (a number is not required for the abstract section)</li>
                                        <li>Click the ‘Create Annotation’ button to create the text annotation.</li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li>For Non-Patent Literature:
                                    <ul>
                                        <li>Enter the Publication Date and Title of the document</li>
                                        <li>Click the ‘Create Annotation’ button to create the text annotation.</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>Annotations cannot be added to projects marked as "completed"</li>
                        <li>For patent documents, do not include paragraph numbers when highlighting text to be annotated.</li>
                        <li>After creating text annotations on a project element, anytime that project and annotated document are active;
                            <ul>
                                <li>The annotations created are highlighted in yellow on the document so they can be easily traced.</li>
                                <li>When an annotation (highlighted in yellow) is hovered over, the elements/features it is related to on the current project, are mentioned in a bubble just above the highlight.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection10'>
                <div className='sectionHeading' onClick={toggleSection}>10. How can I create image annotations for Patent Literature?</div>
                <div className='sectionContent'>
                    <div>To create an image annotation on a Patent Literature, two things are required;</div>
                    <ul>
                        <li>An active project that has not been marked as "completed"</li>
                        <li>A Patent Literature loaded on the Home page</li>
                    </ul>
                    <div>Once the above requirements are met;</div>
                    <ul>
                        <li>If the Patent Literature has associated images, the thumbnails would be loaded in the "Images" section of the rendered document</li>
                        <li>Click on the image thumbnail that the annotation is to be created for. This would open the image in the Image Gallery</li>
                        <li>At the top of the Image Gallery, click the "Create Image Annotation" button</li>
                        <li>From the pop-up that appears, select the project element/feature the annotaion is to be created for</li>
                        <li>Click the "Create Image Annotation" button</li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>Once the image annotation has been created, the associated element can be seen on the gallery</li>
                        <li>The annotated image thumbnail is given a yellow border, so it can be easily identified.</li>
                        <li>When an annotated image thumbnail is hovered over, the elements/features it is related to on the current project, are mentioned in a bubble just above the thumbnail.</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection11'>
                <div className='sectionHeading' onClick={toggleSection}>11. How can I view what project elements/features an annnotation belongs to on a document?</div>
                <div className='sectionContent'>
                    <div>To view what project elements/features an annotation belongs to on a document;</div>
                    <ul>
                        <li>Hover over the annotation on the document</li>
                        <li>The elements/features it is related to on the current project, are mentioned in a bubble just above the highlight</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection12'>
                <div className='sectionHeading' onClick={toggleSection}>12. How can I create manual text annotations?</div>
                <div className='sectionContent'>
                    <div>When there is an active project, if a Patent or Non-Patent Literature search is made and an error occurs while attempting to fetch the data, the Manual Annotation form is rendered</div>
                    <div>On the Manual Annotation form;</div>
                    <ul>
                        <li>Select the document classification (patent, non-patent or PDF)</li>
                        <li>Carefully enter the document title and publication date</li>
                        <li>For each element found in the actual document (that failed to load), manually copy and paste the relevant text into appropriate element/feature input field</li>
                        <li>Once complete, click the "Save Annotations" button to save the document information and text annotations</li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>When creating manual annotations, the publication date and title cannot be modified after the first save</li>
                        <li>Manual annotations can only be created on projects that have not been marked as "completed"</li>
                        <li>Annotations can be updated and saved directly from the form, but saved fields cannot be cleared/deleted
                            <ul>
                                <li>Deletion of an annotation has to be done on the Project Details page.</li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection13'>
                <div className='sectionHeading' onClick={toggleSection}>13. How can I override literature rendered on PatAnalysis in order to create text annotations?</div>
                <div className='sectionContent'>
                    <div>There may be situations were literature rendered on PatAnalysis is not easy to read, or is incomplete.</div>
                    <div>In such cases, the Manual Annotation form can be used to override the rendered document and create text annotations.</div>
                    <br />
                    <div>To access the Manual Annotation form;</div>
                    <ul>
                        <li>Locate the "Override Document" button at the top right corner of the rendered document</li>
                        <li>Click on the button to bypass the document and open the form</li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>When creating manual annotations, the publication date and title cannot be modified after the first save</li>
                        <li>Manual annotations can only be created on projects that have not been marked as "completed"</li>
                        <li>Annotations can be updated and saved directly from the form, but saved fields cannot be cleared/deleted
                            <ul>
                                <li>Deletion of an annotation has to be done on the Project Details page.</li>
                            </ul>
                        </li>
                        <li><strong>Any annotations created prior to bypassing the rendered document will be replaced by the content of the saved Manual Annotation form</strong></li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection14'>
                <div className='sectionHeading' onClick={toggleSection}>14. How can I search for keywords on a document rendered?</div>
                <div className='sectionContent'>
                    <div>To search for keywords on a document rendered on PatAnalysis;</div>
                    <ul>
                        <li>Locate and select the Keyword Highlighter tool at the top right corner of the Menu Bar</li>
                        <li>In the pop-up window that appears, enter the desired keywords</li>
                        <li>Click the "Search Keywords" button to make a search on the loaded document</li>
                        <li>Words found in the document would be highlighted in different colors, based on the search input field used</li>
                        <br />
                        <li>To clear all keywords in the Keyword Highlighter, click on the reset button</li>
                    </ul>
                    <br />
                    <div>This feature has some special wildcards to make searches more efficient. They include: </div>
                    <ul>
                        <li>","
                            <ul>
                                <li>This is used when a list of comma-separated words needs to be searched</li>
                                <li>It is used especially for synonyms of a word</li>
                                <li>All matches would be highlighted with the same color</li>
                                <li>An example would be; 
                                    <ul>
                                        <li>Modify, Change, alter</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>"?"
                            <ul>
                                <li>This wildcard is used for words that could have multiple valid variations</li>
                                <li>An example would be a word like “to-do”, which could correctly be written or translated as “to do” or “todo”</li>
                                <li>To properly use this wildcard, use the space or hyphen separated version of the word (“to-do”, or “to do”) to capture all three cases</li>
                            </ul>
                        </li>
                        <li>"*"
                            <ul>
                                <li>This wildcard is mainly used when we need all the “completions” of a particular word</li>
                                <li>For example, typing “complet*” into a search box would account for “complete”, “completed”, “completing”, “completion”, etc</li>
                                <li>The matched words would be highlighted in the same color</li>
                            </ul>
                        </li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>The Keyword Highlighter tool is only shown when there is a document loaded on the page</li>
                        <li>For now, a maximum of 8 keyword input fields are available for use</li>
                        <li>The feature is NOT case sensitive</li>
                        <li>Any symbols except the listed wildcards and “-” could lead to bad search results</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection15'>
                <div className='sectionHeading' onClick={toggleSection}>15. How can I refresh a project on the homepage without losing all other rendered information?</div>
                <div className='sectionContent'>
                    <div>To refresh a project on the homepage without losing all other rendered information;</div>
                    <ul>
                        <li>Click the refresh icon located in the container just underneath the Project Search dropdown</li>
                        <li>The project is then updated with the most current information</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection16'>
                <div className='sectionHeading' onClick={toggleSection}>16. How can I view a summary of a project on the home page?</div>
                <div className='sectionContent'>
                    <div>To view a summary of a project on the home page;</div>
                    <ul>
                        <li>With the required project loaded on Home page, locate the Project Information section on the left side of the page</li>
                        <li>Click on the Project Number located just above the list of references</li>
                        <li>This reveals a pop-up containing a summary of the project.</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection17'>
                <div className='sectionHeading' onClick={toggleSection}>17. How can I view details of a project?</div>
                <div className='sectionContent'>
                    <div>To view all details of a project;</div>
                    <ul>
                        <li>Navigate to the Project page</li>
                        <li>From the list of projects on the page, click on the project number of the desired project</li>
                        <li>A new page is loaded with all details of the selected project</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection18'>
                <div className='sectionHeading' onClick={toggleSection}>18. How can I leave comments on a project for other users to view?</div>
                <div className='sectionContent'>
                    <div>To leave comments on a project for other users to view;</div>
                    <ul>
                        <li>Navigate to the Project page</li>
                        <li>From the list of projects on the page, click on the project number of the desired project</li>
                        <li>A new page is loaded with all details of the selected project</li>
                        <li>scroll to locate the Comments section on the page</li>
                        <li>Enter the comment into the input field</li>
                        <li>Click the "Add Comment" button to post the comment</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection19'>
                <div className='sectionHeading' onClick={toggleSection}>19. How can I create notes directly on a project reference?</div>
                <div className='sectionContent'>
                    <div>To create notes directly on a project reference;</div>
                    <ul>
                        <li>Navigate to the Project page</li>
                        <li>From the list of projects on the page, click on the project number of the desired project</li>
                        <li>A new page is loaded with all details of the selected project</li>
                        <li>Locate the reference you wish to add a note to</li>
                        <li>Click on the "Add Notes" button located on the right side of the reference row</li>
                        <li>Enter the note into the input field</li>
                        <li>Click the "Save" button to save the note</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection20'>
                <div className='sectionHeading' onClick={toggleSection}>20. How can I complete the search strategy section of a project?</div>
                <div className='sectionContent'>
                    <div>To complete the search strategy section of a project;</div>
                    <ul>
                        <li>Navigate to the Project page</li>
                        <li>From the list of projects on the page, click on the project number of the desired project</li>
                        <li>A new page is loaded with all details of the selected project</li>
                        <li>Locate and click the Search Strategy button on the page</li>
                        <li>The Search Strategy page of the project is loaded</li>
                        <li>Fill in the required fields on the page</li>
                        <li>Click on the "Save Search Strategy" button at the top of the page to save the information</li>
                    </ul>
                    <br />
                    <div>To upload the Search History file;</div>
                    <ul>
                        <li>Locate and click the "Upload Search History" container on the Search Strategy page</li>
                        <li>Select the file from your computer</li>
                        <li>Click the "Submit" button to save the file</li>
                    </ul>
                    <br />
                    <div>To upload the Patent List file;</div>
                    <ul>
                        <li>Locate and click the "Upload Patent List" container on the Search Strategy page</li>
                        <li>Input the number of Patent Families into the required field</li>
                        <li>Select the file from your computer</li>
                        <li>Click the "Submit" button to save the file</li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>A project cannot be submitted for review until the Search Strategy is complete</li>
                        <li>For the "Google Patents Search History", "Google Scholar Search History", "IPC Classification" and "CPC Classification";
                            <ul>
                                <li>The first three fields of each of these sections needs to be filled for the Search Strategy to be considered complete</li>
                            </ul>
                        </li>
                        <li>The Search History file and Patent List file need to be uploaded for the Search Strategy to be considered complete</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection21'>
                <div className='sectionHeading' onClick={toggleSection}>21. How can I submit a project for review?</div>
                <div className='sectionContent'>
                    <div>To submit a project for review;</div>
                    <ul>
                        <li>Navigate to the Project page</li>
                        <li>From the list of projects on the page, click on the project number of the desired project</li>
                        <li>A new page is loaded with all details of the selected project</li>
                        <li>Locate and click the "Submit Project for Review" button on the page</li>
                        <li>A confirmation pop-up appears, click the "Submit" button to confirm</li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>A project cannot be submitted until the search strategy is complete</li>
                        <li>Once a project is submitted for review, it cannot be updated</li>
                        <li>Submitted projects can only be updated by an administrator</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection22'>
                <div className='sectionHeading' onClick={toggleSection}>22. How can I generate a final document for a project once complete?</div>
                <div className='sectionContent'>
                    <div>To generate a final document for a project once it is complete;</div>
                    <ul>
                        <li>Navigate to the Project page</li>
                        <li>From the list of projects on the page, click on the project number of the desired project</li>
                        <li>A new page is loaded with all details of the selected project</li>
                        <li>Locate and click the "Download Final Report" button on the page</li>
                        <li>The final document is then generated and downloaded to your computer</li>
                    </ul>
                    <br />
                    <div>Things to note:</div>
                    <ul>
                        <li>Reports can only be generated for completed projects</li>
                        <li>Only administrators have access to this feature</li>
                    </ul>
                </div>
            </div>
            <div className='section' id='helpSection23'>
                <div className='sectionHeading' onClick={toggleSection}>23. How can I reorder project references?</div>
                <div className='sectionContent'>
                    <div>To reorder project references;</div>
                    <ul>
                        <li>Navigate to the Project page</li>
                        <li>From the list of projects on the page, click on the project number of the desired project</li>
                        <li>A new page is loaded with all details of the selected project</li>
                        <li>Scroll to locate the Prior Arts Reference Table or Freedom to Operate Analysis Table</li>
                        <li>Click and drag references to the desired position/rank</li>
                        <li>Release the reference to save the new order</li>
                    </ul>
                    <br />
                    <div><strong>This feature can also be accessed on the Project Summary Section of the Home page. </strong><small>(See question 16)</small></div>
                </div>
            </div>
        </div>
    </div>
  )
}
