import './PatentData.css'
import Claims from '../Claims/Claims'
import Description from '../Description/Description'
import ImageThumbnails from '../ImageThumbnails/ImageThumbnails'
import Abstract from '../Abstract/Abstract'
import { useState } from 'react'

export default function PatentData({documentData, currentProject, projectDocuments, renderPdf, renderGallery, updateCurrentImage}) {
    const [showImages, setShowImages] = useState(true)
    const [showAbstract, setShowAbstract] = useState(true)
    const [showDescription, setShowDescription] = useState(true)
    const [showClaims, setShowClaims] = useState(true)

    const toggleImages = () => {
        setShowImages(!showImages)
    }

    const toggleAbstract = () => {
        setShowAbstract(!showAbstract)
    }

    const toggleDescription = () => {
        setShowDescription(!showDescription)
    }

    const toggleClaims = () => {
        setShowClaims(!showClaims)
    }
  
    return (
        <div className='PatentData'>
            <div className='text-center'>
                <h3 id='documentTitle'><strong>{documentData.title}</strong></h3>
            </div>
            <div className='documentMetadata'>
                {documentData.applicationNumber &&
                <div className='documentMetadataItem'>
                    <span className=''><strong>Application Number:&nbsp;&nbsp;</strong></span>
                    <span>{documentData.applicationNumber}</span>
                </div>
                }

                {documentData.publicationNumber &&
                <div className='documentMetadataItem'>
                    <span className=''><strong>Publication Number:&nbsp;&nbsp;</strong></span>
                    <span>{documentData.publicationNumber}</span>
                </div>
                }
                
                {documentData.dateSubmitted &&
                <div className='documentMetadataItem'>
                    <span className=''><strong>Date Submitted:&nbsp;&nbsp;</strong></span>
                    <span>{documentData.dateSubmitted}</span>
                </div>
                }
                
                {documentData.publicationDate &&
                <div className='documentMetadataItem'>
                    <span className=''><strong>Publication Date:&nbsp;&nbsp;</strong></span>
                    <span>{documentData.publicationDate}</span>
                </div>
                }
                
                {documentData.assigneeList &&
                <div className='documentMetadataItem'>
                    <span className=''><strong>Assignees:&nbsp;&nbsp;</strong></span>
                    <span>{documentData.assigneeList.toString()}</span>
                </div>
                }

                {documentData.inventorList &&
                <div className='documentMetadataItem'>
                    <span className=''><strong>Inventors:&nbsp;&nbsp;</strong></span>
                    <span>{documentData.inventorList.toString()}</span>
                </div>
                }                            
            </div>
            <div className='documentMainContent'>
                {documentData.imageThumbnails.length>0 &&
                <div className='documentMainContentContainer'>
                    <div className='documentSectionHeading' onClick={toggleImages}>
                        <div>
                            Images
                        </div>
                        {showImages ? 
                        <svg width="20"viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="45" height="45" rx="10" transform="matrix(0 -1 -1 0 45 45)" fill="white"/>
                            <path d="M16 20L23 27L30 20" stroke="#262626" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        :
                        <svg width="20" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="45" height="45" rx="10" transform="matrix(0 -1 -1 0 45 45)" fill="white"/>
                            <path d="M16 30L23 20L30 30" stroke="#262626" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        }
                    </div>
                    <div className={!showImages? 'hiddenContent':''}>
                        <ImageThumbnails images={documentData.imageThumbnails} fullImages={documentData.fullSizeImages} currentProject={currentProject} publicationNumber={documentData.publicationNumber} renderGallery={renderGallery} updateCurrentImage={updateCurrentImage}/>
                    </div>
                </div>
                }
                {documentData.abstract &&
                <div className='documentMainContentContainer'>
                    <div className='documentSectionHeading' onClick={toggleAbstract}>
                        <div>
                            Abstract
                        </div>
                        {showAbstract ? 
                        <svg width="20"viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="45" height="45" rx="10" transform="matrix(0 -1 -1 0 45 45)" fill="white"/>
                            <path d="M16 20L23 27L30 20" stroke="#262626" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        :
                        <svg width="20" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="45" height="45" rx="10" transform="matrix(0 -1 -1 0 45 45)" fill="white"/>
                            <path d="M16 30L23 20L30 30" stroke="#262626" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        }
                    </div>
                    <div className={!showAbstract? 'hiddenContent':''}>
                        <Abstract abstract={documentData.abstract} currentProject={currentProject} publicationNumber={documentData.publicationNumber} />
                    </div>
                </div>
                }
                {documentData.description &&
                <div className='documentMainContentContainer'>
                    <div className='documentSectionHeading' onClick={toggleDescription}>
                        <div>
                            Description
                        </div>
                        {showDescription ? 
                        <svg width="20"viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="45" height="45" rx="10" transform="matrix(0 -1 -1 0 45 45)" fill="white"/>
                            <path d="M16 20L23 27L30 20" stroke="#262626" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        :
                        <svg width="20" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="45" height="45" rx="10" transform="matrix(0 -1 -1 0 45 45)" fill="white"/>
                            <path d="M16 30L23 20L30 30" stroke="#262626" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        }
                    </div>
                    <div className={!showDescription? 'hiddenContent':''}>
                        <Description description={documentData.description} currentProject={currentProject} publicationNumber={documentData.publicationNumber} />
                    </div>
                </div>
                }
                {documentData.claims &&
                <div className='documentMainContentContainer'>
                    <div className='documentSectionHeading' onClick={toggleClaims}>
                        <div>
                            Claims
                        </div>
                        {showClaims ? 
                        <svg width="20"viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="45" height="45" rx="10" transform="matrix(0 -1 -1 0 45 45)" fill="white"/>
                            <path d="M16 20L23 27L30 20" stroke="#262626" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        :
                        <svg width="20" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="45" height="45" rx="10" transform="matrix(0 -1 -1 0 45 45)" fill="white"/>
                            <path d="M16 30L23 20L30 30" stroke="#262626" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        }
                    </div>
                    <div className={!showClaims? 'hiddenContent':''}>
                        <Claims claims={documentData.claims} currentProject={currentProject} publicationNumber={documentData.publicationNumber} />
                    </div>
                </div>
                }
                
            </div>
        </div>
    )
}
