import './Abstract.css'

export default function Abstract({abstract, currentProject, publicationNumber}) {

  return (
    <div className='Abstract'>
        <p className='' id={`abstract`}>{abstract}</p>
    </div>
  )
}
