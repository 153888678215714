import './ProjectStatusActions.css'
import { createEmailNotifications, getProjectDocuments, updateProjectStatus } from '../../utilities/api/projects'
import { useEffect, useState } from 'react';
import { generateReportTypeB } from '../../utilities/services/generateReportTypeB';
import { generateReportTypeA } from '../../utilities/services/generateReportTypeA';
const buff = require('buffer').Buffer

export default function ProjectStatusActions({user, project, updateProject, updateProjects}) {

    const [documents, setDocuments] = useState()
    const [documentImages, setDocumentImages] = useState([])
    const [loading, setLoading] = useState(true)

    const checkSearchStrategyCompletion = (project) => {
        return !(
            !project.CPC1 || !project.CPC2 || !project.CPC3
            || !project.IPC1 || !project.IPC2 || !project.IPC3
            || !project.patentSearchStep1 || !project.patentSearchStep2 || !project.patentSearchStep3
            || !project.scholarSearchStep1 || !project.scholarSearchStep2 || !project.scholarSearchStep3
            || project.orbitSearchHistoryURL === 'https://nulll.com' || project.patentListURL === 'https://nulll.com'
        );
    }

    const analystSubmitProjectForReview = async () => {
        if (checkSearchStrategyCompletion(project)) {
            const data = {
                user: user,
                projNum: project.projNum,
                analystId: project.analystId,
                status: 'inReview'
            }
            try {
                const updatedProjects = await updateProjectStatus(data)
                await updateProject(project.projNum)
                await createEmailNotifications(data)
                updateProjects(updatedProjects)
            } catch (error) {
                console.log(error)
            }
        } else {
            alert('Please complete the Search Strategy before submitting this project for review.')
        }
        
    }

    const adminProjectStatusUpdate = async (evt) => {
        const data = {
            user: user,
            projNum: project.projNum,
            status: document.getElementById('status').value
        }
        try {
            const updatedProjects = await updateProjectStatus(data)
            await updateProject(project.projNum)
            await createEmailNotifications(data)
            updateProjects(updatedProjects)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchImages = async (url) => {
        const data = {imageURL: url}
        const res = await fetch(`/api/projects/referenceImages`, {
            method:'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
        const buffer = await res.json()
        const newBuffer = buff.from(buffer)
        if(res.ok) return newBuffer
        throw new Error(res.statusText)
    }

    const preloadImages = async () => {
        setLoading(true)
        let images = []
        if (documents) {
            documents.forEach(document => {
                document.features.forEach(async feature => {
                    // console.log(feature)
                    if (feature.imageURL) {
                        const url = feature.imageURL
                        const imageData = await fetchImages(url)
                        // Rotate image if necessary
                        if (feature.rotate) {
                            // rotate image
                            const canvas = window.document.createElement('canvas')
                            const ctx = canvas.getContext('2d')
                            const img = new Image()
                            img.src = URL.createObjectURL(new Blob([imageData]))
                            img.onload = () => {
                                canvas.width = img.height
                                canvas.height = img.width
                                ctx.translate(canvas.width/2, canvas.height/2)
                                ctx.rotate(feature.rotate * Math.PI / 180)
                                ctx.drawImage(img, -img.width/2, -img.height/2)
                                const rotatedImage = canvas.toDataURL('image/jpeg')
                                const rotatedImageData = buff.from(rotatedImage.split(',')[1], 'base64')
                                const rotatedImageObject = {
                                    url: feature.imageURL,
                                    image: rotatedImageData
                                }
                                // console.log(rotatedImageObject)
                                images = [...images, rotatedImageObject]
                                setDocumentImages(images)
                            }
                        } else {
                            const imageObject = {
                                url: url,
                                image: imageData
                            }
                            images = [...images, imageObject]
                            setDocumentImages(images)
                        }
                    }
                })
            })
        }
        setLoading(false)
    }

    const getDocuments = async () => {
        try {
          const documentFeatures = await getProjectDocuments(project.projNum)
          setDocuments(documentFeatures)
            // console.log(documentFeatures)
        } catch (error) {
          console.log(error)
        }
    }

    const downloadReport = async () => {
        try {
            setLoading(true)
            if (project.projType === 'FTOA') {
                await generateReportTypeB({project, documents, documentImages})
            } else {
                await generateReportTypeA({project, documents, documentImages})
            }
            setLoading(false)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDocuments()
        // eslint-disable-next-line
    },[project])
    useEffect(() => {
        preloadImages()
        // eslint-disable-next-line
    },[documents])

  return (
    <div className='ProjectStatusActions'>
        { (user.userType === 'admin'||user.userType === 'manager') &&
        <>
        <div className='formContainer'>
            <form onChange={adminProjectStatusUpdate}>
                <div className=' mb-3'>
                    <label htmlFor='status' className='w-50 form-label'>Change Status:</label>
                    <select className='form-select w-100' name='status' id='status' defaultValue={project.status}>
                        <option value='inProgress'>In Progress</option>
                        <option value='inReview'>Under Review</option>
                        <option value='completed'>Completed</option>
                    </select>
                </div>
            </form>
            <div className="modal fade" id='submitProjectForReview' data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`submitProjectForReviewHeader`} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <svg width="84" viewBox="0 0 84 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="4.5" y="5" width="75" height="75" rx="37.5" fill="#158444"/>
                                <rect x="4.5" y="5" width="75" height="75" rx="37.5" stroke="#E8F3EC" strokeWidth="9"/>
                                <path d="M56 42.1743V43.5083C55.9982 46.6351 54.9857 49.6776 53.1135 52.1819C51.2413 54.6863 48.6098 56.5184 45.6113 57.4049C42.6128 58.2915 39.408 58.185 36.475 57.1014C33.5419 56.0178 31.0377 54.0151 29.3359 51.392C27.634 48.7689 26.8257 45.666 27.0314 42.546C27.2372 39.4259 28.4459 36.456 30.4775 34.0791C32.5091 31.7021 35.2545 30.0456 38.3045 29.3565C41.3544 28.6674 44.5454 28.9827 47.4015 30.2553M56 31.9083L41.5 46.4228L37.15 42.0728" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <div className="modal-title" id={`submitProjectForReviewHeader`}>
                                Submit {`${project.projNum}`}
                            </div>
                            <div className='modalProjTitle'>
                                {project.projTitle}
                            </div>
                        </div>
                        <div className="modal-body">
                            <p className='warningMessage'>Once submitted, you will not be able to make changes to this project.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="cancel" data-bs-dismiss="modal">Cancel</button>
                            <button onClick={analystSubmitProjectForReview} type="button" className="submit" data-bs-dismiss="modal">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        {   project.status === 'completed' &&
        <div className='generateFinalReport'>
            {/* <Link to={`/reportDownload/${project.projNum}`} target={'_blank'}> */}
            <button className='generateFinalReport' onClick={downloadReport} disabled={loading}>Download Final Report</button>
            {/* </Link> */}
        </div>
        }
        </>
        }
        { user.userType === 'analyst' &&
        <>
            {   project.status === 'inProgress' &&
            <div>
                <button className='submitForReview' data-bs-toggle="modal" data-bs-target={`#submitProjectForReview`}>
                    Submit Project for Review
                </button>
                <div className="modal fade" id='submitProjectForReview' data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby={`submitProjectForReviewHeader`} aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <svg width="84" viewBox="0 0 84 85" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="4.5" y="5" width="75" height="75" rx="37.5" fill="#158444"/>
                                    <rect x="4.5" y="5" width="75" height="75" rx="37.5" stroke="#E8F3EC" strokeWidth="9"/>
                                    <path d="M56 42.1743V43.5083C55.9982 46.6351 54.9857 49.6776 53.1135 52.1819C51.2413 54.6863 48.6098 56.5184 45.6113 57.4049C42.6128 58.2915 39.408 58.185 36.475 57.1014C33.5419 56.0178 31.0377 54.0151 29.3359 51.392C27.634 48.7689 26.8257 45.666 27.0314 42.546C27.2372 39.4259 28.4459 36.456 30.4775 34.0791C32.5091 31.7021 35.2545 30.0456 38.3045 29.3565C41.3544 28.6674 44.5454 28.9827 47.4015 30.2553M56 31.9083L41.5 46.4228L37.15 42.0728" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <div className="modal-title" id={`submitProjectForReviewHeader`}>
                                    Submit {`${project.projNum}`}
                                </div>
                                <div className='modalProjTitle'>
                                    {project.projTitle}
                                </div>
                            </div>
                            <div className="modal-body">
                                <p className='warningMessage'>Once submitted, you will not be able to make changes to this project.</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="cancel" data-bs-dismiss="modal">Cancel</button>
                                <button onClick={analystSubmitProjectForReview} type="button" className="submit" data-bs-dismiss="modal">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
            {   project.status === 'inReview' &&
            <div title='Project has been submitted for review' className='text-center'>
                <button className='submittedForReview' disabled>Project Submitted</button>
            </div>
            }
        </>
        }
    </div>
  )
}
