import { useState } from 'react'
import './UpdatePasswordForm.css'
import { updatePassword } from '../../utilities/api/users'
import { formNotificationStyle2 } from '../../utilities/services/formNotification'

export default function UpdatePasswordForm({user}) {
    const [formData, setFormData] = useState({
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
    })

    const handleSubmit = async (evt) => {
        evt.preventDefault()
        const data = {
            user: user,
            currentPassword: formData.currentPassword,
            newPassword: formData.newPassword,
            confirmPassword: formData.confirmPassword
        }
        try {
            await updatePassword(data)
            setFormData({
                currentPassword: '',
                newPassword: '',
                confirmPassword: '',
            })
            formNotificationStyle2('passwordUpdateNotification', 'Password Updated!', '#0A7637', 1)
        } catch (error) {
            formNotificationStyle2('passwordUpdateNotification', `${error.message}`, '#CA150C', 1)
        }
    }

    const handleChange = (evt) => {
        const newFormData = {
            ...formData,
            [evt.target.name]: evt.target.value,
            error: ''
        };
        setFormData(newFormData)
    }

    const disabled = formData.currentPassword === '' || formData.newPassword === '' || formData.newPassword.length < 5 || formData.confirmPassword === '' || formData.newPassword !== formData.confirmPassword
  return (
    <div className='UpdatePasswordForm'>
        <div className="w-50 mx-auto formContainer" >
          <form onSubmit={handleSubmit}>
            <div className='formContent'>
              <div className='mb-3'>
                <label htmlFor="currentPassword">Current Password</label>
                <input className='form-control me-2 ' type="password" name="currentPassword" id="currentPassword" value={formData.currentPassword} onChange={handleChange} required autoComplete='off'/>
              </div>

              <div className=''>
                <label htmlFor="newPassword">New Password</label>
                <input className='form-control me-2 ' type="password" name="newPassword" id="newPassword" value={formData.newPassword} onChange={handleChange} required autoComplete='off'/>
              </div>
              { (formData.newPassword && formData.newPassword.length < 5) && 
              <small className="errorText">&nbsp;Must be at least 5 characters long!</small>
              }

              <div className='mt-3'>
                <label htmlFor="confirmPassword">Confirm Password</label>
                <input className='form-control me-2 ' type="password" name="confirmPassword" id="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required autoComplete='off' />
              </div>
              { (formData.newPassword && formData.confirmPassword && (formData.newPassword !== formData.confirmPassword)) && 
              <small className="errorText">&nbsp;Passwords do not match!</small>
              }
            </div>
            <div className='d-flex justify-content-center formSubmitButton mt-3'>
              <button className="btn btn-primary my-2 my-sm-0" type="submit" disabled={disabled}>Change Password</button>
            </div>
          </form>
          <div><p id='passwordUpdateNotification'className='passwordUpdateNotification text-center'></p></div>
      </div>
    </div>
  )
}
