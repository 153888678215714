import HelpList from '../../components/HelpList/HelpList'
import './Help.css'


export default function Help() {
  return (
    <div className='Help'>
        <div className='title'>
            <div className='header'>Help</div>
            {/* <small className='tertiaryText'>Please select a topic from the list below</small> */}
        </div>
        <div className='helpSection'>
            <HelpList/>
        </div>
    </div>
  )
}
