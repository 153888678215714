import DescriptionItem from '../DescriptionItem/DescriptionItem'
import './Description.css'


export default function Description({description, currentProject, publicationNumber}) {
  return (
    <div className='Decription'>
    {
    description.map ( (descriptionItem, idx) =>(
      <DescriptionItem descriptionItem={descriptionItem} index={idx} key={idx} currentProject={currentProject} publicationNumber={publicationNumber} />
    ))
    }
    </div>
  )
}
