import './ProjectFeaturesItem.css'

export default function ProjectFeaturesItem({feature, num}) {
  return (
    <div className='ProjectFeaturesItem'>
      <span className='featureNumber'>
        <span>{num}</span>
        <span>Element</span>
      </span>
      <div className='featureText'>
        <span>{feature.featureText}</span>
      </div>
    </div>
  )
}
