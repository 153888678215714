import ClaimItem from '../ClaimItem/ClaimItem'
import './Claims.css'

export default function Claims({claims, currentProject, publicationNumber}) {
  return (
    <div className='Claims'>
      {
      claims.map ( (claim, idx) =>(
        <ClaimItem claim={claim} index={idx} key={idx} currentProject={currentProject} publicationNumber={publicationNumber} />
      ))
      }
    </div>
  )
}
