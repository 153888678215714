import { useEffect, useState } from 'react'
import { postPatentId } from '../../utilities/api/patentData'
import ProjectSearchDocumentFeature from '../ProjectSearchDocumentFeatures/ProjectSearchDocumentFeature'
import './ProjectSearchDocuments.css'

export default function ProjectSearchDocuments({projectDocument, documentData, updateDocument, toggleDocumentLoading, currentProject}) {
    const [features, setFeatures] = useState('')
    const [highlightDocumentNumber, setHighlightDocumentNumber] = useState(false)

    const updateFeatures = () => {
        const featuresArray = []
        projectDocument.features.forEach((featureItem, idx) => {
            featuresArray.push(featureItem.feature)
        });
        const featuresSet = new Set(featuresArray)
        const finalFeaturesArray = Array.from(featuresSet)
        setFeatures(finalFeaturesArray)
    }

    const handleClick = async() => {
        try {
            toggleDocumentLoading(true)
            const patentData = await postPatentId({patentId: projectDocument.documentNumber})
            // console.log(patentData)
            await updateDocument(patentData)
            updateFeatures()
            toggleDocumentLoading(false)
        } catch (error) {
            
        }
        
    }
    // console.log(features.toString())
    useEffect(() => {
        if (documentData && ((documentData.documentNumber===projectDocument.documentNumber) || (documentData.url===projectDocument.documentNumber))) {
            setHighlightDocumentNumber(true)
        } else {
            setHighlightDocumentNumber(false)
        }
        // setFeatures(updateFeatures())
        // // eslint-disable-next-line
    },[projectDocument, documentData])

    useEffect(() => {
        updateFeatures()
        // eslint-disable-next-line
    },[currentProject])
    // console.log(projectDocument.documentNumber, features)

  return (
    <div className={`ProjectSearchDocuments ${highlightDocumentNumber ? ' highlightDocumentNumber' : ''}`} onClick={handleClick}>
        <hr />
        <div className='documentNumber'>
            <div>
                <svg width="14" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="16" height="6" rx="3" stroke="#1B2B41" strokeOpacity="0.6"/>
                    <rect x="0.5" y="6.5" width="16" height="6" rx="3" stroke="#1B2B41" strokeOpacity="0.6"/>
                </svg>
                <span>&nbsp;&nbsp;&nbsp;</span>
            </div>
            
            { projectDocument.features[0].classification === 'PD' ?
            <div>
                <span className='projectSearchDocumentNumber'><small>{`${projectDocument.documentNumber}`}</small></span>
                <span>&nbsp;<small>({features.length})</small></span>
            </div>
            :
            <div>
                <span className='projectSearchDocumentNumber'><small>{`${projectDocument.features[0].documentTitle}`}</small></span>
                <span>&nbsp;<small>({features.length})</small></span>
            </div>
            }
        </div>
        <div className='documentElements'>
        { features &&
            features.sort((a, b) => a.feature > b.feature ? -1 : 1).map((feature, idx) => (
                <ProjectSearchDocumentFeature feature={feature} key={idx} highlightDocumentNumber={highlightDocumentNumber} />
            ))
            // <p>[{features.toString()}]</p>
            // console.log(features)
        }
        </div>
    </div>
  )
}
