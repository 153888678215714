import './ProjectAnalysisUnfiltered.css'

export default function ProjectAnalysisUnfiltered({projectAnalysisUnfiltered, elements}) {
  return (
    <div className='ProjectAnalysisUnfiltered'>
        <div className='title'>Combination of Two References</div>
        { projectAnalysisUnfiltered.twoRefCombinations.length > 0 &&
        <div className='tableContainer'>
            <table className='table'>
                <thead>
                    <tr>
                        <th scope='col' className='reference'>References</th>
                        {elements.map((element, idx) =>(
                        <th scope='col' className='element' key={idx}>{`EL ${element}`}</th>
                        ))}
                        <th scope='col' className='comment'>Comment</th>
                    </tr>
                </thead>
                <tbody className='tableBody'>
                    {
                        projectAnalysisUnfiltered.twoRefCombinations.map((item, idx) => (
                            <tr key={idx}>
                                <td className='reference' key={idx}>
                                    <div className='referenceContainer'>
                                        <span className='multiRef'>{item.firstRef}</span><span>Ref</span>
                                        <span className='joiner'>+</span>
                                        <span className='multiRef'>{item.secondRef}</span><span>Ref</span>
                                    </div>
                                </td>
                                {elements.map((element, idx) => (
                                <td className='element' key={idx}>
                                    { item.featuresNotPresent.includes(element) ?
                                    <div className='projectAnalysisElementAbsent'>
                                        <span></span>
                                    </div>
                                    :
                                    <div className='projectAnalysisElementPresent'>
                                        <span></span>
                                    </div>
                                    }
                                </td>
                                ))}
                                <td className='commentText'>
                                    {item.featuresNotPresent.length > 0 ?
                                    <>
                                        <span>This reference combination ommits elements </span>
                                        <span>
                                            {item.featuresNotPresent.map((feature, idx) => (
                                            idx === item.featuresNotPresent.length-1 ?
                                            <span key={idx}>{feature}.</span>
                                            :
                                            <span key={idx}>{feature}, </span>
                                            ))}
                                        </span>
                                    </>
                                    :
                                    <span>All features are addressed.</span>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
        }
        <br />
        <div className='title'>Combination of Three References</div>
        { projectAnalysisUnfiltered.threeRefCombinations.length > 0 &&
        <div className='tableContainer'>
            <table className='table'>
                <thead>
                    <tr>
                        <th scope='col' className='reference'>References</th>
                        {elements.map((element, idx) =>(
                        <th scope='col' className='element' key={idx}>{`El ${element}`}</th>
                        ))}
                        <th scope='col' className='comment'>Comment</th>
                    </tr>
                </thead>
                <tbody className='tableBody'>
                    {
                        projectAnalysisUnfiltered.threeRefCombinations.map((item, idx) => (
                            <tr key={idx}>
                                <td className='reference' key={idx}>
                                    <div className='referenceContainer'>
                                        <span className='multiRef'>{item.firstRef}</span><span>Ref</span>
                                        <span className='joiner'>+</span>
                                        <span className='multiRef'>{item.secondRef}</span><span>Ref</span>
                                        <span className='joiner'>+</span>
                                        <span className='multiRef'>{item.thirdRef}</span><span>Ref</span>
                                    </div>
                                </td>
                                {elements.map((element, idx) => (
                                <td className='element' key={idx}>
                                    { item.featuresNotPresent.includes(element) ?
                                    <div className='projectAnalysisElementAbsent'>
                                        <span></span>
                                    </div>
                                    :
                                    <div className='projectAnalysisElementPresent'>
                                        <span></span>
                                    </div>
                                    }
                                </td>
                                ))}
                                <td className='commentText'>
                                    {item.featuresNotPresent.length > 0 ?
                                    <>
                                        <span>This reference combination ommits elements </span>
                                        <span>
                                            {item.featuresNotPresent.map((feature, idx) => (
                                            idx === item.featuresNotPresent.length-1 ?
                                            <span key={idx}>{feature}.</span>
                                            :
                                            <span key={idx}>{feature}, </span>
                                            ))}
                                        </span>
                                    </>
                                    :
                                    <span>All features are addressed.</span>
                                    }
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
        }
    </div>
  )
}
