import './Document.css'

import { useEffect, useState } from 'react'
import NonPatentData from '../NonPatentData/NonPatentData'
import PDFData from '../PDFData/PDFData'
import ManualTextAnnotationForm from '../ManualTextAnnotationForm/ManualTextAnnotationForm'
import { highlightKeyWords } from '../../utilities/services/keywordSearch'
import PatentData from '../PatentData/PatentData'
import { highlightAnnotations } from '../../utilities/services/annotationHighlighter'

export default function Document({user, currentProject, updateCurrentProject, projectDocuments, documentData, updateDocument, documentLoading, updateDocumentWithTranslatedFromBrowser, renderGallery, updateCurrentImage, renderPdf, updateAnnotationText, keyWords}) {
    const [annotations, setAnnotations] = useState([])
    const [manualAnnotationOverride, setManualAnnotationOverride] = useState(false)

    function handleTextSelection(evt) {
        let txt = "";
        if (window.getSelection) {
            txt = window.getSelection().toString();
            if (txt.length > 1) {
                updateAnnotationText(txt)
            }
        }
    }

    const getDocumentAnnotations = () => {
        let annotationsArray = []
        if (currentProject && projectDocuments && documentData) {
          projectDocuments.forEach(document => {
            if (documentData.classification === 'PD') {
                if (document.documentNumber === documentData.documentNumber) {
                    document.features.forEach(feature => {
                        if (feature.textAnnotation) {
                            annotationsArray.push({feature: feature.feature, text: feature.textAnnotation})
                        }
                    })
                }
            } else if (documentData.classification === 'PDF' || documentData.classification === 'NPD') {
                if (document.documentNumber === documentData.url) {
                    document.features.forEach(feature => {
                      annotationsArray.push({feature: feature.feature, text: feature.textAnnotation})
                    })
                }
            }
          })
        }
        setAnnotations(annotationsArray)
    }

    const updateManualAnnotationOverride = () => {
        setManualAnnotationOverride(!manualAnnotationOverride)
    }

    useEffect(() => {
        // updateDocument(documentData)
        getDocumentAnnotations()
        highlightKeyWords(keyWords)
        // eslint-disable-next-line
    },[documentData, keyWords, currentProject, projectDocuments])

    useEffect(() => {
      if (annotations.length > 0) {
        highlightAnnotations(annotations)
      }
      highlightKeyWords(keyWords)
      // eslint-disable-next-line
    },[annotations])

    return(
        <>
        {!documentLoading ?
            <div className='Document card col' onMouseUp={handleTextSelection}>
                { (documentData.classification && !documentData.error) &&
                <>
                    <div className='manualOverrideContainer'>
                    { manualAnnotationOverride ?
                        <button onClick={updateManualAnnotationOverride} className='btn btn-sm showDocumentButton' title={manualAnnotationOverride? 'Show Document on Page':'Manually Annotate Reference' }>
                            <span>
                                <svg width="7" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M8 15L1 8L8 1" stroke="#1A5E9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </span>
                            <span>&nbsp;&nbsp;Back to Document</span>
                        </button>
                    :
                        <button onClick={updateManualAnnotationOverride} className='btn btn-sm manualOverrideButton' title={manualAnnotationOverride? 'Show Document on Page':'Manually Annotate Reference' }>
                            <span>
                                <svg width="20" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" width="28.1816" height="28.1816" rx="7" fill="white" fillOpacity="0.38"/>
                                    <rect x="3.11328" y="2.91602" width="23.0446" height="22.351" rx="5" fill="#409963"/>
                                    <path d="M8.13281 8.21973V21.7234" stroke="white" strokeOpacity="0.41" strokeWidth="1.5"/>
                                    <path d="M12.4769 20H10.3675L14.2081 9.09091H16.6477L20.4936 20H18.3842L15.4705 11.3281H15.3853L12.4769 20ZM12.5462 15.7227H18.299V17.31H12.5462V15.7227Z" fill="white"/>
                                </svg>
                            </span>
                            <span>&nbsp;&nbsp;Override Document</span>
                        </button>
                    }
                    </div>
                    { manualAnnotationOverride ?
                        <>
                            { currentProject ?
                            <>
                                { ((currentProject.status === 'inProgress') || (currentProject.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))) &&
                                <div className='manualTextAnnotation'>
                                    <ManualTextAnnotationForm user={user} documentData={documentData} currentProject={currentProject} updateCurrentProject={updateCurrentProject} projectDocuments={projectDocuments} />
                                </div>
                                }
                            </>
                            :
                            <>
                                <div className=''>
                                    <h6 className='text-center'>Manually Add Text Annotations for Document</h6>
                                    <h4 className='pt-5 text-center text-danger'>Please select a project</h4>
                                </div>
                            </>
                            }
                        </>
                    :
                        <>
                            { documentData.classification === 'PD' &&
                            <div className='mainDocument PD'>
                                {documentData.patentPDF &&
                                <div className='pdfButton'>
                                    <div className='pdfButtonContent'>
                                        <button className='btn btn-sm btn-light' onClick={renderPdf}>
                                            <span><img src={require('./published.png')} width={20} alt="" />&nbsp;&nbsp;</span>
                                            <span>Published Document</span>
                                        </button>
                                    </div>
                                </div>
                                }
                                <div className='card-body'>
                                    <PatentData documentData={documentData} currentProject={currentProject} projectDocuments={projectDocuments} renderPdf={renderPdf} renderGallery={renderGallery} updateCurrentImage={updateCurrentImage} />
                                </div>
                            </div>
                            }
                            { documentData.classification === 'NPD' &&
                            <div className='mainDocument NPD'>
                                <div className='card-body'>
                                    <NonPatentData documentData={documentData} currentProject={currentProject} projectDocuments={projectDocuments} />
                                </div>
                            </div>
                            }
                            { documentData.classification === 'PDF' &&
                            <div className='mainDocument PDF'>
                                <div className='card-body'>
                                    <PDFData documentData={documentData} currentProject={currentProject} projectDocuments={projectDocuments} />
                                </div>
                            </div>
                            }
                        </>
                    }
                    
                </>
                }
                { (documentData.classification && documentData.error) &&
                <>
                    <div className='mainDocument pb-0 pt-0'>
                        <div className='card-body pt-0 pb-0'>
                            <h6 className='text-center'><strong>There was an error retrieving the document.</strong></h6>
                        </div>
                    </div>
                    { currentProject &&
                    <>
                    { ((currentProject.status === 'inProgress') || (currentProject.status === 'inReview' && (user.userType==='admin'||user.userType === 'manager'))) &&
                    <div className='manualTextAnnotation'>
                        <ManualTextAnnotationForm user={user} documentData={documentData} currentProject={currentProject} updateCurrentProject={updateCurrentProject} projectDocuments={projectDocuments} />
                    </div>
                    }
                    </>
                    }
                </>
                }
            </div>
        :
            <div className='Document card col documentLoadingSpinner'>
                <div className='mainDocument'>
                    <div className='card-body'>
                        <div className="spinner-border " role="status">
                            {/* <span className="visually-hidden">Loading...</span> */}
                        </div>
                    </div>
                </div>
            </div>
        }
        </>
    )
}