import { useEffect, useState } from 'react'
import './DescriptionItem.css'


export default function DescriptionItem({index, descriptionItem, currentProject, publicationNumber}) {
  const [paragraphNumber, setParagraphNumber] =useState('')

  const getParagraphNumber = () => {
    setParagraphNumber(``)
    if (descriptionItem.length > 40) {
      if ((index+1)<10) {
        setParagraphNumber(`[000${index+1}]`)
      } else if ((index+1)>=10 && (index+1)<100) {
        setParagraphNumber(`[00${index+1}]`)
      } else if ((index+1)>=100 && (index+1)<1000) {
        setParagraphNumber(`[0${index+1}]`)
      } else if ((index+1)>=1000 && (index+1)<10000) {
        setParagraphNumber(`[${index+1}]`)
      }
    }
  }

  useEffect(() => {
    getParagraphNumber()
    // eslint-disable-next-line
  },[currentProject, descriptionItem])
  
  return (
    <>
    <p className='descriptionItemMatch'><span className='notranslate'>{paragraphNumber}&nbsp;&nbsp;</span><span id={`descriptionItem${index+1}`}>{descriptionItem}</span></p>
    </>
  )
}
